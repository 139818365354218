import { ClassSelectDropdown } from './class-select-dropdown.js';
import { Draggable } from './draggable.js';
import { MainData } from './mainData.js';
import {Utils} from "@/js/utils";
import {Marks} from "@/js/marks";

export class Link {
  constructor(data) {
    this.activity = data;
  }
}

Link.prototype.element = null;

function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
Link.prototype.createElement = function() {
  const cardWrapper = document.createElement("div");
  cardWrapper.classList.add("card-wrapper");
  cardWrapper.setAttribute("data-link-id-insidebar", this.activity.classColor ? this.activity.groupId :  this.activity._id);
  cardWrapper.classList.add("list-view");

  cardWrapper.addEventListener("click", () => {
    const pageNum = MainData.bookPages.find(p => p.pageId == this.activity.pageId).number;
    MainData.navbarObj.changePage(pageNum + 1);
  });
  cardWrapper.addEventListener("mouseenter", onMouseIn.bind(this));
  cardWrapper.addEventListener("mouseleave", onMouseOut.bind(this));

  const activityRow = document.createElement("div");
  activityRow.classList.add("activity-row");

  activityRow.classList.add(`activity-${this.activity.classColor ? 'mark-comment' : this.activity.type}`);

  const infoWrapper = document.createElement("div");
  infoWrapper.classList.add("subject");

  const subject = document.createElement("div");
  subject.classList.add("info-wrapper");
  if (this.activity && this.activity.subjectName) {
    subject.innerText = this.activity.subjectName[0];
  }
  if (subject.innerText.length > 25) {
    subject.innerText = subject.innerText.substring(0, 25) + "...";
  }

  infoWrapper.appendChild(subject);
  if (this.activity.type === "link") {
    const link = document.createElement("a");
    link.classList.add("name");
    const url = !this.activity.url.match(/^https?:\/\//i) ? 'http://' + this.activity.url : this.activity.url;
    link.setAttribute("href", validateEmail(this.activity.url) ? `mailto:${this.activity.url}` :url);
    link.setAttribute("target", "_blank");
    link.innerText = this.activity.url;
    if (link.innerText.length > 98) {
      link.innerText = link.innerText.substring(0, 98) + "...";
    }
    infoWrapper.appendChild(link);
  } else {
    const name = document.createElement("div");
    name.classList.add("name");
    name.innerText = this.activity[this.activity.type === "comment" ? 'text' : 'comment'] || '';
    if (name.innerText.length > 98) {
      name.innerText = name.innerText.substring(0, 98) + "...";
    }
    infoWrapper.appendChild(name);
  }

  activityRow.appendChild(infoWrapper);

  //card subject

  const cardSubject = document.createElement("div");
  cardSubject.classList.add("card-subject");
  if (this.activity.classColor) {
    cardSubject.classList.add(`mark-${this.activity.classColor}`);
  }
  const spanIcon = document.createElement("span");
  spanIcon.classList.add(`icon-${this.activity.classColor ? 'comment' : this.activity.type}`);

  const spanText = document.createElement("span");
  if (this.activity.type === "link") {
    spanText.innerText = MainData.langData["Link"];
  } else if (this.activity.type === "comment") {
    spanText.innerText = MainData.langData["Comment"];
  } else {
    console.log(this.activity.comment);
    spanText.innerText = MainData.langData[this.activity.comment ? "Mark with comment" : 'Highlight'];
  }

  const hoveredOptions = document.createElement("div");
  hoveredOptions.classList.add("hovered-options");

  // const deleteBtn = document.createElement('span');
  // deleteBtn.classList.add('delete-btn', 'icon-delete');
  // deleteBtn.addEventListener('click', removeLinkComment.bind(this));
  const ifUserCanEdit = this.activity.userId && this.activity.userId == MainData.userId || MainData.userType === 'editor';
  if (ifUserCanEdit) {
    const editBtn = document.createElement("span");
    editBtn.classList.add("edit-btn", "icon-edit");
    editBtn.addEventListener("click", this.openLinkCommentEditor.bind(this));

    // hoveredOptions.appendChild(deleteBtn);
    hoveredOptions.appendChild(editBtn);
  }

  cardSubject.appendChild(spanIcon);
  cardSubject.appendChild(spanText);

  activityRow.appendChild(cardSubject);
  activityRow.appendChild(hoveredOptions);

  cardWrapper.appendChild(activityRow);

  this.element = cardWrapper;

  function onMouseIn() {
    if (this.activity.classColor){
      const el = document.querySelector(`[data-mark-group-id='${this.activity.groupId}'`);
      if (el) {
        el.classList.add('highlighted');
      }
    } else {
      const itemInPage = document.querySelector(
        `[data-link-id-inbook='${this.activity._id}'`
      );
      if (itemInPage) {
        const visiblePart = itemInPage.querySelector(".visible-part");
        visiblePart.classList.add("active");
      }
    }
  }

  function onMouseOut() {
    if (this.activity.classColor){
      const el = document.querySelector(`[data-mark-group-id='${this.activity.groupId}'`);
      if (el) {
        el.classList.remove('highlighted');
      }
    } else {
      const itemInPage = document.querySelector(
        `[data-link-id-inbook='${this.activity._id}'`
      );

      if (itemInPage) {
        const visiblePart = itemInPage.querySelector(".visible-part");
        visiblePart.classList.remove("active");
      }
    }
  }
  return cardWrapper;
};
Link.prototype.openLinkCommentEditor = function() {
  MainData.sidebarObj.showLinkDetailsWrapper(this.activity);

  const linkActions = document.querySelector(".link-actions");
  linkActions.style.display = "flex";
  const btnDelete = document.createElement("span");
  const btnCancel = document.createElement("div");
  const btnSave = document.createElement("div");
  btnDelete.classList.add("btn-delete", "icon-delete");
  btnCancel.classList.add("btn-cancel", "btn", "btn-sm");
  btnCancel.innerHTML = MainData.langData["Cancel"];
  btnSave.classList.add("btn-save", "btn", "btn-sm", "btn-blue-pr");
  btnSave.innerHTML = MainData.langData["Save"];
  btnDelete.addEventListener("click", () => {
    this.removeLinkComment();
  });
  btnCancel.addEventListener("click", () => {
    MainData.sidebarObj.closeLinkDetailsWrapper(this.activity);
  });
  btnSave.addEventListener("click", () => {
    this.updateLinkComment();
  });
  linkActions.appendChild(btnDelete);
  linkActions.appendChild(btnCancel);
  linkActions.appendChild(btnSave);

  const linkDetails = document.querySelector(".link-details");
  if (this.activity.type !== "link") {
    const inputText = document.createElement("textarea");
    inputText.classList.add("input-text");
    inputText.setAttribute("rows", "14");
    inputText.value = this.activity.text || this.activity.comment || '';
    linkDetails.appendChild(inputText);
  }

  if (this.activity.type === "link") {
    const label = document.createElement("label");
    label.textContent = 'תיאור';
    linkDetails.appendChild(label);

    const inputLabel = document.createElement("input");
    inputLabel.classList.add("input-text");
    inputLabel.classList.add("input-link-label");
    inputLabel.value = this.activity.text;
    linkDetails.appendChild(inputLabel);

    const labelUrl = document.createElement("label");
    labelUrl.textContent = 'URL';
    linkDetails.appendChild(labelUrl);

    const inputUrl = document.createElement("input");
    inputUrl.classList.add("input-url");
    inputUrl.value = this.activity.url;
    linkDetails.appendChild(inputUrl);
  }

  const classSelectWrapper = document.querySelector(
    ".class-select-wrapper-editor"
  );
  if (MainData.userType === "teacher") {
    classSelectWrapper.classList.add("show");
    const toggleWrapper = classSelectWrapper.querySelector(".toggle-wrapper");
    const checkboxEl = document.createElement("input");
    checkboxEl.type = "checkbox";
    checkboxEl.id = "unchecked-editor";
    checkboxEl.classList.add("toggle-input", "hidden");

    if (this.activity.classIdList && this.activity.classIdList.length > 0) {
      checkboxEl.checked = true;
      const classSelectDropdown = new ClassSelectDropdown();
      const multipleSelect = classSelectDropdown.createMultipleSelect(true);
      classSelectWrapper.appendChild(multipleSelect);
      MainData.selectedClassListIds = [];
      this.activity.classIdList.forEach(item => {
        MainData.selectedClassListIds.push(item);
        const _item = multipleSelect.querySelector(`[id='${item}'`);
        if (_item) {
          _item.checked = true;
        }
      });
      classSelectDropdown.checkAllClasses();
      const toggleText = classSelectWrapper.querySelector(
        ".toggle-text-editor"
      );
      toggleText.classList.add("active");
    }
    const toggleText = classSelectWrapper.querySelector(".toggle-text-editor");
    toggleText.innerText = MainData.langData["Advertising for all classes"];

    const toggleLabel = document.createElement("label");
    toggleLabel.htmlFor = "unchecked-editor";
    toggleLabel.classList.add("toggle-label");
    checkboxEl.addEventListener("change", () => {
      toggleText.classList.toggle("active");
      const existMultipleSelect = document.querySelector(
        ".select-wrapper-editor"
      );
      if (!existMultipleSelect) {
        const classSelectDropdown = new ClassSelectDropdown();
        const multipleSelect = classSelectDropdown.createMultipleSelect(true);
        classSelectWrapper.appendChild(multipleSelect);
      } else {
        existMultipleSelect.remove();
        MainData.selectedClassListIds = [];
      }
    });
    toggleWrapper.appendChild(checkboxEl);
    toggleWrapper.appendChild(toggleLabel);
  }
};

Link.prototype.isValid = function (activity) {
  const inputUrl = document.querySelector(".input-url");
  if (activity.type === "link") {
    if(!inputUrl.value || !inputUrl.value.length) {
      MainData.notify.show(MainData.langData["Field link is required"]);
      return false
    }
    if(!Utils.isValidUrl(inputUrl.value)) {
      MainData.notify.show(MainData.langData["URL is not valid"]);
      return false
    }
  }
  // if (type === "comment" && (!this.textarea.value || !this.textarea.value.length)) {
  //   MainData.notify.show(MainData.langData["Field comment is required"]);
  //   return false
  // }
  return true;
}
Link.prototype.updateLinkComment = function() {
  if (!this.isValid(this.activity)) {
    return false;
  }
  const inputText = document.querySelector(".input-text");
  if (inputText) {
    this.activity.text = inputText.value;
  }
  if (this.activity.type === "link") {
    const inputUrl = document.querySelector(".input-url");
    this.activity.url = inputUrl.value;
  }
  if (this.activity.classColor) {
    Marks.prototype.updateMark(this.activity.groupId, MainData.selectedClassListIds, this.activity.text);
  } else {
    const draggable = new Draggable(this.activity, true);
    draggable.updateObjectRequest(true, this.activity);
  }
};

Link.prototype.removeLinkComment = function() {
  removeFromSidebarLinkComment.call(this);
  removeFromPageLinkComment.call(this);
  removeObjectRequest.call(this);

  //need add close of editor
};

function removeFromSidebarLinkComment() {
  const deletedLink = MainData.linksAndComments.find(item => item._id === this.activity._id);
  const indexOfDeletedElement = MainData.linksAndComments.indexOf(deletedLink);
  MainData.linksAndComments.splice(indexOfDeletedElement, 1);
  MainData.sidebarObj.showLinks();
}

function removeFromPageLinkComment() {
  const elForDeleting = document.querySelector(
    `[data-link-id-inbook='${this.activity._id}'`
  );
  // elForDeleting.remove();
  if (elForDeleting) {
    elForDeleting.parentNode.removeChild(elForDeleting);
  }
}

function removeObjectRequest() {
  let url;

 if(this.activity.groupId) {
   MainData.sidebarObj.closeLinkDetailsWrapper(this.activity);
   Marks.prototype.removeMark(null, this.activity.groupId, this.activity._id);
   return false;
 }
  if (this.activity.type === "comment") {
    url = `${MainData.environment.pdfApi}comment/delete`;
  }
  if (this.activity.type === "link") {
    url = `${MainData.environment.pdfApi}link/delete`;
  }

  let request = {};
  request.type = "DELETE";
  request.data = { id: this.activity._id };
  request.url = url;
  request.beforeSend = function(xhr) {
    xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  };
  request.success = res => {
    this.activity._id = res.id;
    MainData.inCreateMode = false;
    MainData.sidebarObj.closeLinkDetailsWrapper(this.activity);
  };
  request.processData = true;
  $.ajax(request);
}
