import { Common } from './common.js';
import { MainData } from './mainData.js';

export class Activity {
	constructor(data) {
		this.activity = data;

        this.ActivityTypeName = {
            games: 'Games',
            worksheets: 'Worksheets',
            illustrationmeans: 'Illustrationmeans',
            lessons: 'Lessons',
            storyline: 'Storyline',
            tests: 'Tests',
            video: 'Videos',
            link: 'Links',
            audio: 'Audios'
        }
	}
}

Activity.prototype.element = null;

Activity.prototype.createBlockElement = function () {

    const cardWrapper = document.createElement('div');
    cardWrapper.classList.add('card-wrapper');
    cardWrapper.setAttribute('data-catalog-number', this.activity.catalogNumber);

    const activityCardWrapper = document.createElement('div');
    activityCardWrapper.classList.add('activity-card-wrapper');
    activityCardWrapper.classList.add(`card-${this.activity.activityType.name.toLowerCase()}`);

    //header

    const cardHeaderCustom = document.createElement('div');
    cardHeaderCustom.classList.add('card-header-custom');

    const cardImg = this.createCardHeaderEl();

    cardHeaderCustom.appendChild(cardImg);
    activityCardWrapper.appendChild(cardHeaderCustom);

    //card bottom

    const cardBottomCustom = document.createElement('div');
    cardBottomCustom.classList.add('card-bottom-custom');

    //card info

    const cardInfo = document.createElement('div');
    cardInfo.classList.add('card-info');

    const infoName = document.createElement('div');
    infoName.classList.add('info-name');
    if (this.activity && this.activity.subjectName) {
        infoName.innerText = this.activity.subjectName[0];
    }
    if (infoName.innerText.length > 25) {
        infoName.innerText = infoName.innerText.substring(0, 25) + '...';
    }

    const infoDescription = document.createElement('div');
    infoDescription.classList.add('info-description');
    infoDescription.innerText = this.activity.name;
    if (infoDescription.innerText.length > 98) {
        infoDescription.innerText = infoDescription.innerText.substring(0, 98) + '...';
    }

    cardInfo.appendChild(infoName);
    cardInfo.appendChild(infoDescription);

    cardBottomCustom.appendChild(cardInfo);

    //card-type

    const cardType = document.createElement('div');
    cardType.classList.add('card-type');

    if (this.activity.activityType.name == 'Storyline' && this.activity.isTinCan && this.activity.isFullLMS) {
        const checkType = document.createElement('div');
        checkType.classList.add('check-type');

        const typeAuto = document.createElement('div');
        typeAuto.classList.add('type-auto');

        const typeValue = document.createElement('div');
        typeValue.classList.add('type-value');

        const iconLogo = document.createElement('div');
        iconLogo.classList.add('icon-logo');

        typeAuto.appendChild(typeValue);
        typeAuto.appendChild(iconLogo);

        checkType.appendChild(typeAuto);

        cardType.appendChild(checkType);
    }

    //card subject

    const cardSubject = document.createElement('div');
    cardSubject.classList.add('card-subject');

    const spanIcon = document.createElement('span');
    spanIcon.classList.add(`icon-${this.activity.activityType.name.toLowerCase()}`);

    const spanText = document.createElement('span');
    spanText.innerText = `${MainData.langData[this.ActivityTypeName[this.activity.activityType.name.toLowerCase()]]}`;

    cardSubject.appendChild(spanIcon);
    cardSubject.appendChild(spanText);

    cardType.appendChild(cardSubject);

    cardBottomCustom.appendChild(cardType);

    activityCardWrapper.appendChild(cardBottomCustom);
    cardWrapper.appendChild(activityCardWrapper);
    this.element = cardWrapper;

    activityCardWrapper.addEventListener('mouseenter', () => { this.onMouseIn() });
    activityCardWrapper.addEventListener('mouseleave', () => { this.onMouseOut(activityCardWrapper) });
    this.createBlockHoverPart(activityCardWrapper);

    return cardWrapper;
}

Activity.prototype.createCardHeaderEl = function() {
    const cardImg = document.createElement('div');
    cardImg.classList.add('card-img');

    if (this.activity.activityType.activityTypeId === 11) {
        const iconWrapper = document.createElement('div');
        iconWrapper.classList.add('icon-wrapper');
        const iconAudio = document.createElement('div');
        iconAudio.classList.add('icon-audio');
        iconWrapper.appendChild(iconAudio);
        cardImg.appendChild(iconWrapper);
        return cardImg;
    } else {
        const img = document.createElement('img');
        const src = MainData.environment.media + this.activity.thumbnailUri;
        img.setAttribute('onError', 'this.src="../imgs/Icon_Activities.svg"');
        img.setAttribute('data-src', `${src}`);
        img.classList.add('activity-img');
        img.classList.add('activity-lazy');
        img.setAttribute('id', 'activity-image-' + this.activity.activityId);
        cardImg.appendChild(img);
        return cardImg;
    }
}

Activity.prototype.createBlockHoverPart = function (activityCardWrapper) {
    const activityHoverPart = document.createElement('div');
    activityHoverPart.classList.add('activity-hover-part');

    const hoverHeader = document.createElement('div');
    hoverHeader.classList.add('hover-header');
    const likeWrapper = this.initLikeWrapper();
    const shareWrapper = this.initShareWrapper();
    hoverHeader.appendChild(likeWrapper);
    hoverHeader.appendChild(shareWrapper);

    const hoverActions = document.createElement('div');
    hoverActions.classList.add('hover-actions');
        const actionPlay = document.createElement('div');
        actionPlay.classList.add('action');
            const iconPlay = document.createElement('div');
            iconPlay.classList.add('icon-action', 'icon-play', 'icon-primary');
            iconPlay.addEventListener('click', (e) => {
                e.stopPropagation();
                const pageNumber = document.querySelector(`[data-page-id='${this.activity.pageId}'`).getAttribute('data-page-number');
                MainData.navbarObj.changePage(pageNumber);
                MainData.sidebarObj.onPlayActivity(this.activity.catalogNumber);
            });
            const playTitle = document.createElement('div');
            playTitle.classList.add('action-title');
            playTitle.innerText = MainData.langData[this.activity.activityType.activityTypeId != 8 ? 'Play activity' : 'Run test'];
        actionPlay.appendChild(iconPlay);
        actionPlay.appendChild(playTitle);


        const actionInfo = document.createElement('div');
        actionInfo.classList.add('action', 'action-info');
        const iconInfo = document.createElement('div');
            iconInfo.classList.add('icon-action', 'icon-info', 'icon-primary-white');
            iconInfo.addEventListener('click', (e) => {
                e.stopPropagation();
                const activityModal = MainData.activityModal;
                activityModal.initActivityModalContent(this.activity);
            });
            const infoTitle = document.createElement('div');
            infoTitle.classList.add('action-title');
            infoTitle.innerText = MainData.langData['Information'];
        actionInfo.appendChild(iconInfo);
        actionInfo.appendChild(infoTitle);

    hoverActions.appendChild(actionPlay);
    hoverActions.appendChild(actionInfo);

    activityHoverPart.appendChild(hoverHeader);
    activityHoverPart.appendChild(hoverActions);
    activityCardWrapper.appendChild(activityHoverPart);
}

Activity.prototype.createRowElement = function () {
    const cardWrapper = document.createElement('div');
    cardWrapper.classList.add('card-wrapper');
    cardWrapper.classList.add('list-view');

    cardWrapper.setAttribute('data-catalog-number', this.activity.catalogNumber);


    const activityRow = document.createElement('div');
    activityRow.classList.add('activity-row');
    activityRow.classList.add(`activity-${this.activity.activityType.name.toLowerCase()}`);

    const infoWrapper = document.createElement('div');
    infoWrapper.classList.add('subject');

    const subject = document.createElement('div');
    subject.classList.add('info-wrapper');
    if (this.activity && this.activity.subjectName) {
        subject.innerText = this.activity.subjectName[0];
    }
    if (subject.innerText.length > 25) {
        subject.innerText = subject.innerText.substring(0, 25) + '...';
    }

    const name = document.createElement('div');
    name.classList.add('name');
    name.innerText = this.activity.name;

    if (name.innerText.length > 98) {
        name.innerText = name.innerText.substring(0, 98) + '...';
    }

    infoWrapper.appendChild(subject);
    infoWrapper.appendChild(name);

    activityRow.appendChild(infoWrapper);

    //card subject

    const cardSubject = document.createElement('div');
    cardSubject.classList.add('card-subject');

    const spanIcon = document.createElement('span');
    spanIcon.classList.add(`icon-${this.activity.activityType.name.toLowerCase()}`)

    const spanText = document.createElement('span');
    spanText.innerText = `${MainData.langData[this.ActivityTypeName[this.activity.activityType.name.toLowerCase()]]}`

    cardSubject.appendChild(spanIcon);
    cardSubject.appendChild(spanText);

    const likeWrapper = this.initLikeWrapper();

    const leftInfoWrapper = document.createElement('div');
    leftInfoWrapper.classList.add('left-info-wrapper');

    leftInfoWrapper.appendChild(cardSubject);
    leftInfoWrapper.appendChild(likeWrapper);

    activityRow.appendChild(leftInfoWrapper);
    cardWrapper.appendChild(activityRow);

    cardWrapper.addEventListener('mouseenter', () => { this.onMouseIn() })
    cardWrapper.addEventListener('mouseleave', () => { this.onMouseOut() })

    this.element = cardWrapper;

    return cardWrapper;

}
Activity.prototype.initLikeWrapper = function() {
    const likeWrapper = document.createElement('div');
    likeWrapper.classList.add('btn-like-wrapper');
    const likeIcon = document.createElement('div');
    likeIcon.classList.add('btn-like', 'icon-heart', 'icon-primary-white');
    if(this.activity.isLike) {
        likeIcon.classList.add('active');
    }
    likeIcon.addEventListener('click', () => { this.likeActivity(event) });
    likeWrapper.appendChild(likeIcon);
    return likeWrapper;
}

Activity.prototype.initShareWrapper = function() {
    const shareWrapper = document.createElement('div');
    shareWrapper.classList.add('btn-share-wrapper');
    const shareIcon = document.createElement('div');
    shareIcon.classList.add('btn-share', 'icon-share', 'icon-primary-white');

    const cardShareTooltip = this.initCardShareTooltip()
    shareWrapper.appendChild(cardShareTooltip);

    shareIcon.addEventListener('click', (e) => {
        e.stopPropagation();
        cardShareTooltip.classList.toggle('active');
    });

    shareWrapper.appendChild(shareIcon);
    return shareWrapper;
}

Activity.prototype.initCardShareTooltip = function() {
    const cardShareTooltip = document.createElement('div');
    cardShareTooltip.classList.add('card-share-tooltip');
    cardShareTooltip.addEventListener('click', (e) => { e.stopPropagation() });
    const iconX = document.createElement('div');
    iconX.classList.add('icon-x');
    iconX.addEventListener('click', (e) => {
        e.stopPropagation();
        cardShareTooltip.classList.remove('active');
    });

    const tooltipTitle = document.createElement('div');
    tooltipTitle.classList.add('tooltip-title');

        const titleIcon = document.createElement('div');
        titleIcon.classList.add('title-icon', 'icon-share');

        const titleText = document.createElement('div');
        titleText.classList.add('title-text');
        titleText.innerText = MainData.langData['Share'];
        const activityName = document.createElement('p');
        activityName.innerText = this.activity.name;
        titleText.appendChild(activityName);

        tooltipTitle.appendChild(titleIcon);
        tooltipTitle.appendChild(titleText);

    const tooltipBody = document.createElement('div');
    tooltipBody.classList.add('tooltip-body');
        const shareLink = document.createElement('div');
        shareLink.classList.add('share-link');
            const linkLabel = document.createElement('div');
            linkLabel.classList.add('link-label');
            linkLabel.innerText = MainData.langData['Link'];
            const linkValue = document.createElement('div');
            linkValue.classList.add('link-value');
            const link = this.getActivityShareUrl();
            linkValue.innerText = link.substring(0, 22) + '...';

        shareLink.appendChild(linkLabel);
        shareLink.appendChild(linkValue);

        const tooltipBtn = document.createElement('div');
        tooltipBtn.classList.add('tooltip-btn', 'btn', 'btn-blue-pr', 'btn-sm');
            const btnText = document.createElement('span');
            btnText.classList.add('btn-text');
            btnText.innerText = MainData.langData['Copy link'];
            btnText.style.display = 'inline';
            const btnIcon = document.createElement('span');
            btnIcon.classList.add('btn-icon', 'icon-check-mark');
            btnIcon.style.display = 'none';
        tooltipBtn.appendChild(btnText);
        tooltipBtn.appendChild(btnIcon);

        tooltipBtn.addEventListener('click', () => {
            MainData.sidebarObj.copyText(this.getActivityShareUrl());

            btnText.style.display = 'none';
            btnIcon.style.display = 'inline';

            setTimeout(() => {
                btnText.style.display = 'inline';
                btnIcon.style.display = 'none';
            }, 5000);
        });

    tooltipBody.appendChild(tooltipBtn);
    tooltipBody.appendChild(shareLink);

    cardShareTooltip.appendChild(iconX);
    cardShareTooltip.appendChild(tooltipTitle);
    cardShareTooltip.appendChild(tooltipBody);
    return cardShareTooltip;
}

Activity.prototype.getActivityShareUrl = function () {
    return `${MainData.environment.wizdi}activity/${this.activity.activityId}/${this.activity.activityType.activityTypeId}`;
}

Activity.prototype.likeActivity = function (event) {
    event.stopPropagation();
    const common = new Common();
    common.likeActivity(this.activity.catalogNumber)
        .then((isLike) => {
            event.target.classList.toggle('active');
            const allLayers = [...document.querySelectorAll("[data-activity-page-catalog-number]")];
            const activitiesForLike = allLayers.filter(layer => parseInt(layer.getAttribute('data-activity-page-catalog-number')) === parseInt(this.activity.catalogNumber))
            activitiesForLike.forEach(act => {
                act.querySelector('.btn-like').classList.toggle('active');
            })
        })
}

Activity.prototype.onMouseIn = function () {
    const catalogNumber = this.activity.catalogNumber;
    const elementPage = this.element.closest('[data-page-number]').getAttribute('data-page-number');
    let activitiesOnPage = document.querySelectorAll(`[data-activity-page-catalog-number='${catalogNumber}'`);
    if (activitiesOnPage) {
        activitiesOnPage = [...activitiesOnPage];
        let activityOnPage;
        for(let i = 0; i < activitiesOnPage.length; i++) {
            if (activitiesOnPage[i].closest(`[data-page-number='${+elementPage+1}'`)) {
                activityOnPage = activitiesOnPage[i].querySelector('.visible-part');
                activityOnPage.classList.add('active');
                break;
            }
        }
    }
}

Activity.prototype.onMouseOut = function (activityCardWrapper) {
    if (activityCardWrapper) {
        activityCardWrapper.classList.remove('hover');
        const cardShareTooltip = activityCardWrapper.querySelector('.hover-header .card-share-tooltip');
        cardShareTooltip.classList.remove('active');
    }
    const catalogNumber = this.activity.catalogNumber;
    const elementPage = this.element.closest('[data-page-number]').getAttribute('data-page-number');
    let activitiesOnPage = document.querySelectorAll(`[data-activity-page-catalog-number='${catalogNumber}'`);
    if (activitiesOnPage) {
        activitiesOnPage = [...activitiesOnPage];
        let activityOnPage;
        for(let i = 0; i < activitiesOnPage.length; i++) {
            if (activitiesOnPage[i].closest(`[data-page-number='${+elementPage+1}'`)) {
                activityOnPage = activitiesOnPage[i].querySelector('.visible-part');
                activityOnPage.classList.remove('active');
                break;
            }
        }
    }
}
