import { MainData } from "./mainData";

export class AudioPlayer {
	constructor() {
        this.audio = new Audio();
        this.audioItem = {};
        this.prepareAudioPlayer();
    }
}

AudioPlayer.prototype.prepareAudioPlayer = function () {
    const audioPlayer = document.querySelector('.audio-player');
    const timeline = audioPlayer.querySelector('.timeline');
    const progress = audioPlayer.querySelector('.progress');

    timeline.addEventListener("click", e => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const progressWidth = window.getComputedStyle(progress).width;
        let timeToSeek;
        if (e.target.classList.contains('timeline')) {
            timeToSeek = (parseInt(timelineWidth) - e.offsetX) / parseInt(timelineWidth) * this.audio.duration;
        }
        if (e.target.classList.contains('progress')) {
            timeToSeek = (parseInt(progressWidth) - e.offsetX) / parseInt(progressWidth) * this.audio.duration;
        }
        this.audio.currentTime = timeToSeek;
    }, false);

    const togglePlay = audioPlayer.querySelector('.toggle-play');
    togglePlay.addEventListener("click", () => {
        if (this.audio.paused) {
            this.audioPlay();
        } else {
            this.audioPause();
        }
    }, false);

    setInterval(() => {
        if (!this.audio.paused) {
            const progressBar = audioPlayer.querySelector(".progress");
            progressBar.style.width = this.audio.currentTime / this.audio.duration * 100 + "%";
            audioPlayer.querySelector(".time .current").textContent = this.getTimeCodeFromNum(this.audio.currentTime);
        }
        if (this.audio.currentTime == this.audio.duration) {
            this.audioPause();
        }
    }, 500);

    const closeBtn = audioPlayer.querySelector(".icon-x");
    closeBtn.addEventListener('click', () => {
        this.closeAudioPlayer();
    });

    this.addDragToPlayer(audioPlayer);
}

AudioPlayer.prototype.closeAudioPlayer = function() {
    this.audioPause();
    const audioPlayer = document.querySelector('.audio-player');
    audioPlayer.classList.remove('active');
    const lastCatalogNumber = audioPlayer.getAttribute('palyed-catalog-number');
    if (lastCatalogNumber) {
        const lastAudioLayer = document.querySelector(`[data-activity-page-catalog-number="${lastCatalogNumber}"]`);
        lastAudioLayer.classList.remove('active');
    }
}

AudioPlayer.prototype.audioPause = function() {
    this.audio.pause();
    const audioPlayer = document.querySelector('.audio-player');
    const togglePlay = audioPlayer.querySelector('.toggle-play');
    togglePlay.classList.remove("pause");
    togglePlay.classList.add("icon-play");
}
AudioPlayer.prototype.audioPlay = function() {
    this.audio.play();
    const audioPlayer = document.querySelector('.audio-player');
    const togglePlay = audioPlayer.querySelector('.toggle-play');
    togglePlay.classList.remove("icon-play");
    togglePlay.classList.add("pause");
}
AudioPlayer.prototype.setAudioInPlayer = function (audioItem) {
    this.audioPause();
    setLayerActive = setLayerActive.bind(this);
    this.audio.currentTime = 0;
    this.audio = new Audio(getSrcAudio());
    const audioPlayer = document.querySelector(".audio-player");
    setLayerActive();
    audioPlayer.querySelector(".time .current").textContent = this.getTimeCodeFromNum(this.audio.currentTime);
    audioPlayer.style.bottom = '15px';
    audioPlayer.style.right = '105px';
    audioPlayer.style.top = 'auto';
    audioPlayer.style.left = 'auto';

    const progressBar = audioPlayer.querySelector(".progress");
    progressBar.style.width = '0%';
    audioPlayer.classList.add('active');
    const audioName = audioPlayer.querySelector('.audio-name');
    audioName.innerHTML = audioItem.name.length > 27 ? audioItem.name.substring(0, 27) + '...' : audioItem.name;
    this.audio.addEventListener('loadeddata', () => {
        audioPlayer.querySelector(".time .length").textContent = this.getTimeCodeFromNum(this.audio.duration);
        this.audio.volume = 1;
        this.audioPlay();
    }, false);

    function getSrcAudio() {
        const extension = audioItem.name.split('.')[1];
        return MainData.environment.media + audioItem.folderUri + '/activity.' + extension;
    }
    function setLayerActive() {
        const lastCatalogNumber = audioPlayer.getAttribute('palyed-catalog-number');
        if (lastCatalogNumber) {
            const lastAudioLayer = document.querySelector(`[data-activity-page-catalog-number="${lastCatalogNumber}"]`);
            lastAudioLayer.classList.remove('active');
        }
        const currentAudioLayer = document.querySelector(`[data-activity-page-catalog-number="${audioItem.catalogNumber}"]`)
        audioPlayer.setAttribute('palyed-catalog-number', audioItem.catalogNumber);
        currentAudioLayer.classList.add('active');
        AudioPlayer.prototype.audioItem = audioItem;
    }
}

AudioPlayer.prototype.getTimeCodeFromNum = function(num) {
    let seconds = parseInt(num);
    let minutes = parseInt(seconds / 60);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
        seconds % 60
    ).padStart(2, 0)}`;
}

AudioPlayer.prototype.addDragToPlayer = function(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e = e || window.event;
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.addEventListener('mouseup', closeDragElement);
        document.addEventListener('mousemove', elementDrag);
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        const zoomFactor = Math.floor(MainData.zoomFactor * 100) / 100;
        pos1 = (pos3 - e.clientX) / zoomFactor;
        pos2 = (pos4 - e.clientY) / zoomFactor;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        elmnt.style.bottom = 'auto';
        elmnt.style.right = 'auto';
    }

    function closeDragElement(e) {
        e.preventDefault();
        e.stopPropagation();
        document.removeEventListener('mouseup', closeDragElement)   //onmouseup = null;
        document.removeEventListener('mousemove', elementDrag)  //onmousemove = null;
    }
}
