import { MainData } from "./mainData";
import {UiModal} from "@/js/ui-modal";

export class WordsEditor {
  constructor() {
    const wordsEditorToggle = document.querySelector(".editor-toggle");
    const tooltip = wordsEditorToggle.querySelector(".custom-tooltip");

    if (MainData.userType === "editor") {
      wordsEditorToggle.classList.add("show");
      wordsEditorToggle.addEventListener("click", e => {
        e.stopPropagation();
        if (wordsEditorToggle.classList.contains("icon-eye")) {
          wordsEditorToggle.classList.remove("icon-eye");
          wordsEditorToggle.classList.add("icon-eye-cross");
          tooltip.innerHTML = MainData.langData["Show words editor"];
          this.toggleWordsEditor();
        } else {
          wordsEditorToggle.classList.remove("icon-eye-cross");
          wordsEditorToggle.classList.add("icon-eye");
          tooltip.innerHTML = MainData.langData["Hide words editor"];
          this.toggleWordsEditor();
        }
      });
    }
  }
}

WordsEditor.prototype.prepareWordsForEditor = function() {
  preparing();
  this.initCreationNewWord();

  function preparing() {
    MainData.coordsData.forEach(page => {
      const pageEl = document.querySelector(
        `[data-page-id="${page.pageId}"]`
      );
      if (page.data && page.data.length && pageEl) {
        page.data.forEach(word => {
          if (word) {
            const wordBorderEl = MainData.wordsEditor.createElWordBorder(word);
            const wordInDB = MainData.wordsEditor.createElWordFromDB(word, page);
            pageEl.appendChild(wordBorderEl);
            pageEl.appendChild(wordInDB);
          }
        });
      }
    });
    const wordsEditorToggle = document.querySelector(".editor-toggle");
    wordsEditorToggle.classList.add("clickable");
  }
};

WordsEditor.prototype.preparingWordsForPage = function(pageId) {
  if (!MainData.initedWordsArr[pageId]) {
    const pageEl = document.querySelector(
      `[data-page-id="${pageId}"]`
    );
    const activePage = MainData.coordsData.find(el => el.pageId == pageId);
    if (activePage && activePage.data && activePage.data.length && pageEl) {
      activePage.data.forEach(word => {
        if (word) {
          const wordBorderEl = MainData.wordsEditor.createElWordBorder(word);
          const wordInDB = MainData.wordsEditor.createElWordFromDB(word, activePage);
          pageEl.appendChild(wordBorderEl);
          pageEl.appendChild(wordInDB);
        }
        MainData.initedWordsArr[pageId] = 1;
      });
    }
  }
}
WordsEditor.prototype.createElWordBorder = function(word) {
  let el = document.createElement("div");
  el.classList.add("border-word");
  if (word.index >= 0) {
    el.setAttribute("id-border-word", "id_border_" + word.index);
  }
  el.setAttribute("data-word", word.word);
  el.style.width = word.wPercent * 100 + "%";
  el.style.height = word.max_hPercent * 100 + "%";
  el.style.top = (word.yPercent - word.max_hPercent * 0.01) * 100 + "%";
  el.style.left = word.xPercent * 100 + "%";
  return el;
};

WordsEditor.prototype.createElWordFromDB = function(word, page) {
  let dbWordWrapper = document.createElement("div");
  dbWordWrapper.classList.add("db-word-wrapper");
  dbWordWrapper.style.width = word.wPercent * 105 + 1 + "%";
  dbWordWrapper.style.height = word.max_hPercent * 85 + "%";
  dbWordWrapper.style.top =
    (word.yPercent - word.max_hPercent * 0.85) * 100 + "%";
  dbWordWrapper.style.left = word.xPercent * 100 - 0.5 + "%";
  if (word.index >= 0) {
    dbWordWrapper.setAttribute("id-db-word", "id_db_" + word.index);
  }
  dbWordWrapper.setAttribute("data-word", word.word);

  let el = document.createElement("input");
  el.classList.add("db-word");
  el.value = word.word;
  el.style.fontSize =
    "calc((200vh - 145px)/2 *" + word.max_hPercent * 0.85 + ")";
  el.style.height = "calc((200vh - 145px)/2 *" + word.max_hPercent * 0.85 + ")";
  el.addEventListener("focus", e => {
    activateWordEditor(e, word, el);
  });
  // el.addEventListener('blur', (e) => {console.log(e);setDBWord(word, el)});
  dbWordWrapper.appendChild(el);

  const tooltip = createWordEditorTooltip(word, el, page);
  dbWordWrapper.append(tooltip);

  return dbWordWrapper;

  function createWordEditorTooltip(word, el, page) {
    const tooltipWrapper = document.createElement("div");
    tooltipWrapper.classList.add("word-tooltip-wrapper");

    const tooltip = document.createElement("div");
    tooltip.classList.add("word-tooltip");

    tooltipWrapper.appendChild(tooltip);

    const saveBtn = document.createElement("div");
    const removeBtn = document.createElement("div");
    saveBtn.classList.add("icon-save");
    removeBtn.classList.add("icon-delete");

    saveBtn.addEventListener("click", e => {
      updateWord(e, word, el, page);
    });
    removeBtn.addEventListener("click", e => {
      removeWord(e, el, page);
    });

    tooltip.appendChild(saveBtn);
    tooltip.appendChild(removeBtn);

    return tooltipWrapper;
  }

  function activateWordEditor(e, word, el) {
    e.stopPropagation();
    function func(e) {
      setTimeout(() => {
        el.classList.remove("active");
        if (word.word !== el.value) {
          el.value = word.word;
        }
        el.removeEventListener("blur", func);
      }, 200);
    }
    const allActiveWordInput = document.querySelectorAll(".db-word.active");
    allActiveWordInput.forEach(item => {
      item.classList.remove("active");
      document.removeEventListener("click", func);
    });
    const isInputActive = el.classList.contains("active");
    if (!isInputActive) {
      el.classList.add("active");
      el.addEventListener("blur", func);
    }
  }

  // function setDBWord(word, el) {
  //     setTimeout(() => {
  //         if (word.word !== el.value) {
  //             el.value = word.word;
  //         }
  //     }, 1000);
  // }

  function updateWord(e, word, el, page) {
    word.word = el.value;
    e.stopPropagation();

    const data = {
      bookId: MainData.bookId,
      pageId: page.pageId,
      pageNumber: page.pageNumber,
      wordData: {
        word: word.word,
        x: word.x,
        y: word.y,
        w: word.w,
        h: word.h,
        max_h: word.max_h,
        max_y: word.max_y
      }
    };

    const url = `${MainData.environment.pdfApi}page/updateWord`;
    let request = {};
    request.type = "PUT";
    request.contentType = 'application/json';
    request.data = JSON.stringify(data);
    request.url = url;
    request.beforeSend = function(xhr) {
      xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
    };
    request.success = res => {
      page.data = [...res.results.data];
      page.data.forEach(w => MainData.wordsEditor.setPercentWordCoords(w));
      WordsEditor.prototype.toggleStyle(page, word);
    };
    request.processData = true;
    $.ajax(request);
  }

  function removeWord(e, el, page) {
    e.stopPropagation();
    const currentWordIndex = el
      .closest("[id-db-word]")
      .getAttribute("id-db-word")
      .split("_")[2];

    const data = {
      bookId: MainData.bookId,
      pageId: page.pageId,
      wordIndex: currentWordIndex
    };
    console.log('click by delete button');
    UiModal.openUiModal(MainData.langData['Are you sure you want to delete it?'], true).subscribe((res) => {
      const url = `${MainData.environment.pdfApi}page/deleteWord`;
      let request = {};
      request.type = "DELETE";
      request.contentType = 'application/json';
      request.data = JSON.stringify(data);
      request.url = url;
      request.beforeSend = function(xhr) {
        xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
      };
      request.success = res => {
        page.data = res.result.page.data;
        page.data.forEach(w => MainData.wordsEditor.setPercentWordCoords(w));
        const pageEl = document.querySelector(`[data-page-id="${page.pageId}"]`);

        const inputWrappers = pageEl.querySelectorAll(
          `[id-db-word="id_db_${currentWordIndex}"]`
        );
        inputWrappers.forEach(item => {
          item.remove();
        });
        const borderEl = pageEl.querySelectorAll(
          `[id-border-word="id_border_${currentWordIndex}"]`
        );
        borderEl.forEach(item => {
          item.remove();
        });
        MainData.wordsEditor.changeWordsIndexesInElements(
          "-",
          currentWordIndex,
          pageEl
        );
      };
      request.processData = true;
      $.ajax(request);
    })
  }
};

WordsEditor.prototype.toggleStyle = function(page, word, res) {
  console.log(page);
  console.log(word);
  const pageEl = document.querySelector(`[data-page-id="${page.pageId}"]`);
  const newWord = page.data.find(el => el.index === word.index);
  console.log(pageEl);
  console.log(newWord);
  const wordRef = pageEl.querySelector(
    `[word-index="${word.index}"]`
  );
  // debugger;
  if (wordRef) {
    wordRef.style.opacity = newWord.type ? 1 : 0;
    if (!newWord.type) {
      wordRef.style.userSelect = 'none';
    } else {
      wordRef.style.borderBottom = '2px solid #6c90ff';
      wordRef.style.cursor = 'pointer';
    }
    wordRef.style.fontSize = newWord.type ? 0 : "calc((200vh - 145px)/2 *" + newWord.max_hPercent + ")";
  }
}
WordsEditor.prototype.toggleWordsEditor = function() {
  const body = document.querySelector("body");
  body.classList.toggle("editing-word");
};

WordsEditor.prototype.initCreationNewWord = function() {
  const mainConteiner = document.querySelector(".main-conteiner");
  let pageEl;

  function setMousePosition(e) {
    let ev = e || window.event;

    const path = e.path || (e.composedPath && e.composedPath());

    const isOtherElement = path.find(item => {
      if (item.classList && item.classList.length) {
        return (
          item.classList.contains("mark-tooltip-wrapper") ||
          item.classList.contains("word-tooltip-wrapper")
        );
      }
    });
    if (!isOtherElement) {
      for (let item of path) {
        if (item.classList && item.classList.contains("page")) {
          pageEl = item;
          const parentPos = pageEl.getBoundingClientRect();
          mouse.x = (ev.pageX - parentPos.left) / MainData.zoomFactor;
          mouse.y = (ev.pageY - parentPos.top) / MainData.zoomFactor;
          break;
        }
      }
    }
  }

  let mouse = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0
  };
  let element = null;

  mainConteiner.addEventListener(
    "mousedown",
    function(e) {
      if (document.querySelector(".icon-eye") && pageEl && e.which == 1) {
        mouse.startX = mouse.x;
        mouse.startY = mouse.y;
        element = document.createElement("div");
        element.className = "rectangle";
        element.style.left = mouse.startX + "px";
        element.style.top = mouse.startY + "px";

        pageEl.appendChild(element);
      }
    },
    false
  );

  mainConteiner.onmousemove = function(e) {
    if (document.querySelector(".icon-eye")) {
      setMousePosition(e);

      if (element !== null) {
        element.style.width = Math.abs(mouse.x - mouse.startX) + "px";
        element.style.height = Math.abs(mouse.y - mouse.startY) + "px";
        element.style.left =
          mouse.x - mouse.startX < 0 ? mouse.x + "px" : mouse.startX + "px";
        element.style.top =
          mouse.y - mouse.startY < 0 ? mouse.y + "px" : mouse.startY + "px";
      }
    }
  };

  mainConteiner.addEventListener("mouseup", function(e) {
    if (document.querySelector(".icon-eye") && pageEl && element) {
      if (mouse.x - mouse.startX > 5 && mouse.y - mouse.startY > 5) {
        const elementPos = element.getBoundingClientRect();
        const parentPos = pageEl.getBoundingClientRect();

        let sourceX = elementPos.left - parentPos.left;
        let sourceY = elementPos.top - parentPos.top;
        const word = {
          h: elementPos.height,
          w: elementPos.width,
          x: sourceX,
          y: sourceY,
          word: "...",
          max_h: elementPos.height,
          max_y: elementPos.top,
          index: null
        };

        word.xPercent = word.x / parentPos.width;
        word.yPercent = word.y / parentPos.height;
        word.max_yPercent = word.y / parentPos.height;
        word.wPercent = word.w / parentPos.width;
        if (word.h / parentPos.height < 0.5) {
          word.hPercent = word.h / parentPos.height;
          word.max_hPercent = word.max_h / parentPos.height;
        } else {
          word.hPercent = 0;
        }

        const page = MainData.coordsData.find(
          item => item.pageId == pageEl.getAttribute("data-page-id")
        );

        word.indexCalc = calculateIndexOfNewWord(word, page);

        const elWordBorder = MainData.wordsEditor.createElWordBorder(word);
        const elWordFromDB = MainData.wordsEditor.createElWordFromDB(word, page);
        MainData.wordsEditor.setPercentWordCoords(word);
        if (page.data) {
          page.data.push(word);
        } else {
          page.data = [word];
        }
        elWordBorder.classList.add("active");
        elWordFromDB.classList.add("active");
        pageEl.appendChild(elWordBorder);
        pageEl.appendChild(elWordFromDB);

        createWord(word, page, elWordBorder, elWordFromDB);
      }
      if (element) {
        element.remove();
      }
      element = null;
    }
  });

  function calculateIndexOfNewWord(word, page) {
    word.h = Math.round(word.hPercent * MainData.originalPageSize.height);
    word.max_h = +word.h;
    word.w = Math.round(word.wPercent * MainData.originalPageSize.width);
    word.x = Math.round(word.xPercent * MainData.originalPageSize.width);
    word.y = Math.round(word.yPercent * MainData.originalPageSize.height);
    word.max_y = +word.y;
    const wordsArr = page.data;
    let wordClosest;
    let minTotalDif;
    let index;
    if (wordsArr) {
      wordsArr.forEach(item => {
        let xDiff = Math.abs(word.x - item.x);
        let yDiff = +word.y + +word.h + 30 - item.y;
        let totalDiff = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));
        if (
          (!minTotalDif && yDiff >= 0) ||
          (yDiff >= 0 && totalDiff < minTotalDif)
        ) {
          minTotalDif = totalDiff;
          wordClosest = item;
        }
        if (yDiff < 0) return;
      });
    }

    if (wordClosest) {
      if (Math.abs(wordClosest.y - word.y) > +word.h) {
        const arrForIndex = wordsArr.filter(item => +item.y <= +word.y + 10);
        index = Math.max(...arrForIndex.map(w => +w.index)) + 1;
        return index;
      }
      if (+wordClosest.x < +word.x) {
        index = +wordClosest.index;
      } else {
        index = +wordClosest.index + 1;
      }
    } else {
      index = 0;
    }
    return index;
  }

  function createWord(word, page, elWordBorder, elWordFromDB) {
    const data = {
      bookId: MainData.bookId,
      pageId: page.pageId,
      pageNumber: page.pageNumber,
      wordData: {
        word: word.word,
        index: word.indexCalc,
        x: word.x,
        y: word.y,
        w: word.w,
        h: word.h,
        max_h: word.max_h,
        max_y: word.max_y
      }
    };

    const url = `${MainData.environment.pdfApi}page/addWord`;
    let request = {};
    request.type = "POST";
    request.data = data;
    request.url = url;
    request.beforeSend = function(xhr) {
      xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
    };
    request.success = async function(res) {
      page.data = res.results.data;
      page.data.forEach(w => MainData.wordsEditor.setPercentWordCoords(w));
      const pageEl = document.querySelector(`[data-page-id="${page.pageId}"]`);
      const currentWordIndex = word.indexCalc;
      await MainData.wordsEditor.changeWordsIndexesInElements(
        "+",
        currentWordIndex,
        pageEl
      );
      word.index = word.indexCalc;

      elWordBorder.setAttribute(
        "id-border-word",
        "id_border_" + word.indexCalc
      );
      elWordFromDB.setAttribute("id-db-word", "id_db_" + word.indexCalc);
    };
    request.processData = true;
    $.ajax(request);
  }
};

WordsEditor.prototype.changeWordsIndexesInElements = function(
  indexDeviation,
  currentWordIndex,
  pageEl
) {
  let dbWordElements = pageEl.querySelectorAll("[id-db-word]");
  const dbAttributeName = "id-db-word";
  const dbAttributePrefix = "id_db_";
  changeElementsArray(
    currentWordIndex,
    dbWordElements,
    indexDeviation,
    dbAttributeName,
    dbAttributePrefix
  );

  let borderWordElements = pageEl.querySelectorAll("[id-border-word]");
  const borderAttributeName = "id-border-word";
  const borderAttributePrefix = "id_border_";
  changeElementsArray(
    currentWordIndex,
    borderWordElements,
    indexDeviation,
    borderAttributeName,
    borderAttributePrefix
  );

  function changeElementsArray(
    currentWordIndex,
    elementsArray,
    indexDeviation,
    attributeName,
    attributePrefix
  ) {
    const startElement = [...elementsArray].find(item => {
      let checkIndex;
      if (indexDeviation === "+") {
        checkIndex = +currentWordIndex;
      }
      if (indexDeviation === "-") {
        checkIndex = +currentWordIndex + 1;
      }
      return +item.getAttribute(attributeName).split("_")[2] === checkIndex;
    });
    if (startElement) {
      elementsArray = [...elementsArray].sort((a, b) => {
        return (
          +a.getAttribute(attributeName).split("_")[2] -
          +b.getAttribute(attributeName).split("_")[2]
        );
      });
      const indexOfStartElement = elementsArray.indexOf(startElement);
      elementsArray = elementsArray.slice(indexOfStartElement);

      let wordIndex;

      elementsArray.forEach(wordEl => {
        if (wordEl.getAttribute(attributeName)) {
          wordIndex = +wordEl.getAttribute(attributeName).split("_")[2];
          if (indexDeviation === "+") wordIndex += 1;
          if (indexDeviation === "-") wordIndex -= 1;
          wordEl.setAttribute(attributeName, attributePrefix + wordIndex);
        }
      });
    }
  }
};

WordsEditor.prototype.setPercentWordCoords = function(word) {
  if (!word.max_h) {
    word.max_h = word.h;
  }
  if (!word.max_y) {
    word.max_y = word.y;
  }
  word.xPercent = word.x / MainData.originalPageSize.width;
  word.yPercent = word.y / MainData.originalPageSize.height;
  word.wPercent = word.w / MainData.originalPageSize.width;
  word.h / MainData.originalPageSize.height < 0.5
    ? (word.hPercent = word.h / MainData.originalPageSize.height)
    : 0;
  word.max_h / MainData.originalPageSize.height < 0.5
    ? (word.max_hPercent = word.max_h / MainData.originalPageSize.height)
    : 0;
  // word.max_hPercent = word.max_h/MainData.originalPageSize.height;
  word.max_yPercent = word.y / MainData.originalPageSize.height;
};
