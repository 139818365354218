export class MainData {
  constructor() {
   this.ministryOfEducationSerialCode;
   this.watchingTimer;
   this.watchingPage;
   this.user;
   this.token;
   this.userTypesInterface;
   this.userType;
   this.environment;
   this.bookId;
   this.currentPage;
   this.translator;
   this.classId;
   this.languageId;
   this.fromBookReturnUrl;
   this.originalPageSize;
   this.coordsData;
   this.header;
   this.activities;
   this.bookChapters;
   this.bookPages;
   this.sidebarObj;
   this.wordsEditor;
   this.userId;
   this.markColor;
   this.navbarObj;
   this.audioPlayer;
   this.linksAndComments;
   this.activityModal;
   this.MarksClass;
   this.marksData;
   this.selectedClassListIds;
   this.classList;
   this.langData;
   this.isRow;
   this.zoomFactor;
   this.translateX;
   this.inCreateMode;
   this.isFullscreen;
   this.bookmark;
   this.teacherUserId;
   this.book;
   this.isShowOnlyAnnExercisePages;
   this.pageNambers;
   this.annToken;
   this.subActivePage$;
   this.selectedActivities$;
   this.initedWordsArr;
  }
}
