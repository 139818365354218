import { MainData } from "./mainData";

export class ActivityModal {
	constructor() {}
}

ActivityModal.prototype.initActivityModalContent = function (activity) {
    const modalWrapper = document.querySelector('.activity-modal-content-wrapper');
    modalWrapper.classList.add('active');

    const modalContentInner = document.createElement('div');
    modalContentInner.classList.add('modal-content-inner');
    modalContentInner.appendChild(initModalHeader());
    modalContentInner.appendChild(initModalBody());

    modalWrapper.appendChild(modalContentInner);

    function initModalHeader() {
        const modalHeader = document.createElement('div');
        modalHeader.classList.add('modal-header-wrapper');
        const btnX = document.createElement('div');
        btnX.classList.add('icon-x');
        btnX.addEventListener('click', (e) => {
            e.stopPropagation();
            ActivityModal.prototype.closeActivityModal();
        });
        const customTooltip = document.createElement('div');
        customTooltip.classList.add('custom-tooltip');
        customTooltip.innerText = MainData.langData['Close'];
        btnX.appendChild(customTooltip);

        const activityTitle = document.createElement('div');
        activityTitle.classList.add('title');
        activityTitle.innerText = activity.name;


        modalHeader.appendChild(btnX);
        modalHeader.appendChild(activityTitle);

        return modalHeader;
    }

    function initModalBody() {
        const modalBody = document.createElement('div');
        modalBody.classList.add('modal-body-wrapper');

        const activityCardInfo = document.createElement('div');
        activityCardInfo.classList.add('activity-card-info');

        const infoBody = document.createElement('div');
        infoBody.classList.add('info-body');

        infoBody.appendChild(initImgWrapper());
        infoBody.appendChild(initInfoWrapper());

        activityCardInfo.appendChild(infoBody);
        modalBody.appendChild(activityCardInfo);
        return modalBody;
    }

    function initImgWrapper() {
        const imgWrapper = document.createElement('div');
        imgWrapper.classList.add('img-wrapper');
        const img = document.createElement('img');
        img.src = MainData.environment.media + activity.thumbnailUri;
        img.setAttribute('onError', 'this.src="../imgs/Icon_Activities.svg"');

        const playWrapper = document.createElement('div');
        playWrapper.classList.add('play-wrapper');
        const iconPlay = document.createElement('div');
        iconPlay.classList.add('icon-primary', 'icon-play');
        iconPlay.addEventListener('click', (e) => {
            e.stopPropagation();
            ActivityModal.prototype.closeActivityModal();
            const pageNumber = document.querySelector(`[data-page-id='${activity.pageId}'`).getAttribute('data-page-number');
            MainData.navbarObj.changePage(pageNumber);
            MainData.sidebarObj.onPlayActivity(activity.catalogNumber);
        });
        playWrapper.appendChild(iconPlay);

        imgWrapper.appendChild(img);
        imgWrapper.appendChild(playWrapper);

        return imgWrapper;
    }

    function initInfoWrapper() {
        const infoWrapper = document.createElement('div');
        infoWrapper.classList.add('info-wrapper');

        infoWrapper.appendChild(initActions());

        const description = document.createElement('div');
        description.classList.add('info-description');
        if (activity.description) {
            description.innerText = activity.description;
        }
        infoWrapper.appendChild(description);
        infoWrapper.appendChild(initInfoStats());

        return infoWrapper;
    }

    function initActions() {
        const actions = document.createElement('div');
        actions.classList.add('actions');

        const btnLike = document.createElement('div');
        btnLike.classList.add('icon-heart', 'icon-primary-white');
        if (activity.isLike) {
            btnLike.classList.add('active');
        }
        btnLike.addEventListener('click', (e) => {
            e.stopPropagation();
            MainData.sidebarObj.activeActivity = activity;
            btnLike.classList.toggle('active');
            MainData.sidebarObj.likeActivity();
        });

        const customTooltip = document.createElement('div');
        customTooltip.classList.add('custom-tooltip');
        customTooltip.innerText = MainData.langData['I liked'];

        btnLike.appendChild(customTooltip);

        actions.appendChild(btnLike);
        return actions;
    }

    function initInfoStats() {
        const infoStats = document.createElement('div');
        infoStats.classList.add('info-stats');

        const classItem = document.createElement('div');
        classItem.classList.add('stats-item', 'class-item');

        const classTitle = document.createElement('div');
        classTitle.classList.add('stats-title', 'class-title');
        classTitle.innerText = MainData.langData['Class'];

        const classValue = document.createElement('div');
        classValue.classList.add('class-value');
        if (activity.classesId) {
            classValue.innerText = ActivityModal.prototype.ClassesName[activity.classesId];
        }
        classItem.appendChild(classTitle);
        classItem.appendChild(classValue);

        const subjectItem = document.createElement('div');
        subjectItem.classList.add('stats-item', 'subject-item');

        const subjectTitle = document.createElement('div');
        subjectTitle.classList.add('stats-title', 'subject-title');
        subjectTitle.innerText = MainData.langData['Subject'];

        const subjectValue = document.createElement('div');
        subjectValue.classList.add('.subject-value');
        if (activity.subjectName && activity.subjectName.length) {
            if (activity.activityType.activityTypeId == 6) {
                subjectValue.innerText = MainData.langData['Subject']
            } else {
                subjectValue.innerText = activity.subjectName.join(', ');
                if (subjectValue.innerText.length > 30) {
                    subjectValue.innerText = subjectValue.innerText.substring(0, 30) + '...';
                }
            }
        }
        subjectItem.appendChild(subjectTitle);
        subjectItem.appendChild(subjectValue);

        infoStats.appendChild(classItem);
        infoStats.appendChild(subjectItem);

        return infoStats;
    }
}

ActivityModal.prototype.closeActivityModal = function() {
    const modalWrapper = document.querySelector('.activity-modal-content-wrapper');
    const modalContentInner = document.querySelector('.modal-content-inner');
    modalWrapper.classList.remove('active');
    modalContentInner.remove();
}

ActivityModal.prototype.ClassesName = {
    1: "א",
    2: "ב",
    3: "ג",
    4: "ד",
    5: "ה",
    6: "ו",
    7: "ז",
    8: "ח",
    9: "ט",
    10: "י",
   11: "יא",
   12: "יב"
}
