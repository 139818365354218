import { MainData } from './mainData.js';
import { Search } from './search.js';

export class Translator {
	constructor(languageId) {
    switch (languageId) {
      case 1: {
        this.lang = "he";
        break;
      }
      case 2: {
        this.lang = "ar";
        break;
      }
    }
    readTextFile("./translate_json/" + this.lang + ".json", function(text) {
      const langData = JSON.parse(text);
      MainData.langData = langData;
      const translationArr = document.querySelectorAll("[data-translation]");
      translationArr.forEach(el => {
        const dataTranslation = el.getAttribute("data-translation");
        el.innerHTML = langData[dataTranslation];
      });
      initTranslationForParts();
    });
    // if (!MainData.langData) {
    //   MainData.langData = hebrewWords;
    //   initTranslationForParts();
    // }
  
    function readTextFile(file, callback) {
      var rawFile = new XMLHttpRequest();
      rawFile.overrideMimeType("application/json");
      rawFile.open("GET", file, true);
      rawFile.onreadystatechange = function() {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
          callback(rawFile.responseText);
        }
      };
      rawFile.send(null);
    }
  
    function initTranslationForParts() {
      new Search();
    }
	}
}

// const hebrewWords = {
//   "Show words editor": "הצג עורך מילים",
//   "Hide words editor": "עורך מילים להסתיר",
//   Search: "חיפוש",
//   Drag: "גרירה",
//   Emphasis: "הדגשה",
//   "Add a link": "הוספת קישור",
//   "Add a comment": "הוספת הערה",
//   "Two-page view": "תצוגת שני עמודים",
//   "Full screen": "מסך מלא",
//   "Enlarge the view": "הגדלת התצוגה",
//   "Reduce the view": "הקטנת התצוגה",
//   Printing: "הדפסה",
//   Close: "סגירה",
//   "Table of Contents": "תוכן העניינים",
//   "Information layers": "שכבות מידע",
//   "My layers": "השכבות שלי",
//   "Lesson plans": "מערכי שיעור",
//   "Soon. Lesson plans": "בקרוב. מערכי שיעור",
//   "Beyond book display": "מעבר לתצוגת ספר",
//   "Back to the bookmark": "בחזרה לסימניה",
//   "Previous page": "דף הקודם",
//   Page: "עמוד",
//   Of: "מתוך",
//   "Next page": "דף הבא",
//   "Bookmark the page with the bookmark": "סמן את העמוד עם הסימניה",
//   "Get out of full screen": "לצאת ממסך מלא",
//   Filter: "סינון",
//   "Book content": "הספר",
//   "Clear the election": "נקה את הבחירות",
//   "Start searching": "תתחיל לחפש",
//   Storyline: "פעילות",
//   Games: "משחקים",
//   Worksheets: "דפי עבודה",
//   Illustrationmeans: "אמצעי המחשה",
//   Lessons: "מערכי שיעור",
//   Tests: "מבדקים",
//   Videos: "סרטונים",
//   Video: "סרטונים",
//   Audio: "שֶׁמַע",
//   Audios: "שֶׁמַע",
//   Links: "קישורים",
//   "Tab view": "תצוגת כרטיסיות",
//   "Row view": "תצוגת שורות",
//   "I liked": "אהבתי",
//   Class: "שכבה",
//   Subject: "נושא",
//   Ribs: "מְצֻלָּעִים",
//   "No subject": "ללא נושא",
//   Share: "שתף את",
//   "Copy link": "העתק לינק",
//   Link: "קישור",
//   Comment: "הערה",
//   Cancel: "בטל",
//   Save: "שמור",
//   "Please switch to horizontal view": "נא לעבור לתצוגה אופקית",
//   "You have reached the maximum page size": "הגעת לגודל הדף המקסימלי",
//   "You have reached the minimum page size": "הגעת לגודל הדף המינימלי",
//   "Play activity": "הפעל פעילות",
//   Information: "מידע",
//   Publish: "לפרסם",
//   Results: "תוצאות",
//   "P.": "עמ׳",
//   "Filters selected": "סינונים נבחרו",
//   "Progress in the book": "התקדמות בספר",
//   "Cover page": "כריכה",
//   "Advertising for all classes": "פרסום לכל הכיתות",
//   "All classes": "כל הכיתות",
//   Everything: "הכל"
// };
