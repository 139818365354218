import { Action } from './actions.js';
import { MainData } from './mainData.js';
import { Utils } from "@/js/utils";
import {Sidebar} from "@/js/sidebar";

export class Navbar {
  constructor(data) {
    this.pagesAmount = MainData.isShowOnlyAnnExercisePages ? MainData.bookPages.length: data.pagesAmount - 1;
    this.nextArrowBtn = document.querySelector(".icon-nav-arrow-left");
    this.prevArrowBtn = document.querySelector(".icon-nav-arrow-right");
    //this.gotoBookmarkBtn = document.querySelector(".go-to-bookmark");
    this.bookmarkBtn = document.querySelector(".navbar-bookmark-btn");
    this.input = document.querySelector("#currentPageInput");

    this.nextArrowBtn.addEventListener("click", this.nextPage.bind(this));
    this.prevArrowBtn.addEventListener("click", this.prevPage.bind(this));
    this.input.addEventListener("blur", this.onNavBlur.bind(this));
    this.input.addEventListener("focus", this.onNavFocus.bind(this));
    this.input.addEventListener("keypress", this.onKeyPressInput.bind(this));

    if (Utils.isHardcodedBookmarks()) {
      Utils.setStylesToAllBookmarks();
      Utils.setLastBookmark();
    } else {
      MainData.bookmark = data.bookmark && MainData.userType !== 'editor' ? data.bookmark: null;
    }

    setTimeout(() => {
      const page = document.querySelector(`#page${MainData.currentPage}`);
      page.scrollIntoView();
    }, 500);

    this.showCurrentPage();
    this.setPageAmount();

    if (MainData.userType !== 'editor') {
      //this.gotoBookmarkBtn.style.display = 'flex';
      this.bookmarkBtn.style.display = 'flex';
      //this.gotoBookmarkBtn.addEventListener("click", this.gotoBookmark.bind(this));
      this.bookmarkBtn.addEventListener("click", () => {
        this.onBookmarkEvent();
      });
    }

    this.initCardShareTooltip();
    const shareToggle = document.querySelector(".no-fullscreen-nav .share-toggle");
    shareToggle.addEventListener("click", (e) => {
      this.showCardShareTooltip(e);
    });


    // const pageWrapper = document.querySelector(`#page${MainData.pageNambers[0]}`);
    const pageWrapper = document.querySelector(`#page0`);

    const containerWrapper = document.querySelector(".conteiner-wrapper");


    function setReadBook(){
      return new Promise((resolve, reject) => {
        const url = `${MainData.environment.webHook}api/event/book-read`;
        let request = {};
        const chapter = MainData.bookChapters.find((chapter) => {
          return chapter.startPage <= MainData.currentPage && chapter.endPage >= MainData.currentPage
        })
        // console.log(MainData.currentPage);
        // console.log(MainData.bookChapters);
        // console.log(chapter);
        request.data = JSON.stringify({
          legalIdentity: MainData.user.legalIdendifier, // useridentity
          bookId: MainData.bookId, //bookId
          pageNumber: `${MainData.currentPage}`, //current page number
          topics: chapter ? chapter?.topics : [],
          serialNumber: `${MainData?.ministryOfEducationSerialCode}`
        });
        request.type = 'POST';
        request.url = url;
        request.contentType = 'application/json';
        // console.log(request);
        request.beforeSend = function (xhr) {
          xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
        };
        request.success = (res) => {
          resolve(res);
        }
        request.error = (err) => {
          reject(err);
        }
        $.ajax(request);
      })
    }

    function setLastActivePage(){
      return new Promise((resolve, reject) => {
        const page = MainData.currentPage - 1;
        const pageId = MainData.bookPages.find(p => p.number == page).pageId;
        const url = `${MainData.environment.coreApi}api/v2/User/SetLastActiveBookPage`;
        let request = {};
        const body = {
          bookId: MainData.bookId, //bookId
          classLevelId: MainData.classId,
          pageId: pageId
        }
        // console.log("------------------");
        // console.log(MainData.currentPage);
        // console.log(body.pageId);
        // console.log("------------------");
        request.data = JSON.stringify(body);
        request.type = 'POST';
        request.url = url;
        request.contentType = 'application/json';
        // console.log(request);
        request.beforeSend = function (xhr) {
          xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
        };
        request.success = (res) => {
          resolve(res);
        }
        request.error = (err) => {
          reject(err);
        }
        $.ajax(request);
      })
    }
    function lookingPage() {
      var timeleft = 5;
      MainData.watchingTimer = setInterval(function(){
        if (timeleft === 0) {
          console.log('send request');
          setLastActivePage().then((res) => {
            // console.log(res);
            // console.log("asdasdasdasdasdasd");
          });
          setReadBook().then(() => {
            clearInterval(MainData.watchingTimer);
            MainData.watchingTimer = null;
            // console.log('finish request');
          }, (err) => {
            clearInterval(MainData.watchingTimer);
            MainData.watchingTimer = null;
            // console.log('finish request', err);
          })
        }
        MainData.timeOnPage = 5 - timeleft;
        timeleft -= 1;
      }, 1000);
    }
    containerWrapper.addEventListener("scroll", () => {
      if (MainData.isFullscreen !== false) {
        let pageHeight = pageWrapper.getBoundingClientRect().height;
        const page = document.querySelector(".conteiner-wrapper").scrollTop / pageHeight;
        // MainData.currentPage = Math.floor(page - Math.floor(page) > 0.6 ? page + 1 : page) || 1;
        MainData.currentPage = Math.floor(page - Math.floor(page) > 0.6 ? page + 1 : page);
        MainData.subActivePage$.next(MainData.currentPage);
        // console.log(MainData.currentPage);
        const nextPage  = page - Math.floor(page) > 0.3 && page - Math.floor(page) < 0.6;
        const wrapper = document.querySelector(".conteiner-wrapper");
        if (MainData.watchingPage !== MainData.currentPage && !MainData.watchingTimer) {
          lookingPage();
        }
        MainData.watchingPage = MainData.currentPage;
        if (nextPage){
          wrapper.classList.add("scrolledHalf");
        } else {
          wrapper.classList.remove("scrolledHalf");
        }
        if (MainData.isRow) {
          MainData.currentPage *= 2;
        }
        this.showCurrentPage();
      } else {
        setTimeout(() => {
          MainData.isFullscreen = null;
        }, 600);
      }
    });

    document.addEventListener("onBookmark", function(event) {
        // if (MainData.userType === 'teacher') {
          this.setBookmark(event.detail.page);
        // }
      }.bind(this)
    );
    setTimeout(() => {
      this.initBookmark();
    }, 1000);
  }
}

Navbar.prototype.nextArrowBtn = null;
Navbar.prototype.prevArrowBtn = null;
Navbar.prototype.gotoBookmarkBtn = null;
Navbar.prototype.bookmarkBtn = null;
Navbar.prototype.input = null;

Navbar.prototype.onKeyPressInput = function(e) {
  if (e.key === "Enter") {
    this.onNavBlur(e);
  }
};

Navbar.prototype.onNavBlur = function(e) {
  this.changePageFromInput(event);
  e.target.value = "";
  this.showNavBar = false;
  this.checkNavPlaceholder();
};

Navbar.prototype.onNavFocus = function(e) {
  e.target.placeholder = "";
};

Navbar.prototype.gotoBookmark = function() {
  if (MainData.bookmark && MainData.bookmark.pageNumber !== undefined) {
    this.changePage(MainData.bookmark.pageNumber);
  }
};

Navbar.prototype.deleteBookmark = function(activeBookmark ) {
  let url;
  const request = {};
  url = MainData.environment.coreApi + `api/v2/Bookmark/${activeBookmark.id}`;
  request.type = "DELETE";
  request.contentType = 'application/json';
  request.url = url;
  request.beforeSend = function(xhr) {
    xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  };
  request.success = res => {
    this.bookmarkBtn.classList.remove("active");
    $(`.exercise-item[id=${activeBookmark.pageId}] .wrap-bookmark`).removeClass('bookmark-added').data('bookmark-id', '');
    $(`.displayPair [data-page-id=${activeBookmark.pageId}] .ui-bookmark`).removeClass('active');

    MainData.bookmarks = MainData.bookmarks.filter(p => p.id !== activeBookmark.id);
    Sidebar.prototype.bookmarks$.next(MainData.bookmarks);
    MainData.notify.show(MainData.langData["Bookmark deleted"], { type: "success" });
  };
  request.processData = true;
  $.ajax(request);
};
Navbar.prototype.onBookmark = function(pageId, pageNumber) {
  this.bookmarkBtn.classList.add("active");

  let url;
  const request = {};
    url = MainData.environment.coreApi + `api/v2/Book/${MainData.bookId}/bookmark`;
    request.type = "POST";
    request.data = JSON.stringify({
      pageId: pageId
    });
  request.contentType = 'application/json';
  request.url = url;
  request.beforeSend = function(xhr) {
    xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  };
  request.success = res => {
    $(`.exercise-item[id=${pageId}] .wrap-bookmark`).addClass('bookmark-added');
    $(`.displayPair [data-page-id=${pageId}] .ui-bookmark`).addClass('active');
    Sidebar.prototype.bookmarks$.next([...MainData.bookmarks, { ...res, pageNumber: pageNumber }]);
    MainData.notify.show(MainData.langData["The page has been marked successfully"], { type: "success" });
  };
  request.processData = true;
  $.ajax(request);
};

Navbar.prototype.setPageAmount = function() {
  document.querySelector(".pages-amount-value").innerText = this.pagesAmount;
};

Navbar.prototype.changePages = function(next) {
  if (MainData.isShowOnlyAnnExercisePages) {
    const index = MainData.pageNambers.findIndex((id) => id === MainData.currentPage);
    if (index !== -1) {
      MainData.currentPage = MainData.pageNambers[ index + ( next ? + 1 : -1) ];
    }
  } else {
    if (MainData.currentPage - 1 < 0) return;
    MainData.currentPage -= 1;
  }
  MainData.subActivePage$.next(MainData.currentPage);
};
Navbar.prototype.nextPage = function() {
  if (MainData.currentPage + 1 > this.pagesAmount) return;
  MainData.currentPage += 1;
  const page = document.querySelector(`#page${MainData.currentPage}`);
  if (page) {
    page.scrollIntoView();
    this.showCurrentPage();
  }
};

Navbar.prototype.prevPage = function() {
  if (MainData.currentPage - 1 < 0) return;
  MainData.currentPage -= 1;
  const page = document.querySelector(`#page${MainData.currentPage}`);
  if (page) {
    page.scrollIntoView();
    this.showCurrentPage();
  }
};

Navbar.prototype.changePageFromInput = function(e) {
  if (!e.target.value) return;
  if (this.pagesAmount < 1) return;
  let value = +e.target.value + 1;
  if (isNaN(value)) {
    e.target.value = "";
    e.target.blur();
    return;
  }
  if (!value || e.target.value == MainData.currentPage - 1) return;
  if (e.target.value >= this.pagesAmount) value = this.pagesAmount;
  if (e.target.value <= 0) value = 0;

  this.changePage(value);
};

Navbar.prototype.onBookmarkEvent = function() {
  const onBookmarkEvent = new CustomEvent("onBookmark", {
    detail: { page: MainData.currentPage }
  });
  document.dispatchEvent(onBookmarkEvent);
  // MainData.sidebarObj.recalculateContentItems();
};

Navbar.prototype.changePage = function(page) {
  if (page !== undefined) {
    MainData.currentPage = page;
    MainData.subActivePage$.next(MainData.currentPage);
    // console.log(MainData.currentPage);
    const newPage = document.querySelector(`#page${MainData.currentPage}`);
    if (newPage) {
      newPage.scrollIntoView();
      this.showCurrentPage();
      MainData.sidebarObj.recalculateContentItems();
    }
  }
};

Navbar.prototype.showCurrentPage = function() {
  this.checkNavPlaceholder();

  MainData.sidebarObj.scrollActivitiesLinksBody();

  if (Utils.isHardcodedBookmarks() ? Utils.isPageAddedToBookmark(MainData.currentPage) : this.isPageBookmarked(MainData.currentPage)) {
    this.bookmarkBtn.classList.add("active");
    //this.gotoBookmarkBtn.classList.add("disabled");
    return;
  }

  //this.gotoBookmarkBtn.classList.remove("disabled");
  document.querySelector(".navbar-bookmark-btn").classList.remove("active");

  const shareToggle = document.querySelector('.no-fullscreen-nav .share-toggle');
  if (shareToggle.classList.contains('active')) {
    this.hideCardShareTooltip();
  }
};

Navbar.prototype.isPageBookmarked = function(page) {
  const currentBookmark = $("#page" + page);
  if (!currentBookmark) {
    console.log('currentBookmark -', !currentBookmark);
    return false;
  }
  const clickedBookmark = currentBookmark.find(".ui-bookmark");
  return clickedBookmark ? clickedBookmark.hasClass('active') : false;
};

Navbar.prototype.checkNavPlaceholder = function() {
  const pagesInRow = document.querySelector('.pages-in-row');
  if (MainData.currentPage == 0 && !this.showNavBar) {
    this.input.placeholder = MainData.langData["Cover page"];
  } else {
    // const page = MainData.currentPage - (!pagesInRow ? 2 : 1);
    // this.input.placeholder = page < 0 ? 0 : page;
    this.input.placeholder = MainData.currentPage - 1;
  }
};

Navbar.prototype.initBookmark = function() {
  if (MainData.bookmark && MainData.bookmark.pageNumber != undefined && MainData.userType !== 'editor') {
    const bookmarkedPage = document.querySelector(
      `#page${MainData.bookmark.pageNumber} .ui-bookmark`
    );
    if (bookmarkedPage) {
      bookmarkedPage.classList.add("active");
    }
  }
};

Navbar.prototype.setBookmark = function(page) {
  console.log(page);

  const pageId = MainData.bookPages.find(p => p.number == page).pageId;
  if (Utils.isHardcodedBookmarks()) {
    const bookmarkData = {
      ClassId: MainData.classId,
      BookId: MainData.bookId,
      PageId: pageId,
      pageNumber: page
    }
    Utils.toggleBookMark(bookmarkData);
  } else {
    const id = $('#page' + page).attr("data-page-id") || pageId;
    const isAddedBookmark = MainData.bookmarks.find((el) => el.pageId == id);
    console.log( 'isAddedBookmark - ', isAddedBookmark );
    if (isAddedBookmark) {
      this.deleteBookmark(isAddedBookmark);
    } else {
      this.onBookmark(id, page);
    }
  }
};

Navbar.prototype.initCardShareTooltip = function() {
  const cardShareTooltip = document.querySelector('.no-fullscreen-nav .share-toggle .card-share-tooltip');
  const iconX = cardShareTooltip.querySelector('.icon-x');
  iconX.addEventListener('click', (e) => {
    e.stopPropagation();
    this.hideCardShareTooltip();
  });

  const tooltipBtn = cardShareTooltip.querySelector('.tooltip-btn');
      const btnText = tooltipBtn.querySelector('.btn-text');
      btnText.style.display = 'inline';
      const btnIcon = tooltipBtn.querySelector('.btn-icon.icon-check-mark');
      btnIcon.style.display = 'none';

  tooltipBtn.addEventListener('click', () => {
      const action = new Action();
      // const link = window.location.href + '&page=' + (MainData.currentPage-2);
      const link = window.location.href + '&page=' + (MainData.currentPage-1);
      action.copyToClipboard(link);
      tooltipBtn.style.pointerEvents = 'none';

      btnText.style.display = 'none';
      btnIcon.style.display = 'inline';

      setTimeout(() => {
          btnText.style.display = 'inline';
          btnIcon.style.display = 'none';
          tooltipBtn.style.pointerEvents = 'auto';

      }, 5000);
  });
}
Navbar.prototype.showCardShareTooltip = function(e) {
  const shareToggle = document.querySelector(".no-fullscreen-nav .share-toggle");
  const cardShareTooltip = shareToggle.querySelector('.card-share-tooltip');
  if (!shareToggle.classList.contains('active') && (e.target.classList.contains('share-toggle') || e.target.classList.contains('icon-share'))) {
    e.stopPropagation();
    shareToggle.classList.add('active');
    cardShareTooltip.style.display = 'block';
    const titleText = cardShareTooltip.querySelector('.title-text');
    const pageNumber = document.createElement('span');
    // pageNumber.innerText = MainData.currentPage - 2;
    pageNumber.innerText = MainData.currentPage - 1;
    titleText.appendChild(pageNumber);

    const shareLink = cardShareTooltip.querySelector('.share-link');
    const linkValue = shareLink.querySelector('.link-value');
    // const link = window.location.href + '&page=' + (MainData.currentPage-2);
    const link = window.location.href + '&page=' + (MainData.currentPage-1);
    linkValue.innerText = link.substring(0, 22) + '...';
  }
}
Navbar.prototype.hideCardShareTooltip = function() {
  const shareToggle = document.querySelector('.no-fullscreen-nav .share-toggle');
  const cardShareTooltip = shareToggle.querySelector('.card-share-tooltip');
  cardShareTooltip.style.display = 'none';
  shareToggle.classList.remove('active');

  const titleText = cardShareTooltip.querySelector('.title-text');
  const pageNumber = titleText.querySelector('span');
  if (pageNumber) titleText.removeChild(pageNumber);
}
