import { MainData } from './mainData.js';
export class Common {
	constructor(data) {
		this.data = data;
	}
}

Common.prototype.activity = function (item) {
	// console.log(item)
}

// Common.prototype.load = function (json) {
// 	let that = this;
// 	//alert(MainData.bookId);

// 	$.get(MainData.environment.pdfApi + 'load?pdf_id=' + MainData.bookId, function (data) {
// 		console.log(data)
// 		let keys = Object.keys(data);
// 		let len = keys.length;

// 		for (let i = 0; i < len; i++) {
// 			let key = keys[i];
// 			let items = data[key];

// 			for (let j = 0; j < items.length; j++) {
// 				that[key](items[j]);
// 			}
// 		}
// 	})
// }

Common.prototype.likeActivity = function (activityCatalogNumber = null) {
	return new Promise((resolve, reject) => {
		const url = MainData.environment.coreApi + `api/Activity/ActivityLike `;
		const activity = MainData.activities.find(act => act.catalogNumber == activityCatalogNumber);
		const isLike = !activity.isLike;
		let request = {};
		request.type = 'POST';
		request.data = {};
		request.data = JSON.parse(JSON.stringify({
			ActivityId: activity.activityId,
			ActivityTypeId: activity.activityType.activityTypeId,
			IsLike: isLike
		}));
		request.url = url;
		request.beforeSend = function (xhr) {
			xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
		};
		request.success = (res) => {
			if (res.responseObject == 1) {
				const activities = MainData.activities.filter(act => act.catalogNumber == activityCatalogNumber);
				activities.forEach(activity => {
					activity.isLike = isLike;
				});
				resolve(isLike);
			}
		}
		request.processData = true;
		$.ajax(request);
	})


}
