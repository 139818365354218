import { Common } from './common.js';
import { Activity } from './activity.js';
import { InfoLayer } from './info-layer.js';
import { Link } from './sidebar-link.js';
import { MainData } from './mainData.js';
import { Utils } from "@/js/utils";
import { ActivitiesTypes } from "@/js/models";
import { BehaviorSubject, filter, merge } from "rxjs";

export class Sidebar {
    constructor() {
        this.activeActivitiesStyle = 'block';
        this.selectedActivityTypesForFilter = [];

        const sidebar = document.querySelector('.sidebar');

        const closeBtn = document.querySelector('.content-wrapper-block .icon-x');
        closeBtn.addEventListener('click', this.close.bind(this));


        const closeLinkBtn = document.querySelector('.link-wrapper-block .icon-x');
        closeLinkBtn.addEventListener('click', this.close.bind(this));

        const closeBtnActivities = document.querySelector('.activities-wrapper-block .icon-x');
        closeBtnActivities.addEventListener('click', this.close.bind(this))

        const closeBtnActivitiesFrame = document.querySelector('.activity-frame-wrapper .icon-x');
        closeBtnActivitiesFrame.addEventListener('click', this.close.bind(this));

        sidebar.addEventListener('click', (e) => { this.onSidebarClick(e) });
        this.element = sidebar;

        this.isIframeFullscreen = false;

        this.common = new Common();
        this.activeActivity;

        this.likeBtn = document.querySelector('.activity-frame-wrapper .like-wrapper');
        this.likeBtn.addEventListener('click', () => {
            this.likeActivity();
        })


        document.body.addEventListener('fullscreenchange', this.checkIframeFullscreen.bind(this), false);

        this.initShareTooltip();

        this.isOpenShareBlock = false;

        const shareLinkBlock = document.querySelector('.activity-actions .card-share-tooltip .share-link');
        const shareLinkBtn = document.querySelector('.activity-actions .card-share-tooltip .tooltip-btn');

        const shareLinkToggle = document.querySelector('.share-wrapper .icon-share');

        shareLinkToggle.addEventListener('click', () => {
            if (this.isOpenShareBlock) {
                this.hideShareTooltip();
            } else {
                this.showShareTooltip();
            }
        })

        shareLinkBlock.addEventListener('click', () => {
            this.copyText(this.getActiveActivityShareUrl());

            const text = document.querySelector('.activity-actions .card-share-tooltip .btn-text');
            const checkMark = document.querySelector('.activity-actions .card-share-tooltip .btn-icon');

            text.style.display = 'none';
            checkMark.style.display = 'inline';

            setTimeout(() => {
                text.style.display = 'inline';
                checkMark.style.display = 'none';
            }, 5000);

        })
        shareLinkBtn.addEventListener('click', () => {
            this.copyText(this.getActiveActivityShareUrl());
            const text = document.querySelector('.activity-actions .card-share-tooltip .btn-text');
            const checkMark = document.querySelector('.activity-actions .card-share-tooltip .btn-icon');

            text.style.display = 'none';
            checkMark.style.display = 'inline';

            setTimeout(() => {
                text.style.display = 'inline';
                checkMark.style.display = 'none';
            }, 5000);
        })

        this.exercises$.subscribe((res) => {
            Sidebar.prototype.updateSelectedItems('annExercises', res);
        });
        this.activities$.subscribe((res) => {
            Sidebar.prototype.updateSelectedItems('activities', res);
        });
        merge(this.exercises$, this.activities$).subscribe(() => {
            Sidebar.prototype.toggleMissionFooter();
        })
        Sidebar.prototype.selectedPages$.subscribe((data) => {
            const obj = Sidebar.prototype.getLocalSelectedPages();
            if (data.length){
                obj[MainData.bookId] = data;
            } else {
                delete obj[MainData.bookId];
            }
            localStorage.setItem('selectedPages', JSON.stringify(obj));
        })
        Sidebar.prototype.bookmarks$.subscribe((data) => {
            MainData.bookmarks = data.sort((a, b) => a.pageNumber - b.pageNumber);
            Sidebar.prototype.updateBookMarks(data);
            Sidebar.prototype.checkBookMarkBtnNav(data);
        })
        Sidebar.prototype.activeBookMark$.pipe(filter((res) => res)).subscribe((activeBookMarkPage) => {
            Sidebar.prototype.checkBookMarkBtnArrows(activeBookMarkPage - 1);
        });
        if (MainData.userType !== 'teacher') {
            const footer = $("#footer-create-mission");
            footer.removeClass('active');
        }

        $('.btns-bookmark .icon-nav-arrow-right').on('click', function (){
            const openedItem = $('.exercise-item.highlighted');
            const bookmark = openedItem.find('.bookmark-added');
            const bookmarkId = bookmark.data('bookmark-id');
            const index = MainData.bookmarks.findIndex(el => el.id === bookmarkId);
            if (index >= 0) {
                const prevIndex = index - 1 < 0 ? 0 : index - 1;
                Sidebar.prototype.selectBookmark(MainData.bookmarks[prevIndex].pageId);
            } else {
                const nextBookmark = MainData.bookmarks.find(el => el.pageNumber <= MainData.currentPage)
                if (nextBookmark) Sidebar.prototype.selectBookmark(nextBookmark.pageId);
            }
        })
        $('.btns-bookmark .icon-nav-arrow-left').on('click', function (){
            const openedItem = $('.exercise-item.highlighted');
            const bookmark = openedItem.find('.bookmark-added');
            const bookmarkId = bookmark.data('bookmark-id');
            const index = MainData.bookmarks.findIndex(el => el.id === bookmarkId);
            if (index >= 0) {
                const nextIndex = index + 1 <= MainData.bookmarks.length - 1 ? index + 1 : MainData.bookmarks.length - 1;
                Sidebar.prototype.selectBookmark(MainData.bookmarks[nextIndex].pageId);
            } else {
                const nextBookmark = MainData.bookmarks.find(el => el.pageNumber >= MainData.currentPage);
                if (nextBookmark) Sidebar.prototype.selectBookmark(nextBookmark.pageId);
            }
        })
        $('.btns-bookmark .bookmark-btn').on('click', function (){
            const openedItem = $('.wrap-content-item.active');
            const isOpenedItemWithBookmark = openedItem.find('.bookmark-added').first();
            if (openedItem.length && isOpenedItemWithBookmark.length) {
                const page = isOpenedItemWithBookmark.closest('.exercise-item');
                if (page) {
                    page.trigger("click");
                }
            } else {
                //const nextBookmark = MainData.bookmarks.find(el => el.pageNumber >= MainData.currentPage);
                // nextBookmark?.pageId ||
                Sidebar.prototype.selectBookmark(MainData.bookmarks[0].pageId);
            }
        })
    }
}

Sidebar.prototype.selectBookmark = function(pageId){
    const firstBookMarkItem = $(`.exercise-item[id=${pageId}]`);
    if (firstBookMarkItem && firstBookMarkItem.toArray().length) {
        const wrap = firstBookMarkItem.closest('.wrap-content-item');
        const page = wrap.find('.content-item');
        if (!wrap.hasClass('active')) {
            page.trigger("click");
        }
        firstBookMarkItem.trigger("click");
        $('.content-wrapper-block .content-body').animate({
            scrollTop: wrap.offset().top - wrap.parent().offset().top - wrap.parent().scrollTop()
        }, 500);
    }
};
Sidebar.prototype.getLocalSelectedPagesByBook = function () {
    const obj = Sidebar.prototype.getLocalSelectedPages();
    return obj[MainData.bookId] || [];
}
Sidebar.prototype.removeSelectedPagesByBook = function () {
    const obj = Sidebar.prototype.getLocalSelectedPages();
    if (obj[MainData.bookId]) {
        delete obj[MainData.bookId];
    }
}
Sidebar.prototype.getLocalSelectedPages = function () {
    return localStorage.getItem('selectedPages') ? JSON.parse(localStorage.getItem('selectedPages')) : {};
}
Sidebar.prototype.element = null;
Sidebar.prototype.bookContent = [];
Sidebar.prototype.activities$ = new BehaviorSubject([]);
Sidebar.prototype.exercises$ = new BehaviorSubject([]);
Sidebar.prototype.bookmarks$ = new BehaviorSubject([]);
Sidebar.prototype.activeBookMark$ = new BehaviorSubject(null);
Sidebar.prototype.selectedPages$ = new BehaviorSubject(Sidebar.prototype.getLocalSelectedPages());

Sidebar.prototype.likeActivity = function() {
    this.common.likeActivity(this.activeActivity.catalogNumber)
        .then((isLike) => {
            const infoLayer = new InfoLayer(this.activeActivity);
            infoLayer.setLikeActiveClassInBook(isLike);
            infoLayer.setLikeActiveClassInActivityRow();
            this.setLikeActiveClassInSidebar(isLike);
        });
}

Sidebar.prototype.setLikeActiveClassInSidebar = function (isLike) {
    if(this.activeActivity) {
        this.likeBtn.classList.toggle('active');
    }
}

Sidebar.prototype.getActiveActivityShareUrl = function () {
    //todo
    return `${MainData.environment.wizdi}activity/${this.activeActivity.activityId}/${this.activeActivity.activityType.activityTypeId}`
}


Sidebar.prototype.copyText = function (text) {
    const box = document.createElement('textarea');
    box.style.position = 'fixed';
    box.style.opacity = '0';
    box.value = text;
    document.body.appendChild(box);
    box.focus();
    box.select();
    document.execCommand('copy');
    document.body.removeChild(box);
}

Sidebar.prototype.initShareTooltip = function () {
    const shareTooltip = document.querySelector('.activity-actions .card-share-tooltip');
    const iconX = document.querySelector('.activity-actions .card-share-tooltip .icon-x');
    iconX.addEventListener('click', () => {
        this.hideShareTooltip();
    });
}

Sidebar.prototype.showShareTooltip = function () {
    const shareTooltip = document.querySelector('.activity-actions .card-share-tooltip');
    shareTooltip.style.display = 'block';

    const linkValue = document.querySelector('.activity-actions .card-share-tooltip .link-value');
    let link = this.getActiveActivityShareUrl();
    linkValue.innerText = link.substring(0, 25);

    const titleText = document.querySelector('.activity-actions .card-share-tooltip .title-text');
    const pTitle = document.createElement('p');
    pTitle.innerText = this.activeActivity.name;
    titleText.appendChild(pTitle);
    this.isOpenShareBlock = true;
}

Sidebar.prototype.hideShareTooltip = function () {
    const shareTooltip = document.querySelector('.activity-actions .card-share-tooltip');
    shareTooltip.style.display = 'none';

    this.isOpenShareBlock = false;
}

Sidebar.prototype.deleteActiveClassInPageIcons = function () {
    const innerWrappers = document.getElementsByClassName('inner-wrapper');
    [...innerWrappers].forEach(item => {
        if (item.classList.contains('active') && !item.classList.contains('activity-audio')) {
            item.classList.remove('active');
        }
    });
    const wrappersAdditional = document.getElementsByClassName('visible-part');
    [...wrappersAdditional].forEach(item => {
        if (item.classList.contains('active')) {
            item.classList.remove('active');
        }
    })
}

Sidebar.prototype.clickOnActivityType = function (event) {
    let activityTypeId;
    const classes = $(event.target).closest('.activity-type-wrapper').attr('class').split(/\s+/);
    console.log(classes);
    if (classes.length && classes[0] === 'activity-type-wrapper') {
        const parrent = $(event.target).closest('.activity-type-wrapper');
        activityTypeId = parrent.attr('data-activity-type-id');
        parrent.toggleClass('active');
    }
    if (!activityTypeId) return;
    if (this.selectedActivityTypesForFilter.includes(+activityTypeId)) {
        this.selectedActivityTypesForFilter = this.selectedActivityTypesForFilter.filter(id => id != activityTypeId);
    } else {
        this.selectedActivityTypesForFilter.push(+activityTypeId);
    }
}

Sidebar.prototype.onSidebarClick = function (event) {
    let activeTab;

    const containerWrapper = document.querySelector('.sidebar-block');
    containerWrapper.style.width = '50%';

    if (event.target && event.target.parentNode && event.target.parentNode.dataset && event.target.parentNode.dataset.tab) {
        activeTab = event.target.parentNode.dataset.tab;
    }

    if (activeTab && activeTab !== 'plans') {
        this.close();
        this.disableAllBtns();
        this.openSidebarBlock();
        switch (activeTab) {
            case 'search': {
                this.openSearch();
                break;
            }
            case 'content': {
                this.openContent();
                break;
            }
            case 'layers': {
                this.openActivities()
                break;
            }
            case 'links': {
                this.openLinks()
                break;
            }
        }
    }
}

Sidebar.prototype.disableAllBtns = function () {
    for (let child of this.element.children) {
        child.classList.remove('active');
    }

    const sidebarBlock = document.querySelector('.sidebar-block');
    const containerWrapper = document.querySelector('.conteiner-wrapper');
    containerWrapper.style.width = '50%';

    const gutter = document.querySelector('.gutter');

    for (let child of sidebarBlock.children) {
        child.style.display = 'none';
    }
    sidebarBlock.style.display = 'none';
    gutter.style.display = 'none';
};

Sidebar.prototype.openSidebarBlock = function () {
    const gutter = document.querySelector('.gutter');
    const sidebarBlock = document.querySelector('.sidebar-block');
    const conteinerWrapper = document.querySelector('.conteiner-wrapper');
    sidebarBlock.style.display = 'flex';
    gutter.style.display = 'block';

    conteinerWrapper.style.maxWidth = 'calc(100% - 400px)';
    conteinerWrapper.style.minWidth = 'calc(100% - 850px)';
    MainData.zoomFactor = 1;
    MainData.translateX = 0;
    if (MainData.header) {
        MainData.header.setBodyZoom();
    }
};

Sidebar.prototype.openSearch = function () {
    // const sidebarSearchBtn = document.querySelector('.sidebar-item.sidebar-search');
    // sidebarSearchBtn.classList.add('active');
    const searchBlock = document.querySelector('.search-wrapper-block');
    searchBlock.style.display = 'block';
}

Sidebar.prototype.openContent = function () {
    this.getContent();
    const sidebarContentBtn = document.querySelector('.sidebar-item.sidebar-content');
    sidebarContentBtn.classList.add('active');
    const contentBlock = document.querySelector('.content-wrapper-block');
    contentBlock.style.display = 'block';
}

Sidebar.prototype.openActivities = function () {
    this.showActivities();
    const sidebarContentBtn = document.querySelector('.sidebar-item.sidebar-layers');
    sidebarContentBtn.classList.add('active');
    const contentBlock = document.querySelector('.activities-wrapper-block');
    contentBlock.style.display = 'block';
    this.scrollActivitiesLinksBody();
}


Sidebar.prototype.openLinks = function () {
    this.showLinks();
    const sidebarContentBtn = document.querySelector('.sidebar-item.sidebar-adds');
    sidebarContentBtn.classList.add('active');
    const contentBlock = document.querySelector('.link-wrapper-block');
    contentBlock.style.display = 'block';
    const linkList = document.querySelector('.link-list');
    linkList.style.display = 'block';
}

Sidebar.prototype.showLinks = function () {

    const linkBody = document.querySelector('.link-wrapper-block .link-body');
    linkBody.innerHTML = ``;
    let activities = MainData.linksAndComments;

    activities = this.calcActivities(activities, true);
    console.log(activities);
    if (activities && activities.length) {
        for (let i = 0; i < activities.length; i++) {
            const subj = activities[i];

            const subjectWrapper = document.createElement('div');
            subjectWrapper.classList.add('subject-wrapper');

            const subjectTitle = document.createElement('div');
            subjectTitle.classList.add('subject-title');
            subjectTitle.innerHTML = `<span> ${subj.prefixName} : </span> ${subj.name} `

            subjectWrapper.appendChild(subjectTitle);
            linkBody.appendChild(subjectWrapper);

            subj.pages = subj.pages;
            for (let j = 0; j < subj.pages.length; j++) {
                const pageObj = subj.pages[j];

                const pageWrapper = document.createElement('div');
                pageWrapper.classList.add('page-wrapper');
                pageWrapper.setAttribute("data-page-number", pageObj.number);

                const title = document.createElement('div');
                title.classList.add('title');
                title.innerHTML = `${MainData.langData['P.']} <span dir="ltr">${pageObj.number}</span>`;

                pageWrapper.appendChild(title);

                const activitiesWrapper = document.createElement('div');
                activitiesWrapper.classList.add('activities-wrapper');
                for (let k = 0; k < pageObj.activities.length; k++) {
                    const activityObj = pageObj.activities[k];
                    const newAct = new Link(activityObj);
                    let el;
                    el = newAct.createElement();
                    activitiesWrapper.appendChild(el);
                }
                pageWrapper.appendChild(activitiesWrapper);

                linkBody.appendChild(pageWrapper);
            }

        }
    }
}

Sidebar.prototype.scrollToPage = function (page) {
    const wrap = $('.exercise-item[data-page-number="' + page + '"]');
    if (wrap && wrap.length) {
        const content = $('.content-wrapper-block .content-body');
        content.animate({
            scrollTop: wrap.position().top
        }, 500);
    }
}
Sidebar.prototype.initEvents = function () {
    const content = $('#content-body');
    content.on('click', '.content-item',  function (event){
        $(this).closest('.wrap-content-item').toggleClass('active');
        const pageNumber = $(this).closest('.wrap-content-item').data('page-start-number');
        MainData.navbarObj.changePage(pageNumber + 1);
        $('.exercise-item.highlighted').removeClass('highlighted');
    })
    content.on('click', '.exercise-item',  function (event){
        const pageNumber = $(this).data('page-number');
        MainData.navbarObj.changePage(pageNumber + 1);
        $('.exercise-item.highlighted').removeClass('highlighted');
        $(this).addClass('highlighted');
        Sidebar.prototype.activeBookMark$.next(pageNumber + 1);
        Sidebar.prototype.scrollToPage(pageNumber);
    })
    content.on('click', '.icon-play',  function (event){
        const pageNumber = $(this).closest('.exercise-item').data('page-number');
        MainData.navbarObj.changePage(pageNumber + 1);
    })
    content.on('click', '.icon-play',  function (event){
        console.log(event);
        event.stopPropagation();
        const catalogNumber = getCatalogNumber(this);
        MainData.sidebarObj.onPlayActivity(catalogNumber, true);
    });
    const getCatalogNumber = (el) => {
        return $(el).closest('.item').data('catalog-number');
    }
    content.on('mouseenter', '.activities-item .ui-activity-icon',  function (event){
        const catalogNumber = getCatalogNumber(this);
        $(`[data-activity-page-catalog-number='${catalogNumber}'`).addClass('active');
    });
    content.on('mouseleave', '.activities-item .ui-activity-icon',  function (event){
        const catalogNumber = getCatalogNumber(this);
        $(`[data-activity-page-catalog-number='${catalogNumber}'`).removeClass('active');
    });

    content.on('mouseenter', '.ann-activities .ui-custom-checkbox',  function (event){
        const id = $(this).data('ann-exercise-annId');
        $(`.page [data-ann-exercise-annId='${id}']`).addClass('active');
    });
    content.on('mouseleave', '.ann-activities .ui-custom-checkbox',  function (event){
        const id = $(this).data('ann-exercise-annId');
        $(`.page [data-ann-exercise-annId='${id}']`).removeClass('active');
    });
    // all
    content.on('change', '.range-checkbox',  function (){
        const checkboxes = $(this).closest('.wrap-content-item').find(".list-exercises .whole-page-checkbox");
        checkboxes.prop("checked", $(this).prop('checked')).change();
    });
    // whole page
    content.on('change', '.whole-page-checkbox',  function (){
        const checkboxes = $(this).closest('.exercise-item').find(".wrap-activities input[type='checkbox']");
        checkboxes.prop("checked", $(this).prop('checked')).change();
    });
    // each checkbox
    content.on('change', ".wrap-activities input[type='checkbox']",  function (){
        const isChecked = $(this).prop('checked');
        const annId = $(this).data('ann-exercise-id');
        const activityId = $(this).data('activity-id');
        const activityTypeId = $(this).data('activity-type-id');

        if (Sidebar.prototype.checkIfNotAllowedToAdd(+activityId, +activityTypeId, +annId)){
            $(this).prop('checked', false);
            Sidebar.prototype.showModalText(MainData.langData['Tests cannot be combined with other activities in a task']);
        } else {
            if (annId) {
                Sidebar.prototype.toggleExercise(annId, isChecked);
            } else if (activityId) {
                Sidebar.prototype.toggleActivity(activityId, isChecked, activityTypeId);
            }

            const page = $(this).closest('[data-page-number]').data('page-number');
            Sidebar.prototype.toggleSelectedPage(page, isChecked);
        }

        Sidebar.prototype.checkWholePage(this);
    });
    $('#footer-create-mission').on('click', "[data-action]", function(e){
        const action = $(this).data('action');
        console.log( action );
        if (action === 'cancel'){
            Sidebar.prototype.resetSelectedItems();
        } else if (action == 'send') {
            Sidebar.prototype.redirectWithData();
            // window.open(url, "blank")
        }
    });

    $('#modal-text').on('click', ".icon-x, .btn", function(){
        const modal = $(this).closest('#modal-text');
        modal.find('.modal-wrapper').removeClass('active');
        setTimeout(() => {
            modal.removeClass('active');
        }, 300)
    });
}
Sidebar.prototype.redirectWithData = function () {
    let url = MainData.environment.wizdi + `teacher/create-mission?bookId=${MainData.bookId}`;
    const activities = Sidebar.prototype.activities$.getValue();
    const exercises = Sidebar.prototype.exercises$.getValue();
    if (activities.length){
        const act = activities.map(el => {
            return `${el.objectMetadataId}-${el.activityTypeId}`;
        });
        url += `&activities=${act.join(',')}`;
    }
    if (exercises.length){
        url += `&exercises=${exercises.join(',')}`;
    }
    window.location.href = url;
}
Sidebar.prototype.showModalText = function (text, isCancelButton = false) {
    const modal = $('#modal-text');
    const content = modal.find('.modal-content-text');
    content.text( text );
    if (isCancelButton) {
        modal.find('.btn-cancel').addClass('active');
    } else {
        modal.find('.btn-cancel').removeClass('active');
    }

    modal.addClass('active');
    setTimeout(() => {
        modal.find('.modal-wrapper').addClass('active');
    }, 200)
}
Sidebar.prototype.checkIfNotAllowedToAdd = function (objectMetadataId, activityTypeId, annExId) {
    const isExercise = Boolean(annExId);
    const isTest = activityTypeId === 8;
    const selectedActivities = Sidebar.prototype.getLocalItems('activities');
    const selectedExercises = Sidebar.prototype.getLocalItems('annExercises');

    const isActivityAdded = selectedActivities.find((el) => el.objectMetadataId === objectMetadataId);
    const isExerciseAdded = selectedExercises.find((id) => id === annExId);
    const isAlreadyAdded = isActivityAdded || isExerciseAdded;

    if (isAlreadyAdded) return false;

    const isAllSelectedTest = selectedActivities.length ? selectedActivities.every((el) => {
        return el.activityTypeId == 8
    }) : false;
    const notActivities = selectedActivities.every((el) => {
        return el.activityTypeId !== 8;
    })

    const isAllSelectedDefault =
      (selectedActivities.length ?  notActivities : false) || selectedExercises.length;
    // console.log( isExercise ? isAllSelectedTest : (isAllSelectedTest && !isTest) || (isAllSelectedDefault && isTest) );
    return isExercise ? isAllSelectedTest : (isAllSelectedTest && !isTest) || (isAllSelectedDefault && isTest);
}
Sidebar.prototype.resetSelectedItems = function () {
    const checkboxes = $("#content-body input[type='checkbox']");
    checkboxes.prop("checked", false);
    Sidebar.prototype.activities$.next([]);
    Sidebar.prototype.exercises$.next([]);
    localStorage.removeItem('taskSelectedObjects');
    Sidebar.prototype.removeSelectedPagesByBook();
}
Sidebar.prototype.checkWholePage = function (el) {
    const checkboxes = $(el).closest('.wrap-activities').find("input[type='checkbox']");
    const wholePage = $(el).closest('.exercise-item').find(".wrap-page input[type='checkbox']");
    const isAll = checkboxes.toArray().every(item => $(item).prop('checked'));
    wholePage.prop('checked', isAll);
    Sidebar.prototype.checkWholeRanges(el);
}
Sidebar.prototype.checkWholeRanges = function (el) {
    const wholeRange = $(el).closest('.wrap-content-item').find(".checkbox-pages input[type='checkbox']");
    const checkboxes = $(el).closest('.list-exercises').find(".wrap-page input[type='checkbox']");
    const isAll = checkboxes.toArray().every(item => $(item).prop('checked'));
    wholeRange.prop('checked', isAll);
}
Sidebar.prototype.updateSelectedItems = function (field, res) {
        const bookId = localStorage.getItem('bookID') ? JSON.parse(localStorage.getItem('bookID'))?.bookID : '';
        let objects = this.getLocalTaskSelectedObjects();
        const savedBook = objects.find((el) => el.bookId == MainData.bookId);
        if (MainData.bookId) {
            if (savedBook) {
                savedBook[field] = res;
                if (!savedBook.activities.length && !savedBook.annExercises.length && !res.length) {
                    objects = objects.filter((el) => el.bookId !== MainData.bookId);
                }
            } else {
                const el = {
                    bookId: MainData.bookId || bookId,
                    activities: field == 'activities' ? res : this.getLocalItems(field),
                    annExercises: field == 'annExercises' ? res : this.getLocalItems(field),
                };
                if (el.bookId && (el.activities.length || el.annExercises.length)) {
                    objects.push(el);
                }
            }
        } else {
            if (res.hasOwnProperty('checked') && res.checked) {
                const el = {
                    bookId: MainData.bookId || bookId,
                    activities: field == 'activities' ? [res] : this.getLocalItems(field),
                    annExercises: field == 'annExercises' ? res : this.getLocalItems(field),
                };
                if (el.bookId && (el.activities.length || el.annExercises.length)) {
                    objects.push(el);
                } else if (el.activities.length || el.annExercises.length) {
                    objects.push(el);
                }
            } else if (res.hasOwnProperty('checked') && !res.checked) {
                objects = objects.filter((el) => !el.activities.find((item) => item.objectMetadataId == res.objectMetadataId));
            }
        }
        // console.log(objects);
        localStorage.setItem('taskSelectedObjects', JSON.stringify(objects));

}
Sidebar.prototype.ifSelectedActivity = function () {
    return Sidebar.prototype.activities$.getValue().length || Sidebar.prototype.exercises$.getValue().length;
}
Sidebar.prototype.toggleMissionFooter = function () {
    const footer = $("#footer-create-mission");
    if ( Sidebar.prototype.ifSelectedActivity() ) {
        footer.addClass('active');
    } else {
        footer.removeClass('active');
    }
    footer.find('.objects-amount').text(Sidebar.prototype.activities$.getValue().length + Sidebar.prototype.exercises$.getValue().length);
}
Sidebar.prototype.getLocalTaskSelectedObjects = function () {
    return localStorage.getItem('taskSelectedObjects') ? JSON.parse(localStorage.getItem('taskSelectedObjects')) : [];
}
Sidebar.prototype.getLocalItems = function (field) {
    const objects = Sidebar.prototype.getLocalTaskSelectedObjects();
    if (objects) {
        const savedBook = objects.find((el) => MainData.bookId === el.bookId);
        return savedBook ? savedBook[field] : [];
    }
    return [];
}
Sidebar.prototype.toggleActivity = function (id, state, typeId) {
    const arr = this.getLocalItems('activities');
    const isExist = arr.find((el) => el.objectMetadataId == id);
    let newArr = new Set(arr);
    // state ? newArr.add(id) : newArr.delete(id);

    if (state && !isExist) {
        newArr.add({ objectMetadataId: id, activityTypeId: typeId });
    } else if(!state) {
        newArr = [...newArr].filter((el) => el.objectMetadataId !== id);
    }
    $(`input[data-activity-id="${id}"]`).toArray().forEach((el) => {
        $(el).prop('checked', state);
    });
    this.activities$.next([...newArr]);
}
Sidebar.prototype.toggleExercise = function (id, state) {
    const newArr = new Set(this.getLocalItems('annExercises'));
    state ? newArr.add(id) : newArr.delete(id);
    this.exercises$.next([...newArr]);
}
// Sidebar.prototype.toggleOpenedItem = function (id, state) {
//     const arr = Sidebar.prototype.getLocalOpenedItemsByBook();
//     const newArr = new Set(arr);
//     state ? newArr.add(id) : newArr.delete(id);
//     this.openedContentItems$.next([...newArr]);
// }
Sidebar.prototype.toggleSelectedPage = function (id, state) {
    const arr = Sidebar.prototype.getLocalSelectedPagesByBook();
    const newArr = new Set(arr);
    state ? newArr.add(id) : newArr.delete(id);
    this.selectedPages$.next([...newArr]);
}
Sidebar.prototype.getBookData = function (selectedBook) {
    if (!selectedBook) return (Sidebar.prototype.bookContent = []);
    Sidebar.prototype.bookContent = [];

    if (selectedBook) {
        for (let chapter of selectedBook.bookChapters ? selectedBook.bookChapters : []) {
            chapter.isChapter = true;
            Sidebar.prototype.bookContent.push(chapter);
            if (chapter.subjects && chapter.subjects.length) {
                for (let subject of chapter.subjects) {
                    let level = 1;
                    subject.level = level;
                    subject.chapterId = chapter.id;
                    Sidebar.prototype.bookContent.push(subject);
                    this.setChildrenSubjectToBookContent(subject, chapter.id, level);
                }
            }
        }
        Sidebar.prototype.bookContent.sort((a, b) => {
            return a.startPage - b.startPage;
        });
        // this.renderBookContent.emit(Sidebar.prototype.bookContent);
        // if (selectedBook.bookmark && selectedBook.bookmark.bookmarkId && selectedBook.bookmark.bookmarkPageNumber) {
        //     this.toSetBookmark.emit(selectedBook.bookmark.bookmarkPageNumber);
        // }
    }
}
Sidebar.prototype.getCustomCheckbox = function (id, name) {
    const inputCheckbox = document.createElement('input');
    inputCheckbox.setAttribute('type', 'checkbox');
    inputCheckbox.setAttribute('id', id);
    inputCheckbox.classList.add('input-checkbox');
    inputCheckbox.setAttribute('name', name);
    return inputCheckbox;
}
Sidebar.prototype.setChildrenSubjectToBookContent = function (subject, chapterId, level) {
    ++level;
    if (subject.children && subject.children.length > 0) {
        subject.children.forEach((child) => {
            child.level = level;
            child.chapterId = chapterId;
            Sidebar.prototype.bookContent.push(child);
            Sidebar.prototype.setChildrenSubjectToBookContent(child, chapterId, level);
        });
    }
}
Sidebar.prototype.createCheckboxPages = function (chapter) {
    const checkboxPages = document.createElement('div');
    checkboxPages.classList.add('checkbox-pages');
    if (chapter.pages) {
        const notEmptyPage = chapter.pages.some(item => {
            return item.activities.length || item.annExercises.length;
        })
        if (MainData.userType == 'teacher' && notEmptyPage) {
            const labelCheckbox = document.createElement('label');
            labelCheckbox.classList.add('ui-custom-checkbox');
            const inputCheckbox = Sidebar.prototype.getCustomCheckbox('selectedPages', 'selectedPages');
            inputCheckbox.classList.add('range-checkbox');

            labelCheckbox.appendChild(inputCheckbox);
            checkboxPages.appendChild(labelCheckbox);
        }
    }

    return checkboxPages;
}
Sidebar.prototype.createHTMLContentItem = function (chapter) {
    // Create elements
    const contentItem = document.createElement('div');
    contentItem.classList.add('content-item');

    const wrapItem = document.createElement('div');
    wrapItem.classList.add('wrap-item');

    const rowIcon = document.createElement('div');
    rowIcon.classList.add('row-icon');

    const iconArrowLeft = document.createElement('i');
    iconArrowLeft.classList.add('icon-arrow-left');

    rowIcon.appendChild(iconArrowLeft);

    const itemTitle = document.createElement('div');
    itemTitle.classList.add('item-title', 'chapter');
    const spanTitle = document.createElement('span');
    const name = chapter.name ? chapter.name : '';
    spanTitle.textContent = (chapter.prefixName ? chapter.prefixName + ' - ' : '') + name;
    itemTitle.appendChild(spanTitle);
    if (name) {
        const customTooltip = document.createElement('div');
        customTooltip.classList.add('custom-tooltip');
        customTooltip.classList.add('custom-tooltip-bottom');
        customTooltip.innerText = name;
        spanTitle.appendChild(customTooltip);
    }

    const itemDots = document.createElement('div');
    itemDots.classList.add('item-dots');

    wrapItem.appendChild(rowIcon);
    wrapItem.appendChild(itemTitle);
    wrapItem.appendChild(itemDots);

    const wrapDetails = document.createElement('div');
    wrapDetails.classList.add('wrap-details');

    const itemPages = document.createElement('div');
    itemPages.classList.add('item-pages');
    const spanPages = document.createElement('span');
    spanPages.textContent = MainData.langData['Pages'];
    const spanPageRange = document.createElement('span');
    spanPageRange.textContent = chapter.endPage + '-' + chapter.startPage;

    itemPages.appendChild(spanPages);
    itemPages.appendChild(spanPageRange);
    wrapDetails.appendChild(itemPages);

    contentItem.appendChild(wrapItem);
    contentItem.appendChild(wrapDetails);
    return contentItem;
}
Sidebar.prototype.createAnnActivities = function(data){
    // Create elements
    const activities = document.createElement('div');
    activities.classList.add('activities');
    activities.classList.add('ann-activities');

    const rowItem = document.createElement('div');
    rowItem.classList.add('row-item');

    const title = document.createElement('div');
    title.classList.add('title');
    title.textContent = MainData.langData['exercises'];

    const items = document.createElement('div');
    items.classList.add('items');

    for(let i = 0; i< data.length; i++) {
        const exercise = data[i];
        const labelCheckbox = document.createElement('label');
        labelCheckbox.classList.add('ui-custom-checkbox');

        if (MainData.userType == 'teacher') {
            const inputCheckbox = Sidebar.prototype.getCustomCheckbox(exercise.annId, 'checked');
            inputCheckbox.setAttribute('data-ann-exercise-id', exercise.id);
            inputCheckbox.setAttribute('data-ann-exercise-annId', exercise.annId);
            labelCheckbox.appendChild(inputCheckbox);
        }

        const spanLabel = document.createElement('span');
        spanLabel.classList.add('label');
        spanLabel.textContent = exercise.number;

        labelCheckbox.appendChild(spanLabel);
        items.appendChild(labelCheckbox);
    }
    if (data.length) {
        rowItem.appendChild(title);
    }
    rowItem.appendChild(items);
    activities.appendChild(rowItem);
    return activities;
};

Sidebar.prototype.getActivityTypeById = function(id){
    return ActivitiesTypes.find(el => el.id === id);
}
Sidebar.prototype.createActivityItem = function(activity){
    // Create elements
    const catalogNumber = MainData.activities.find((el) => el.activityId == activity.id)?.catalogNumber;
    const item = document.createElement('div');
    item.classList.add('item');
    if (catalogNumber) {
        item.setAttribute('data-catalog-number', catalogNumber);
    }

    if (MainData.userType == 'teacher') {
        const labelCheckbox = document.createElement('label');
        labelCheckbox.classList.add('ui-custom-checkbox');
        const inputCheckbox = Sidebar.prototype.getCustomCheckbox(activity.objectMetadataId, 'checked');
        inputCheckbox.setAttribute('data-activity-id', activity.objectMetadataId);
        inputCheckbox.setAttribute('data-activity-type-id', activity.activityTypeId);

        labelCheckbox.appendChild(inputCheckbox);
        item.appendChild(labelCheckbox);
    }
    const activityType = Sidebar.prototype.getActivityTypeById(activity.activityTypeId);
    const activityTypeName = activityType.name;

    const activitiesItem = document.createElement('div');
    activitiesItem.classList.add('activities-item');

    const uiActivityIcon = document.createElement('div');
    uiActivityIcon.classList.add('ui-activity-icon', `ui-activity-icon-${activityTypeName.toLowerCase()}`);

    const iconSpan = document.createElement('span');
    iconSpan.classList.add(`icon-${activityTypeName.toLowerCase()}`);

    uiActivityIcon.appendChild(iconSpan);

    const activitiesCard = document.createElement('div');
    activitiesCard.classList.add('activities-card');

    const cardBody = document.createElement('div');
    cardBody.classList.add('card-body');

    const cardTitle = document.createElement('p');
    cardTitle.classList.add('card-title');
    cardTitle.textContent = activity.name;

    const cardButton = document.createElement('div');
    cardButton.classList.add('card-button');
    const activityTypeWrapper = document.createElement('div');
    activityTypeWrapper.classList.add('activity-type-wrapper', 'active', `activity-type-wrapper-${activityTypeName.toLowerCase()}`);

    const activityTypeIcon = document.createElement('span');
    activityTypeIcon.classList.add('activity-icon', `icon-${activityTypeName.toLowerCase()}`);
    const activityTypeIconName = document.createElement('span');
    activityTypeIconName.textContent = MainData.langData[activityTypeName.toLowerCase()] || activityTypeName;

    activityTypeWrapper.appendChild(activityTypeIcon);
    activityTypeWrapper.appendChild(activityTypeIconName);

    const activityBtnPlay = document.createElement('div');
    activityBtnPlay.classList.add('activity-btn-play');

    const iconPlay = document.createElement('i');
    iconPlay.classList.add('icon-play');

    activityBtnPlay.appendChild(iconPlay);
    cardButton.appendChild(activityTypeWrapper);
    cardButton.appendChild(activityBtnPlay);
    cardBody.appendChild(cardTitle);
    cardBody.appendChild(cardButton);

    const cardImg = document.createElement('div');
    cardImg.classList.add('card-img');

    const img = document.createElement('img');
    img.classList.add('card-icon');
    img.setAttribute('src', `${MainData.environment.coreApi + activity.thumbnailUri}`)

    cardImg.appendChild(img);
    activitiesCard.appendChild(cardBody);
    activitiesCard.appendChild(cardImg);

    activitiesItem.appendChild(uiActivityIcon);
    activitiesItem.appendChild(activitiesCard);
    item.appendChild(activitiesItem);

    return item;
}

Sidebar.prototype.createActivities = function(data){
    // Create elements
    const activities = document.createElement('div');
    activities.classList.add('activities');
    const rowItem = document.createElement('div');
    rowItem.classList.add('row-item');

    const items = document.createElement('div');
    items.classList.add('items');

    for(let i = 0; i < data.length; i++) {
        const activityItem = Sidebar.prototype.createActivityItem(data[i]);
        items.appendChild(activityItem);

        rowItem.appendChild(items);
        activities.appendChild(rowItem);
    }

    return activities
}
Sidebar.prototype.createListExercises = function (chapter) {
    // Create elements
    const listExercises = document.createElement('div');
    listExercises.classList.add('list-exercises');
    if (chapter.pages) {
        for (let i = 0; i < chapter.pages.length; i++) {
            const item = chapter.pages[i];
            const exerciseItem = document.createElement('div');
            exerciseItem.classList.add('exercise-item');
            exerciseItem.setAttribute('id', item.id);
            exerciseItem.setAttribute('data-page-number', item.number);

            const page = document.createElement('div');
            page.classList.add('page');

            const wrapPage = document.createElement('div');
            wrapPage.classList.add('wrap-page');


            if (MainData.userType == 'teacher') {
                const labelCheckbox = document.createElement('label');
                labelCheckbox.classList.add('ui-custom-checkbox');
                if (item.activities.length || item.annExercises.length) {
                    const inputCheckbox = Sidebar.prototype.getCustomCheckbox('allExercisesId', 'checked');
                    inputCheckbox.classList.add('whole-page-checkbox');

                    labelCheckbox.appendChild(inputCheckbox);
                }
                wrapPage.appendChild(labelCheckbox);
            }

            const pageSelect = document.createElement('span');
            pageSelect.classList.add('page-select');
            pageSelect.textContent = `${MainData.langData['Page']} ${item.number}`;

            // const pageImg = document.createElement('span');
            // pageImg.classList.add('page-img');
            //
            // const img = document.createElement('img');
            // img.setAttribute('src', `${MainData.environment.coreApi + item.backgroundUrl}`);
            //
            // pageImg.appendChild(img);
            // pageSelect.appendChild(pageImg);

            wrapPage.appendChild(pageSelect);
            page.appendChild(wrapPage);

            const wrapActivities = document.createElement('div');
            wrapActivities.classList.add('wrap-activities');

            const annActivities = Sidebar.prototype.createAnnActivities(item.annExercises);
            const activities = Sidebar.prototype.createActivities(item.activities);

            wrapActivities.appendChild(annActivities);
            wrapActivities.appendChild(activities);

            const wrapBookmark = document.createElement('div');
            wrapBookmark.classList.add('wrap-bookmark');

            const iconBookmark = document.createElement('i');
            iconBookmark.classList.add('icon4-multiple-bookmark');

            wrapBookmark.appendChild(iconBookmark);

            exerciseItem.appendChild(page);
            // exerciseItem.appendChild(wrapPage);
            exerciseItem.appendChild(wrapActivities);
            exerciseItem.appendChild(wrapBookmark);
            listExercises.appendChild(exerciseItem);
        }
    }
    return listExercises;
}
Sidebar.prototype.createWrapContentItem = function (chapter) {
    // console.log(chapter);
    const wrapContentItem = document.createElement('div');
    wrapContentItem.classList.add('wrap-content-item');
    if(MainData.userType !== 'teacher') {
        wrapContentItem.classList.add('no-checkboxes');
    }
    wrapContentItem.setAttribute('data-page-start-number', chapter.startPage);
    wrapContentItem.setAttribute('data-page-end-number', chapter.endPage);
    wrapContentItem.setAttribute('data-chapter-id', chapter.id);

    const checkboxPages = Sidebar.prototype.createCheckboxPages(chapter);
    const contentItem = Sidebar.prototype.createHTMLContentItem(chapter);
    const listExercises = Sidebar.prototype.createListExercises(chapter);

    wrapContentItem.appendChild(checkboxPages);
    wrapContentItem.appendChild(contentItem);
    wrapContentItem.appendChild(listExercises);

    return wrapContentItem;
}
Sidebar.prototype.geHTMLForOneBookRange = function (chapter) {
    return Sidebar.prototype.createWrapContentItem(chapter);
}
Sidebar.prototype.renderHTMLBookContent = function (content) {
    const bookContent = content.filter((el) => !el.isChapter);
    const contentBody = document.querySelector('#content-body');

    console.log(bookContent);

    for (let i = 0; i < bookContent.length; i++) {
        const oneRange = Sidebar.prototype.geHTMLForOneBookRange(bookContent[i]);
        contentBody.appendChild(oneRange);
    }
}
Sidebar.prototype.createBookContent = function (book) {
    console.log('createBookContent');
    Sidebar.prototype.getBookData(book);
    Sidebar.prototype.renderHTMLBookContent(Sidebar.prototype.bookContent);
    Sidebar.prototype.initEvents();
    Sidebar.prototype.setSelectedItems()
}
Sidebar.prototype.updateBookMarks = function (data) {
    MainData.bookmarks.map((el) => {
        const exerciseItem = $(`.exercise-item[id=${el.pageId}]`);
        if (exerciseItem) {
            const wrapBookmark = exerciseItem.find('.wrap-bookmark');
            if (!wrapBookmark.hasClass('bookmark-added')) {
                wrapBookmark.addClass('bookmark-added');
                wrapBookmark.data('bookmark-id', el.id);
                $(`.displayPair [data-page-id=${el.pageId}]`).find('.ui-bookmark').addClass('active');
            }
        }
    })
}
Sidebar.prototype.checkBookMarkBtnNav = function (data) {
    console.log('checkBookMarkBtnNav');
    if (data && data.length) {
        $('.btns-bookmark').removeClass('disabled')
    } else {
        $('.btns-bookmark').addClass('disabled')
    }
}
Sidebar.prototype.checkBookMarkBtnArrows = function (activeBookMarkPage) {
    if (activeBookMarkPage !== undefined && MainData.bookmarks.length) {
        const list = $(`.btns-bookmark`);
        const right = list.find('.icon-nav-arrow-right');
        const left = list.find('.icon-nav-arrow-left');
        if (MainData.bookmarks[0].pageNumber >= activeBookMarkPage) {
            right.addClass('disabled');
        } else {
            right.removeClass('disabled');
        }
        if (MainData.bookmarks[MainData.bookmarks.length - 1].pageNumber <= activeBookMarkPage) {
            left.addClass('disabled');
        } else {
            left.removeClass('disabled');
        }
    }
}

Sidebar.prototype.setSelectedItems = function () {
    const activities = this.getLocalItems('activities');
    const exercises = this.getLocalItems('annExercises');
    activities.forEach((el) => {
        $(`input[data-activity-id=${el.objectMetadataId}]`).prop('checked', true).change();
    });
    exercises.forEach((id) => {
        $(`input[data-ann-exercise-id=${id}]`).prop('checked', true).change();
    });
    Sidebar.prototype.activities$.next(activities);
    Sidebar.prototype.exercises$.next(exercises);
    const arr = Sidebar.prototype.getLocalSelectedPagesByBook();
    if (arr.length && Sidebar.prototype.ifSelectedActivity()) {
        setTimeout(() => {
            const containerWrapper = document.querySelector('.sidebar-block');
            containerWrapper.style.width = '50%';
            const containerWrapper2 = document.querySelector('.conteiner-wrapper');
            containerWrapper2.style.width = '50%';
            this.openSidebarBlock();
            this.openContent();
            const wrap = $('.exercise-item[data-page-number="' + arr[arr.length - 1] + '"]');
            if (wrap && wrap.length) {
                wrap.closest('.wrap-content-item').find('.content-item').trigger('click');
                wrap.trigger('click');
            }
        }, 1000);
    }
}
Sidebar.prototype.getContent = function () {

    // this.showContent(MainData.bookChapters);

}

Sidebar.prototype.calcActivities = function (activities, isMarks = false) {
    const marks = [];
    MainData.marksData.map(el => marks.push(...el.marks));
    let res = [];
    const arr = [...activities];
    if (isMarks) arr.push(...marks);

    for (let activity of arr) {
        if (activity.pageId) {
            const pageNum = MainData.bookPages.find(page => page.pageId == activity.pageId)?.number;

            for (let chapter of MainData.bookChapters) {
                for (let subject of chapter.subjects) {
                    if (pageNum >= subject.startPage && pageNum <= subject.endPage) {
                        const resSubj = res.find(subj => subj.id == subject.id);
                        if (resSubj) {
                            const pageObj = resSubj.pages.find(page => page.number == pageNum);
                            if (pageObj) {
                                pageObj.activities.push(activity);
                                pageObj.activities.sort((a,b) => a.bookTaskName - b.bookTaskName)
                            } else {
                                let pageData = {
                                    number: pageNum,
                                    activities: [activity]
                                }
                                resSubj.pages.push(pageData);
                            }
                            resSubj.pages = resSubj.pages.sort((a,b) => {return a.number - b.number});
                        } else {
                            let data = subject;
                            data.pages = [];
                            let pageData = {
                                number: pageNum,
                                activities: [activity]
                            }
                            data.pages.push(pageData);
                            res.push(data);
                        }

                    }
                }
            }

            const activitySubject = MainData.bookChapters.flatMap(chapter => chapter.subjects).find(subject => pageNum >= subject.startPage && pageNum <= subject.endPage);
            if (!activitySubject) {
                const isObjectWOSubject = res.find(item => item.id == 'ללא נושא');
                if (isObjectWOSubject) {
                    let pageData = {
                        number: pageNum,
                        activities: [activity]
                    }
                    const existPage = isObjectWOSubject.pages.find((el) => el.number == pageNum);
                    if (existPage) {
                        existPage.activities.push(activity);
                    } else {
                        isObjectWOSubject.pages.push(pageData);
                    }
                    isObjectWOSubject.pages.sort((a,b) => {return a.number - b.number});
                } else {
                    let data = {
                        activityQuantity: 0,
                        children: [],
                        endPage: 0,
                        id: 'ללא נושא',
                        name: "",
                        parentId: null,
                        prefixName: "ללא נושא",
                        startPage: 0
                    };
                    data.pages = [];
                    let pageData = {
                        number: pageNum,
                        activities: [activity]
                    }
                    data.pages.push(pageData);
                    res.push(data);
                }


            }
        }
    }
    return res.sort((a,b) => {return a.startPage - b.startPage});
}


Sidebar.prototype.getActivitiUrlForFrame = function (activity) {
    let url = `${MainData.environment.media}${activity.folderUri}`;

    if(activity.activityType.activityTypeId === 5 || activity.activityType.activityTypeId === 6) {
        url += `/index.html`.replace(/\//gi, '/');
        return url;
    }

    if(activity.activityType.activityTypeId === 9) {
        return url + '/activity.mp4';
    }

    // url += `/story_html5.html?tincan=true&actor={"name": [""], "mbox": ["mailto:4@wizdi.com"]}&endpoint=https://www.wizdi.co.il/lrs/api/&activity_id=${activity.activityId}&sessionId=14149&userTaskID=-1 &grouping=https://www.wizdi.co.il/api/Storylines/${activity.activityId}/ &UserID=4 &saveData=false`.replace(/\//gi, '/');
    url += `/story_html5.html?tincan=true&actor={"name":[""],"mbox":["mailto:4@wizdi.com"]}&endpoint=${MainData.environment.lrs}api/&sessionId=14149&IsViewMode=true&isGetLastState=false&WizdiActivityId=${activity.activityId}&ActivityTypeId=${activity.activityType.activityTypeId}&content_endpoint=${MainData.environment.lrs}api/content`.replace(
        /\//gi,
        '/'
      );
    return url;

}

Sidebar.prototype.onPlayActivity = function (activityCatalogNumber, fromContent) {
    const activity = MainData.activities.find(act => act.catalogNumber == activityCatalogNumber);
    this.activeActivity = activity;

    let activitiesOnPage = document.querySelectorAll(`[data-activity-page-catalog-number='${activityCatalogNumber}'`);
    activitiesOnPage = [...activitiesOnPage];
    let playingActivityOnPage;
    for(let i = 0; i < activitiesOnPage.length; i++) {
        if (activitiesOnPage[i].closest(`[data-page-number='${MainData.currentPage}'`)) {
            playingActivityOnPage = activitiesOnPage[i];
            setTimeout( ()=> {playingActivityOnPage.classList.add('active')}, 500);
            break;
        }
    }

    if (activity.activityType.activityTypeId === 11) {
        this.onPlayInPlayer(activity);
    } else {
        this.onPlayInFrame(activity, playingActivityOnPage, activityCatalogNumber, fromContent);
    }
}

Sidebar.prototype.onPlayInPlayer = function (activity) {
    MainData.audioPlayer.setAudioInPlayer(activity);
}

Sidebar.prototype.getWorksheetById = function (id) {
    return new Promise((resolve, reject) => {
        const url = `${MainData.environment.coreApi}api/v2/LegacyWorksheetGetByWorksheetId?WorksheetId=${id}`;
        let request = {};
        request.type = "GET";
        request.url = url;
        request.beforeSend = function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
        };
        request.success = (res) => {
            resolve(res.pages || []);
        }
        request.processData = true;
        $.ajax(request);
    });
}
Sidebar.prototype.onPlayInFrame = function (activity, playingActivityOnPage, activityCatalogNumber,fromContent) {
    MainData.audioPlayer.closeAudioPlayer();
    const iframeURL = this.getActivitiUrlForFrame(activity);
    this.likeBtn.setAttribute("data-iframe-activity-catalog-number", activityCatalogNumber);
    if (activity.isLike) {
        this.likeBtn.classList.add('active');
    } else {
        this.likeBtn.classList.remove('active');
    }

    const activitiesList = document.querySelector('.activities-list');
    activitiesList.style.display = 'none';

    const frameVideo = document.querySelector('.activity-frame-wrapper video');
    const frame = document.querySelector('.activity-frame-wrapper iframe');
    const worksheet = document.querySelector('.activity-frame-wrapper .wrap-worksheet');
    const pageViewer = worksheet.querySelector('.page-viewer');
    const contentWrapperBlock = document.querySelector('.content-wrapper-block');
    const activitiesWrapper = document.querySelector('.activities-wrapper-block');
    pageViewer.innerHTML = '';
    if (fromContent) {
        activitiesWrapper.style.display = 'block';
        contentWrapperBlock.style.display = 'none';
    }

    if (activity.activityType.activityTypeId === 9) {
        frameVideo.style.display = 'block';
        frame.style.display = 'none';
        worksheet.style.display = 'none';
        frameVideo.pause();
        frameVideo.querySelector('source').setAttribute('src', iframeURL);
        frameVideo.load();
        frameVideo.play();
    } else if (activity.activityType.activityTypeId === 4) {
        frameVideo.style.display = 'none';
        frame.style.display = 'none';
        worksheet.style.display = 'block';
        this.getWorksheetById(activity.activityId).then((images) => {
            console.log(images);
            images.forEach((el) => {
                const img = document.createElement('img');
                img.src = `${MainData.environment.media}${el.backgroundUrl}`;
                img.setAttribute('onError', 'this.src="../imgs/Icon_Activities.svg"');
                pageViewer.appendChild(img);
            })
        });
    } else {
        frame.style.display = 'block';
        frameVideo.style.display = 'none';
        worksheet.style.display = 'none';
        setTimeout(() => {frame.setAttribute('src', iframeURL)}, 500);
    }

    const activityFrameWrapper = document.querySelector('.activity-frame-wrapper');
    activityFrameWrapper.style.display = 'block';

    const activityTitleWrapper = document.querySelector('.activity-title-wrapper');
    activityTitleWrapper.addEventListener('click', () => {
        activityFrameWrapper.style.display = 'none';
        if (fromContent) {
            contentWrapperBlock.style.display = 'block';
            activitiesWrapper.style.display = 'none';
        } else {
            activitiesList.style.display = 'block';
        }
        this.hideShareTooltip();
        frame.setAttribute('src', '');
        frameVideo.querySelector('source').setAttribute('src', '');
        frameVideo.pause();
        frameVideo.currentTime = 0;
        if (playingActivityOnPage) playingActivityOnPage.classList.remove('active');
    });
    const activityTitle = activityTitleWrapper.querySelector('.activity-title');
    activityTitle.innerHTML = activity.name;

    const activityTypeWrapper = document.querySelector('.activity-type-wrapper.activity-play.active');
    activityTypeWrapper.classList = "";
    activityTypeWrapper.classList.add('activity-type-wrapper', 'activity-play', 'active', `${activity.activityType.name.toLowerCase()}-wrapper`);
    activityTypeWrapper.setAttribute('data-activity-type-id', activity.activityType.activityTypeId)

    const activityIcon = activityTypeWrapper.querySelector('.activity-icon');
    activityIcon.classList.add(`icon-${activity.activityType.name.toLowerCase()}`);

    const activityLabel = activityTypeWrapper.querySelector('.activity-label');
    activityLabel.innerHTML = MainData.langData[this.ActivityTypeName[activity.activityType.name.toLowerCase()]];

    const fullScreenBtn = document.querySelector('.activity-frame-wrapper .fullscreen-wrapper');
    fullScreenBtn.addEventListener('click', () => {
        if (Utils.isNotDesktop()) {
            Sidebar.prototype.checkIframeFullscreen();
        } else {
            if (!this.isIframeFullscreen) {
                document.body.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        }
    });
}

Sidebar.prototype.checkIframeFullscreen = function () {
    if (this.isIframeFullscreen) {
        this.hideIframeFullscreen();
    } else {
        this.showIframeFullscreen();
    }
}

Sidebar.prototype.showIframeFullscreen = function () {
    const frame = document.querySelector('.activity-frame-wrapper .activity-frame');
    const fullscreenBtn = document.querySelector('.activity-frame-wrapper .fullscreen-wrapper');
    frame.classList.add('fullscreen');
    fullscreenBtn.classList.add('fullscreen');
    const header = document.querySelector('body > .header');
    header.style.display = 'none';
    this.isIframeFullscreen = true;
}

Sidebar.prototype.hideIframeFullscreen = function () {
    if (this.isIframeFullscreen) {
        const frame = document.querySelector('.activity-frame-wrapper .activity-frame');
        const fullscreenBtn = document.querySelector('.activity-frame-wrapper .fullscreen-wrapper');
        frame.classList.remove('fullscreen');
        fullscreenBtn.classList.remove('fullscreen');
        const header = document.querySelector('body > .header');
        header.style.display = 'flex';
        this.isIframeFullscreen = false;
    }
}

Sidebar.prototype.toggleFilterActivities = function () {
    const activitiesMenu = document.querySelector('.activities-filter-menu');
    const filterToggle = document.querySelector('.activities-filter-toggle');
    const testsWrapper = document.querySelector('.tests-wrapper');
    if (MainData.userType === 'student') {
        testsWrapper.style.display = 'none';
    }
    activitiesMenu.classList.toggle('show');
    if (activitiesMenu.classList.contains('show') && !filterToggle.classList.contains('active')) {
        filterToggle.classList.add('active');
    }
    if (!activitiesMenu.classList.contains('show') && this.selectedActivityTypesForFilter.length) {
        filterToggle.classList.add('active');
    }
    if (!activitiesMenu.classList.contains('show') && !this.selectedActivityTypesForFilter.length) {
        filterToggle.classList.remove('active');
    }

}

Sidebar.prototype.showActivities = function () {

    const activitiesBody = document.querySelector('.activities-wrapper-block .activities-body');

    const activitiesList = document.querySelector('.activities-list');
    activitiesList.style.display = 'block'
    activitiesBody.addEventListener('click', (event) => {
        let activityCatalogNumber;
        let pageNumber;

        const path = event.path || (event.composedPath && event.composedPath())
        for (let item of path) {
            if (item.dataset && item.dataset.pageNumber) {
                pageNumber = +item.dataset.pageNumber + 1;
                break;
            }
        }
        for (let item of path) {
            if (item.dataset && item.dataset.catalogNumber) {
                activityCatalogNumber = item.dataset.catalogNumber;
                break;
            }
        }
        if (!activityCatalogNumber) return;
        MainData.navbarObj.changePage(pageNumber);
        this.onPlayActivity(activityCatalogNumber);
    })

    activitiesBody.innerHTML = '';

    const blockStyleBtn = document.querySelector('.activity-style-block');
    const rowStyleBtn = document.querySelector('.activity-style-row');

    const filterMenu = document.querySelector('.type-filter-menu-content');
    const filterMenuFooter = document.querySelector('.type-filter-menu-footer');

    const filterToggle = document.querySelector('.activities-filter-toggle');

    if (!filterToggle.getAttribute('is-inited')) {
        filterToggle.addEventListener('click', this.toggleFilterActivities.bind(this), true);
        filterToggle.setAttribute('is-inited', true);
    }

    if (!filterMenuFooter.getAttribute('is-inited')) {
        filterMenuFooter.addEventListener('click', () => {
            this.selectedActivityTypesForFilter = [];
            for (let item of filterMenu.children) {
                item.classList.remove('active');
            }
            activitiesBody.innerHTML = '';
            buildActivities();
        })
        filterMenuFooter.setAttribute('is-inited', true);
    }



    if (!filterMenu.getAttribute('is-inited')) {
        $('.type-filter-menu-content .activity-type-wrapper').on('click', (event) => {
            this.clickOnActivityType(event);
            activitiesBody.innerHTML = '';
            // sm-loader
            buildActivities();
        });
        filterMenu.setAttribute('is-inited', true);
    }

    if (!blockStyleBtn.getAttribute('is-inited')) {
        blockStyleBtn.addEventListener('click', () => {
            activitiesBody.innerHTML = '';
            this.activeActivitiesStyle = 'block';
            buildActivities();
            checkActiveStyle();
        })
        blockStyleBtn.setAttribute('is-inited', true);
    }

    if (!rowStyleBtn.getAttribute('is-inited')) {
        rowStyleBtn.addEventListener('click', () => {
            activitiesBody.innerHTML = '';
            this.activeActivitiesStyle = 'row';
            buildActivities();
            checkActiveStyle();
        })
        rowStyleBtn.setAttribute('is-inited', true);
    }

    const buildActivities = () => {
        console.log('buildActivities started');
        //$('.activities-wrapper-block').addClass('sm-loader');
        setTimeout(() => {
            let activities = MainData.activities;
            const activitiesQuantity = document.querySelector('.activities-quantity');
            const a = new Set(activities
              .sort((a, b) => +a.activityId - +b.activityId)
              // .filter(item =>  item.activityType.activityTypeId != 11)
              .filter(item => item.audioTypeId !== 1)
              .map(item => item.activityId)
            );
            activitiesQuantity.innerText = a.size;

            if (this.selectedActivityTypesForFilter.length) {
                activities = activities.filter(act => this.selectedActivityTypesForFilter.includes(act.activityType.activityTypeId));
            }
            activities = this.calcActivities(activities);
            const filterText = document.querySelector('.activities-filter-toggle .filter-text');
            if (filterText) {
                if (this.selectedActivityTypesForFilter.length) {
                    filterText.classList.add('active');
                    filterText.innerText = `${this.selectedActivityTypesForFilter.length} ${MainData.langData['Filters selected']}`;
                } else {
                    filterText.classList.remove('active');
                    filterText.innerText = MainData.langData['Filter'];
                }
            }

            if (activities && activities.length) {
                for (let i = 0; i < activities.length; i++) {
                    const subj = activities[i];

                    const subjectWrapper = document.createElement('div');
                    subjectWrapper.classList.add('subject-wrapper');

                    const subjectTitle = document.createElement('div');
                    subjectTitle.classList.add('subject-title');
                    subjectTitle.innerHTML = `<span> ${subj.prefixName} : </span> ${subj.name} `

                    subjectWrapper.appendChild(subjectTitle);
                    activitiesBody.appendChild(subjectWrapper);

                    subj.pages = subj.pages.sort((a, b) => a.number - b.number);
                    // subj.pages = subj.pages.reverse();
                    for (let j = 0; j < subj.pages.length; j++) {
                        const pageObj = subj.pages[j];
                        const pageWrapper = document.createElement('div');
                        pageWrapper.classList.add('page-wrapper');
                        pageWrapper.setAttribute('data-page-number', pageObj.number)
                        const title = document.createElement('div');
                        title.classList.add('title');
                        title.innerText = `${MainData.langData['P.']} ${pageObj.number} `;

                        pageWrapper.appendChild(title);

                        const activitiesWrapper = document.createElement('div');
                        activitiesWrapper.classList.add('activities-wrapper');


                        for (let k = 0; k < pageObj.activities.length; k++) {
                            const activityObj = pageObj.activities[k];
                            if (activityObj.audioTypeId !== 1) {
                                const newAct = new Activity(activityObj);
                                let el;
                                if (this.activeActivitiesStyle == 'block') {
                                    el = newAct.createBlockElement();
                                } else {
                                    el = newAct.createRowElement();
                                }
                                activitiesWrapper.appendChild(el);
                            }
                        }
                        pageWrapper.appendChild(activitiesWrapper);
                        if (activitiesWrapper.querySelectorAll('div').length) {
                            activitiesBody.appendChild(pageWrapper);
                        }
                    }

                }
            }
            setTimeout(() => {
                $('.activities-wrapper-block').removeClass('sm-loader');
                console.log('buildActivities ended');
                setTimeout(() => {this.lazyLoad()}, 500);
            }, 1000)
        }, 1000);

    }

    const checkActiveStyle = () => {
        if (this.activeActivitiesStyle == 'block') {
            blockStyleBtn.classList.add('active');
            rowStyleBtn.classList.remove('active');
        } else {
            rowStyleBtn.classList.add('active');
            blockStyleBtn.classList.remove('active');
        }
    }

    checkActiveStyle();
    buildActivities();

}

Sidebar.prototype.lazyLoad = function () {
    const containerWrapper = document.querySelector(".activities-body");
    const loaderWrapper = document.querySelector('.loader-material-wrapper');
    const spinner = loaderWrapper.querySelector('.spinner').cloneNode(true);
    // const spinner2 = `<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>`;

    lazyload();

    function lazyload() {
        const lazyloadImages = document.querySelectorAll(".activity-lazy");
        lazyloadImages.forEach(function(item) {
            var position = item.getBoundingClientRect();
            if(position.top < window.innerHeight && position.bottom >= 0) {
                item.classList.remove('activity-lazy');
                item.closest('.card-img').appendChild(spinner);
                item.setAttribute("src", `${item.dataset.src}`);
                item.onload = function(){
                    const el = item.closest('.card-img').querySelector('.spinner');
                    if (el) el.remove();
                    item.removeAttribute('data-src');
                };
            }
        });
        if (lazyloadImages.length === 0) {
            containerWrapper.removeEventListener("scroll", lazyload);
        }
    }
    containerWrapper.addEventListener("scroll", lazyload);
}

Sidebar.prototype.showContent = function (arr) {
    const contentBody = document.querySelector('.content-wrapper-block .content-body');
    contentBody.innerHTML = '';

    contentBody.addEventListener('click', this.clickOnContentBody)
    for (let item of arr) {
        item.isChapter = true;
        const el = this.createContentItem(item);
        contentBody.appendChild(el);
        if (item.subjects && item.subjects.length) {
            for (let s of item.subjects) {
                const el = this.createContentItem(s);
                contentBody.appendChild(el);
            }
        }

    }
}

Sidebar.prototype.clickOnContentBody = function (e) {
    const contentBody = document.querySelector('.content-wrapper-block .content-body');
    // contentBody.innerHTML = '';

    contentBody.removeEventListener('click', this.clickOnContentBody)
    const path = e.path || (e.composedPath && e.composedPath())
    for (let item of path) {
        if (item.dataset.startPage) {
            const page = +item.dataset.startPage + 1;
            MainData.navbarObj.changePage(page);
            MainData.sidebarObj.recalculateContentItems();
            // MainData.sidebarObj.showContent(MainData.bookChapters);
            return;
        }
    }
}


//create subject item which needed to be inserted into subjects-list div
Sidebar.prototype.createSearchResultItem = function (item) {

    //subject

    const subjectItem = document.createElement('div');
    subjectItem.classList.add('subject-item');

    const subjectWrapper = document.createElement('div');
    subjectWrapper.classList.add('subject-wrapper');

    const subjectTitle = document.createElement('div');
    subjectTitle.classList.add('subject-title');

    subjectTitle.innerHTML = `<span> ${MainData.langData['Subject']} 1 : </span> ${MainData.langData['Ribs']}`

    subjectWrapper.appendChild(subjectTitle);
    subjectItem.appendChild(subjectWrapper);

    //page
    const pageWrapper = document.createElement('div');
    pageWrapper.classList.add('page-wrapper');

    const pageTitle = document.createElement('div');
    pageTitle.classList.add('title');
    pageTitle.innerText = `${MainData.langData['P.']} 15`;

    pageWrapper.appendChild(pageTitle);

    //in loop
    const textWrapper = document.createElement('div');
    textWrapper.classList.add('text-wrapper');
    pageWrapper.appendChild(textWrapper);


}

Sidebar.prototype.recalculateContentItems = function () {
    const contentBody = document.querySelector('.content-body');
    const contentItems = document.querySelectorAll('.content-body .content-item');
    for (let item of contentItems) {
        const innerIcon = item.querySelector('.inner-icon');
        const itemTitle = item.querySelector('.item-title');

        item.classList.remove('active');
        item.classList.remove('bookmarked');
        // itemTitle.classList.remove('chapter');
        if (innerIcon) {
            innerIcon.classList.remove('icon-book-location');
            innerIcon.classList.remove('icon-done-circle');
        }

    }

    for (let item of contentItems) {
        const innerIcon = item.querySelector('.inner-icon');
        const itemTitle = item.querySelector('.item-title');
        const startPage = +item.dataset.startPage;
        const endPage = +item.dataset.endPage;

        if (MainData.bookmark && innerIcon ) {
            if (startPage <= MainData.bookmark.pageNumber && !item.classList.contains('chapter')) {
                innerIcon.classList.add('icon-done-circle')
            }

            const chapter = MainData.bookChapters.find(item => item.startPage == startPage && item.endPage == endPage);

            if (startPage <= MainData.bookmark.pageNumber && item.classList.contains('chapter')
                &&  MainData.bookmark.pageNumber == MainData.currentPage ) {
                const canv = document.querySelector(`[data-chapter-id='${chapter.id}'`);
                if (canv) {
                    this.calcChapterPercent(chapter, canv);
                } else {
                    const progressCircle = this.initProgressCircle(chapter);
                    innerIcon.appendChild(progressCircle);
                }
            }
            if (startPage + 1 > MainData.bookmark.pageNumber && item.classList.contains('chapter')
                &&  MainData.bookmark.pageNumber == MainData.currentPage ||
                startPage + 1 == MainData.bookmark.pageNumber && item.classList.contains('chapter')
                &&  MainData.bookmark.pageNumber == MainData.currentPage) {

                const canv = document.querySelector(`[data-chapter-id='${chapter.id}'`);
                if (canv) {
                    const progressCircle = canv.closest('.progress-circle-wrapper');
                    progressCircle.remove();
                }
            }
        }
        let isRangeHasBookmark;

        if (Utils.isHardcodedBookmarks()) {
            isRangeHasBookmark = Utils.isRangeHasBookmark(startPage, endPage);
        } else if (MainData.bookmark) {
            isRangeHasBookmark = MainData.bookmark.pageNumber >= startPage && MainData.bookmark.pageNumber <= endPage;
        }
        if ((Utils.isHardcodedBookmarks() ? true : MainData.bookmark) && innerIcon) {
        // if (MainData.bookmark && innerIcon) {
            if (isRangeHasBookmark && !item.classList.contains('chapter')) {
                item.classList.add('bookmarked');
                innerIcon.classList = '';
                innerIcon.classList.add('inner-icon')
                innerIcon.classList.add('icon-book-location')
            }
            if (Utils.isPageAddedToBookmark(startPage + 1) && item.classList.contains('chapter')) {
                item.classList.add('bookmarked');
                innerIcon.classList = '';
                innerIcon.classList.add('inner-icon')
                innerIcon.classList.add('icon-book-location')
            }
        }

        if (MainData.currentPage >= startPage && MainData.currentPage <= endPage && !item.classList.contains('chapter')
            || item.classList.contains('chapter') && MainData.currentPage == startPage + 1) {
            item.classList.add('active')
        }

    }
}

Sidebar.prototype.initProgressCircle = function(chapter) {
    const progressCircleWrapper = document.createElement('div');
    progressCircleWrapper.classList.add('progress-circle-wrapper');

    const canvasWrapper = document.createElement('div');
    canvasWrapper.classList.add('canvas-wrapper');

    const canv = document.createElement('canvas');
    canv.classList.add('canvas');
    canv.id = 'canvas';
    canv.width = "320";
    canv.height = "320";
    canv.setAttribute('data-chapter-id', chapter.id);
    this.calcChapterPercent(chapter, canv);

    const iconWrapper = document.createElement('div');
    iconWrapper.classList.add('icon-wrapper');

    const iconCheckMark = document.createElement('span');
    iconCheckMark.classList.add('icon-check-mark');
    iconWrapper.appendChild(iconCheckMark);

    const customTooltip = document.createElement('div');
    customTooltip.classList.add('custom-tooltip');
    customTooltip.innerText = MainData.langData['Progress in the book'];

    canvasWrapper.appendChild(canv);
    canvasWrapper.appendChild(iconWrapper);

    progressCircleWrapper.appendChild(canvasWrapper);
    progressCircleWrapper.appendChild(customTooltip);
    return progressCircleWrapper;
}

Sidebar.prototype.calcChapterPercent = function(chapter, canv) {
    const subjectList = chapter.subjects;
    const subjectsAmount = subjectList.length;
    const bookmarkedSubject = subjectList.find(item => MainData.bookmark.pageNumber >= item.startPage && MainData.bookmark.pageNumber <= item.endPage);
    let doneSubjects;
    if (bookmarkedSubject) {
        const indexOfBookmarkedSubject = subjectList.indexOf(bookmarkedSubject);
        doneSubjects = indexOfBookmarkedSubject;
    } else {
        doneSubjects = subjectsAmount;
    }
    let percent = (doneSubjects * 100) / subjectsAmount;
    let ctx = canv.getContext('2d');
    drawCircle(ctx, percent);

    function drawCircle(ctx, percent) {
        //outer circle
        ctx.beginPath();
        ctx.strokeStyle = 'transparent'
        ctx.fillStyle = "#ddd";
        ctx.moveTo(160, 160);
        ctx.arc(160, 160, 160, 0, Math.PI * 2, true);
        ctx.fill();
        ctx.stroke();

        //inner arc
        ctx.beginPath();
        ctx.strokeStyle = '#7cc62b'
        for (let i = 158; i > 0; i--) {
          ctx.beginPath();
          ctx.strokeStyle = '#7cc62b';
          ctx.arc(160, 160, i, degToRad(-90), degToRad(getDegFromPercent(percent)), false);
          ctx.stroke();
        }
      }
    function getDegFromPercent(percent) {
        return percent * 360 / 100 - 90;
    }

    function degToRad(degrees) {
        return degrees * Math.PI / 180;
    };
}

Sidebar.prototype.createContentItem = function (item) {
    const contentItem = document.createElement('div');
    contentItem.setAttribute('data-start-page', item.startPage);
    contentItem.setAttribute('data-end-page', item.endPage)
    contentItem.classList.add('content-item');

    const contentPages = document.createElement('div');
    contentPages.classList.add('item-pages');
    contentPages.innerText = `${MainData.langData['P.']} ${item.startPage} - ${item.endPage}`;

    contentItem.appendChild(contentPages);

    const innerIcon = document.createElement('div');
    innerIcon.classList.add('inner-icon');

    if (MainData.bookmark) {
        if (item.startPage <= MainData.bookmark.pageNumber && !item.isChapter) {
            innerIcon.classList.add('icon-done-circle')
        }

        if (item.startPage <= MainData.bookmark.pageNumber && item.startPage + 1 != MainData.bookmark.pageNumber && item.isChapter) {
            const progressCircle = this.initProgressCircle(item);
            innerIcon.appendChild(progressCircle);
        }
    }

    let isRangeHasBookmark;

    if (Utils.isHardcodedBookmarks()) {
        isRangeHasBookmark = Utils.isRangeHasBookmark(item.startPage, item.endPage);
    } else if (MainData.bookmark) {
        isRangeHasBookmark = MainData.bookmark.pageNumber >= item.startPage && MainData.bookmark.pageNumber <= item.endPage
    }
    if ((Utils.isHardcodedBookmarks() ? true : MainData.bookmark) && innerIcon) {
        if (isRangeHasBookmark) {
       // if (MainData.bookmark.pageNumber >= item.startPage && MainData.bookmark.pageNumber <= item.endPage) {
            if (!item.isChapter) {
                innerIcon.classList.remove('icon-done-circle');
            }
            // if(item.isChapter) {
            //     console.log(item)
            //     console.log(MainData.bookmark.pageNumber, item.startPage)
            // }
            if (!item.isChapter || item.isChapter && Utils.isPageAddedToBookmark(item.startPage + 1)) {
                contentItem.classList.add('bookmarked');
                innerIcon.classList.add('icon-book-location');
            }
        }
    }

    const itemIcon = document.createElement('div');
    itemIcon.classList.add('item-icon');

    itemIcon.appendChild(innerIcon);

    if (+MainData.currentPage + 1 >= item.startPage && +MainData.currentPage + 1 <= item.endPage && !item.isChapter) {
        contentItem.classList.add('active')
    }

    const itemTitle = document.createElement('div');
    itemTitle.classList.add('item-title');
    let str = item.prefixName ? item.prefixName : '';
    itemTitle.innerText = str + ' ' + item.name;

    if (item.isChapter) {
        itemTitle.classList.add('chapter');
        contentItem.classList.add('chapter');
    }

    const itemDots = document.createElement('div');
    itemDots.classList.add('item-dots');

    contentItem.appendChild(itemIcon);
    contentItem.appendChild(itemTitle);
    contentItem.appendChild(itemDots);

    return contentItem;

}

Sidebar.prototype.close = function () {
    this.disableAllBtns();
    const el = document.querySelector('.sidebar-block');
    const activitiesList = document.querySelector('.activities-list');
    const activityFrameWrapper = document.querySelector('.activity-frame-wrapper');
    activitiesList.style.display = 'none';
    activityFrameWrapper.style.display = 'none';
    el.style.display = 'none';
    const containerWrapper = document.querySelector('.conteiner-wrapper');
    containerWrapper.style.width = '100%';
    containerWrapper.style.maxWidth = '100%';
    containerWrapper.style.minWidth = '100%';

    const frame = document.querySelector('.activity-frame-wrapper iframe');
    const frameVideo = document.querySelector('.activity-frame-wrapper video');
    frame.setAttribute('src', '');
    frameVideo.querySelector('source').setAttribute('src', '');
    frameVideo.pause();
    frameVideo.currentTime = 0;

    MainData.zoomFactor = 1;
    MainData.translateX = 0;
    if (MainData.header) {
        MainData.header.setBodyZoom();
    }
    this.hideShareTooltip();
    this.deleteActiveClassInPageIcons();
    this.closeLinkDetailsWrapper();
}

Sidebar.prototype.removeEvents = function () {
    document.body.removeEventListener('fullscreenchange', this.checkIframeFullscreen.bind(this), false);
}

Sidebar.prototype.scrollActivitiesLinksBody = function() {
    const activitiesBody = document.querySelector('.activities-body');
    const linksBody = document.querySelector('.link-body');
    const currentPageActivitiesBlock = activitiesBody.querySelector(`[data-page-number='${+MainData.currentPage-1}'`);
    const currentPageLinkBlock = linksBody.querySelector(`[data-page-number='${+MainData.currentPage-1}'`);
    if (currentPageActivitiesBlock) {
        activitiesBody.scrollTo({top: currentPageActivitiesBlock.offsetTop - 160, behavior: "smooth"});
    }
    if (currentPageLinkBlock) {
        linksBody.scrollTo({top: currentPageLinkBlock.offsetTop - 200, behavior: "smooth"});
    }
}

Sidebar.prototype.showLinkDetailsWrapper = function (data) {
    const linkList = document.querySelector('.link-list');
    linkList.style.display = 'none';

    const linkDetailsWrapper = document.querySelector('.link-details-wrapper');
    linkDetailsWrapper.style.display = 'block';

    const btnCloseInHeader = linkDetailsWrapper.querySelector('.icon-x');
    btnCloseInHeader.addEventListener('click', this.close.bind(this));

    const linkOnPage = document.querySelector(`[data-link-id-inbook='${data._id}'`)
      || document.querySelector(`[data-mark-group-id='${data.groupId}'`);
    const linkOnPageChild = linkOnPage.querySelector('.visible-part');

    setTimeout( ()=> {
        if (data.classColor) {
            linkOnPage.classList.add('highlighted')
        } else {
            linkOnPageChild.classList.add('active')
        }
    }, 500);

    const linkTitleWrapper = document.querySelector('.link-title-wrapper');
    linkTitleWrapper.addEventListener('click', () => {this.closeLinkDetailsWrapper(data)});

    const linkTitle = linkTitleWrapper.querySelector('.link-title');
    const text = data[data.classColor ? 'comment' : 'text'] || '';
    if (text && text.length > 30) {
        linkTitle.innerHTML = text.substring(0, 30) + '...';
    } else {
        linkTitle.innerHTML = text;
    }
}

Sidebar.prototype.closeLinkDetailsWrapper = function (data) {
    // console.log(data);
    const linkList = document.querySelector('.link-list');
    linkList.style.display = 'block';
    const linkDetailsWrapper = document.querySelector('.link-details-wrapper');
    linkDetailsWrapper.style.display = 'none';
    if (data) {
        if (data.classColor) {
            const linkOnPage = document.querySelector(`[data-mark-group-id='${data.groupId}'`);
            if (linkOnPage) {
                linkOnPage.classList.remove('highlighted');
            }
        } else {
            const linkOnPage = document.querySelector(`[data-link-id-inbook='${data._id}'`);
            if (linkOnPage) {
                const linkOnPageChild = linkOnPage.querySelector('.visible-part');
                linkOnPageChild.classList.remove('active');
            }
        }
    }
    const linkDetails = document.querySelector('.link-details');
    linkDetails.innerHTML = '';
    const linkActions = document.querySelector('.link-actions');
    linkActions.style.display = 'none';
    linkActions.innerHTML = '';
    const toggleWrapper = document.querySelector('.toggle-wrapper-editor');
    toggleWrapper.innerHTML = '';
    const selectWrapper = document.querySelector('.select-wrapper-editor');
    if (selectWrapper) {
        selectWrapper.remove();
        MainData.selectedClassListIds = [];
    }
    const toggleText = document.querySelector(".toggle-text-editor");
    toggleText.classList.remove("active");
}

Sidebar.prototype.ActivityTypeName = {
    games: 'Games',
    worksheets: 'Worksheets',
    illustrationmeans: 'Illustrationmeans',
    lessons: 'Lessons',
    storyline: 'Storyline',
    tests: 'Tests',
    video: 'Videos',
    link: 'Links',
    audio: 'Audios'
}
