import { MainData } from "./mainData";

export class Action {
	constructor() {}
}

Action.prototype.copyToClipboard = function (text) {
    const box = document.createElement('textarea');
    box.style.position = 'fixed';
    box.style.opacity = '0';
    box.value = text;
    document.body.appendChild(box);
    box.focus();
    box.select();
    document.execCommand('copy');
    document.body.removeChild(box);
}

Action.prototype.setHeightdisplayPairs = function() {
    const containerWrapper = document.querySelector('.conteiner-wrapper');
    const pagesInRow = document.querySelector('.pages-in-row');
    const sidebarBlock = document.querySelector('.sidebar-block');
    const displayPairs = document.querySelectorAll(".displayPair");
    if (MainData.originalPageSize) {
        const widthRelative = MainData.originalPageSize.width / MainData.originalPageSize.height;
        if (pagesInRow) {
            if(sidebarBlock.offsetWidth == 0) {
                displayPairs.forEach((item ,i)=> {
                    item.style.maxHeight = 'calc((100vw - 108px) / 1.56)';
                    item.style.height = 'calc(100vh - 72px)';
                    item.style.width = widthRelative * item.offsetHeight * 2 + 'px';
                });
            } else {
                displayPairs.forEach(item => {
                    item.style.height = 'calc(100vh - 72px)'
                    item.style.maxHeight = containerWrapper.offsetWidth/ 1.56 - 8 +'px';
                    item.style.width = widthRelative * item.offsetHeight * 2 + 'px';
                });
            }

            // $('.main-conteiner .displayPair:first-child > .page:first-child').css({
            //     opacity: 1,
            //     position: 'static'
            // })
        }
        if (!pagesInRow) {
            if(sidebarBlock.offsetWidth == 0) {
                displayPairs.forEach((item,i) => {
                    // else {
                        item.style.maxHeight = 'calc((100vw - 108px) / 0.39)';
                        item.style.height = 'calc(200vh - 145px)';
                        item.style.width = widthRelative * (item.offsetHeight/2) + 'px';
                    // }
                    /*if (i === 0) {
                        item.style.height = 'calc((200vh - 145px)/2)';
                    }*/
                });
            } else {
                displayPairs.forEach(item => {
                    item.style.height = 'calc(200vh - 145px)'
                    item.style.maxHeight = containerWrapper.offsetWidth/ 0.39 - 17 +'px';
                    item.style.width = widthRelative * (item.offsetHeight/2) + 'px';
                });
            }

            /*$('.main-conteiner .displayPair:first-child > .page:first-child').css({
                opacity: 0,
                position: 'absolute'
            })*/
        }
    }
}

Action.prototype.showUserToast = function(message) {
    const zoomToast = document.querySelector(".user-toast");
    zoomToast.innerText =
      MainData.langData[message];
    zoomToast.classList.add("active");
    setTimeout(() => {
      zoomToast.classList.remove("active");
    }, 3000);
}
