import { MainData } from './mainData.js';

export class ClassSelectDropdown {
  constructor() {}
}

ClassSelectDropdown.prototype.createMultipleSelect = function(isEdit) {
  const selectWrapper = document.createElement("div");
  selectWrapper.classList.add("select-wrapper");
  if (isEdit) {
    selectWrapper.classList.add("select-wrapper-editor");
  }

  const selectToggle = document.createElement("div");
  selectToggle.classList.add("select-toggle");

  const iconDropdownCaret = document.createElement("div");
  iconDropdownCaret.classList.add("icon-dropdown-caret");

  const selectText = document.createElement("div");
  selectText.classList.add("select-text");
  selectText.innerText = MainData.langData["Save privately"];

  selectToggle.appendChild(selectText);
  selectToggle.appendChild(iconDropdownCaret);

  const selectMenu = document.createElement("div");
  selectMenu.classList.add("select-menu");

  const optionText = MainData.langData["Everything"];
  const selectAllOption = this.createSelectOptionWrapper(optionText, false, true, isEdit);
  selectMenu.appendChild(selectAllOption);

  MainData.classList.forEach(classData => {
    const selectOption = this.createSelectOptionWrapper(classData.name, classData.classId, false, isEdit);
    selectMenu.appendChild(selectOption);
  });

  selectToggle.addEventListener("click", () => {
    selectMenu.classList.toggle("active");
    selectToggle.classList.toggle("active");
  });

  selectWrapper.appendChild(selectToggle);
  selectWrapper.appendChild(selectMenu);
  return selectWrapper;
};

ClassSelectDropdown.prototype.createSelectOptionWrapper = function(optionText, optionId, isSelectAll, isEdit = false) {
  const selectOptionWrapper = document.createElement("div");

  const inputCheckbox = document.createElement("input");
  const checkboxLabel = document.createElement("label");
  inputCheckbox.type = "checkbox";
  inputCheckbox.classList.add("input-checkbox");
  let inputCheckboxId;
  if (isSelectAll) {
    inputCheckboxId = "selectAll";
    checkboxLabel.classList.add("checkbox-label-select-all");
  } else {
    inputCheckboxId = optionId;
    checkboxLabel.classList.add("checkbox-label");
  }
  inputCheckbox.addEventListener("change", e => {
    if (e.target.getAttribute("id") == "selectAll") {
      MainData.selectedClassListIds = [];
      if (e.target.checked) {
        MainData.classList.forEach(item => {
          MainData.selectedClassListIds.push(item.classId);
        });
        this.checkAllClasses(e.target.checked);
      } else {
        this.checkAllClasses(e.target.checked);
        MainData.selectedClassListIds = [];
      }
    } else {
      if (e.target.checked) {
        const classDataFromList = MainData.classList.find(
          item => item.classId == e.target.getAttribute("id")
        );
        MainData.selectedClassListIds.push(classDataFromList.classId);
        ///!!! add checking for main checkbox
        this.checkAllClasses();
      } else {
        const classElId = e.target.getAttribute("id");
        const classIndexFromSelectedListIds = MainData.selectedClassListIds.indexOf(
          +classElId
        );
        MainData.selectedClassListIds.splice(classIndexFromSelectedListIds, 1);
        this.checkAllClasses();
      }
    }
  });
  // inputCheckbox.checked = forMarks ? false : !Boolean(isEdit);
  inputCheckbox.checked = false;
  inputCheckbox.id = inputCheckboxId;
  checkboxLabel.htmlFor = inputCheckboxId;

  const chackboxView = document.createElement("div");
  chackboxView.classList.add("icon-check-mark");
  checkboxLabel.appendChild(chackboxView);

  const textLabel = document.createElement("div");
  textLabel.classList.add("text-label");
  textLabel.innerText = optionText;
  checkboxLabel.appendChild(textLabel);

  selectOptionWrapper.appendChild(inputCheckbox);
  selectOptionWrapper.appendChild(checkboxLabel);

  return selectOptionWrapper;
}

ClassSelectDropdown.prototype.checkAllClasses = function(isCheckedAll) {
  const checkboxLabelSelectAll = document.querySelector(
    ".checkbox-label-select-all"
  );
  const iconCheckMark = checkboxLabelSelectAll.querySelector(
    ".icon-check-mark"
  );
  if (isCheckedAll !== undefined) {
    MainData.classList.forEach(classData => {
      const classCheckbox = document.getElementById(classData.classId);
      classCheckbox.checked = isCheckedAll;
    });
    iconCheckMark.classList.remove("partly");
  } else {
    if (MainData.classList.length !== MainData.selectedClassListIds.length
      && MainData.selectedClassListIds.length !== 0) {
        iconCheckMark.classList.add("partly");
        if (document.getElementById("selectAll").checked) {
          document.getElementById("selectAll").checked = false;
        }
    }
    if (
      MainData.classList.length === MainData.selectedClassListIds.length ||
      MainData.selectedClassListIds.length === 0
    ) {
      iconCheckMark.classList.remove("partly");
      document.getElementById("selectAll").checked = false;
    }
    if (MainData.classList.length === MainData.selectedClassListIds.length) {
      document.getElementById("selectAll").checked = true;
    }
  }
  this.setSelectText();
}

ClassSelectDropdown.prototype.setSelectText = function() {
  let selectText = document.querySelector('.select-text');
  selectText.innerText = '';
  if (MainData.selectedClassListIds && MainData.selectedClassListIds.length) {
    MainData.selectedClassListIds.forEach((item, i) => {
      const classData = MainData.classList.find(classInfo => classInfo.classId === item);
      if (classData) {
        // selectText.innerText ? selectText.innerText += `, ${classData.name}` : selectText.innerText += classData.name;


        const span = document.createElement('span');
        span.setAttribute("dir", "ltr");
        span.innerText = (i !== MainData.selectedClassListIds.length -1 ? "," : '') + classData.name;
        selectText.append(span);
      }
    })
    /*classNameList.forEach((el, i) => {
      const span = document.createElement('span');
      span.setAttribute("dir", "ltr");
      span.innerText = (i !== classNameList.length -1 ? "," : '') + el;
      hoveredPartClassList.append(span);
    });*/

  } else {
    selectText.innerText = MainData.langData["Private comment"];
  }
}

window.addEventListener('click', function(event){
  const overlay = document.querySelector('.select-wrapper');
  if (overlay && !overlay.contains(event.target)){
    const selectMenu = document.querySelector(".select-menu");
    const selectToggle = document.querySelector(".select-toggle");
    selectMenu.classList.remove("active");
    selectToggle.classList.remove("active");
  }
});
