import {Action} from './actions.js';
import {ClassSelectDropdown} from './class-select-dropdown.js';
import {MainData} from './mainData.js';
import {Utils} from "@/js/utils";

export class Draggable {
  constructor(data, isSaved) {
    // data ~= {id:string, type: 'link' | 'comment', data(url,title if link | comment if comment)}
    this.type = data.type;
    this.data = data;
    this.isSaved = isSaved;
    this.isDraggable = false;
    this.open = true;
  }
}

Draggable.prototype.element = null;
Draggable.prototype.textarea = null;
Draggable.prototype.inputLinkLabel = null;
Draggable.prototype.inputLinkUrl = null;

Draggable.prototype.createElement = function () {
  const type = this.type;
  const mainWrapper = document.createElement("div");
  mainWrapper.classList.add("wrapper-additional");
  mainWrapper.setAttribute("data-link-id-inbook", this.data._id);
  if (this.isSaved) {
    mainWrapper.style.top = `calc(${this.data.top}% + 19.5px)`;
    mainWrapper.style.left = `calc(${this.data.left}% + 19.5px)`;
  }

  const visiblePart = document.createElement("div");
  visiblePart.classList.add("visible-part");

  // visiblePart.addEventListener('click', this.openHiddenPart.bind(this))
  visiblePart.addEventListener("mouseenter", () => {
    this.onMouseIn();
  });
  visiblePart.addEventListener("mouseleave", () => {
    this.onMouseOut();
  });

  let flag;
  visiblePart.addEventListener("mousedown", () => {
    flag = 0;
  });
  visiblePart.addEventListener("mousemove", () => {
    flag = 1;
  });
  visiblePart.addEventListener("mouseup", () => {
    if (flag === 0) {
      this.openLinkCommentSidebar.call(this);
    }
  });

  const iconSpan = document.createElement("span");
  iconSpan.classList.add(`icon-${type}`);
  visiblePart.appendChild(iconSpan);

  const hiddenPart = this.createHiddenPart();

  mainWrapper.appendChild(visiblePart);
  mainWrapper.appendChild(hiddenPart);

  if (this.data._id) {
    const hoveredPart = this.createHoveredPart();
    mainWrapper.appendChild(hoveredPart);
  }

  this.element = mainWrapper;
  if (this.isSaved) this.closeObject();

  const ifUserCanEdit = this.data.userId && this.data.userId == MainData.userId || MainData.userType === 'editor';
  if (this.isSaved && !this.isDraggable && ifUserCanEdit) {
    this.addDragToEl();
  }

  return mainWrapper;
};

Draggable.prototype.createHiddenPart = function () {
  const hiddenPart = document.createElement("div");
  hiddenPart.classList.add("hidden-part");

  const hiddenPartHeader = document.createElement("div");
  hiddenPartHeader.classList.add("part-header");
  const iconClose = document.createElement("div");
  iconClose.classList.add("icon-x");

  iconClose.addEventListener("click", this.closeObject.bind(this));

  const titleWrapper = document.createElement("div");
  titleWrapper.classList.add("title-wrapper");

  const headerTitleIcon = document.createElement("div");
  headerTitleIcon.classList.add(`header-icon`, `icon-${this.type}`);

  const headerTitleText = document.createElement("div");
  headerTitleText.classList.add(`title`);
  headerTitleText.innerText =
    this.type == "link" ? MainData.langData["Link"] : MainData.langData["Comment"];

  titleWrapper.appendChild(headerTitleIcon);
  titleWrapper.appendChild(headerTitleText);

  hiddenPartHeader.appendChild(iconClose);

  hiddenPart.appendChild(hiddenPartHeader);

  //form

  const formWrapper = document.createElement("div");
  formWrapper.classList.add("form-wrapper");
  formWrapper.prepend(titleWrapper);

  const inputWrapper = document.createElement("div");

  if (this.type == "link") {
    const inputLinkLabel = document.createElement("input");
    inputLinkLabel.type = "text";
    inputLinkLabel.className = "input-underline";
    inputLinkLabel.placeholder = "תיאור";
    this.inputLinkLabel = inputLinkLabel;

    const linkInput = document.createElement("input");
    linkInput.type = "text";
    linkInput.className = "input-underline";

    if (this.isSaved) {
      linkInput.value = this.data.url || this.inputLinkUrl?.value;
    }
    linkInput.placeholder = "URL";
    this.inputLinkUrl = linkInput;
    inputWrapper.appendChild(inputLinkLabel);
    inputWrapper.appendChild(linkInput);
  }
  if (this.type === "comment") {
    const textarea = document.createElement("textarea");
    textarea.classList.add("textarea");
    if (this.isSaved) {
      textarea.value = this.data.text || this.textarea?.value;
    }
    textarea.placeholder = MainData.langData["Note"];
    inputWrapper.appendChild(textarea);
    this.textarea = textarea;
  }

  formWrapper.appendChild(inputWrapper);

  hiddenPart.appendChild(formWrapper);

  // class select

  if (MainData.userType === "teacher") {
    const classSelectWrapper = document.createElement("div");
    classSelectWrapper.classList.add("class-select-wrapper");

    // const toggleDiv = document.createElement("div");

    const toggleWrapper = document.createElement("div");
    toggleWrapper.classList.add("toggle-wrapper");

    // const toggleInput = document.createElement("input");
    // toggleInput.type = "checkbox";
    // toggleInput.id = "unchecked";
    // toggleInput.classList.add("toggle-input", "hidden");
    //
    // const toggleLabel = document.createElement("label");
    // toggleLabel.setAttribute("for", "unchecked");
    // toggleLabel.classList.add("toggle-label");
    //
    // toggleWrapper.appendChild(toggleInput);
    // toggleWrapper.appendChild(toggleLabel);
    //
    // const toggleText = document.createElement("div");
    // toggleText.classList.add("toggle-text");
    // toggleText.innerText = MainData.langData["Advertising for all classes"];
    //
    // toggleDiv.appendChild(toggleWrapper);
    // toggleDiv.appendChild(toggleText);


    const classSelectDropdown = new ClassSelectDropdown();
    const multipleSelect = classSelectDropdown.createMultipleSelect();
    classSelectWrapper.appendChild(multipleSelect);
    hiddenPartHeader.appendChild(classSelectWrapper);

  }
  // buttons

  const actions = document.createElement("div");
  const btnDelete = document.createElement("button");
  const btnSave = document.createElement("div");

  actions.classList.add("actions");

  // btnDelete.classList.add("btn", "btn-sm", "btn-red-pr", "btn-cancel");
  // btnDelete.innerText = MainData.langData["Cancel"];

  // btnSave.classList.add("btn", "btn-sm", "btn-blue-pr");
  // btnSave.innerText = MainData.langData["Publish"];

  const saveBtnIcon = document.createElement('i');
  saveBtnIcon.classList.add('icon2-publish');
  const saveBtnLabel = document.createElement('span');
  saveBtnLabel.innerHTML = MainData.langData['Publish'];
  btnSave.appendChild(saveBtnIcon);
  btnSave.appendChild(saveBtnLabel);

  btnDelete.addEventListener("click", () => {
    this.deleteObject();
  });
  btnSave.addEventListener("click", () => {
    this.createObject();
  });

  // actions.appendChild(btnDelete);
  actions.appendChild(btnSave);

  hiddenPart.appendChild(actions);

  return hiddenPart;
};
Draggable.prototype.createHoveredPart = function () {
  const hoveredPartWrapper = document.createElement("div");
  hoveredPartWrapper.classList.add("hovered-part-wrapper");
  this.setClassForHoveredPart(
    this.data.left,
    this.data.top,
    hoveredPartWrapper
  );
  const hoveredPart = document.createElement("div");
  hoveredPart.classList.add("hovered-part");
  hoveredPartWrapper.appendChild(hoveredPart);

  const hoveredPartTitle = document.createElement("div");
  hoveredPartTitle.classList.add("hovered-part-title");

  const hoveredPartUrl = document.createElement("div");
  hoveredPartUrl.className = "hovered-url";
  hoveredPartUrl.innerText = this.data.url && this.data.url.length > 120 ? this.data.url.replace(/[\r\n]/g, "").substring(0, 120) + "..." : this.data.url;
  if (this.data.type === "link") {
    hoveredPartTitle.innerText = MainData.langData["Link"];
  }
  if (this.data.type === "comment") {
    hoveredPartTitle.innerText = MainData.langData["Comment"];
  }
  const hoveredPartDesc = document.createElement("div");
  hoveredPartDesc.classList.add("hovered-part-desc");
  if (this.data.text && this.data.text.length > 120) {
    hoveredPartDesc.innerText =
      this.data.text.replace(/[\r\n]/g, "").substring(0, 120) + "...";
  }
  if (this.data.text && this.data.text.length <= 120) {
    hoveredPartDesc.innerText = this.data.text;
  }
  hoveredPart.appendChild(hoveredPartTitle);
  hoveredPart.appendChild(hoveredPartDesc);
  if (this.data.type === "link") {
    hoveredPart.appendChild(hoveredPartUrl);
  }
  if (this.data.classIdList && this.data.classIdList[0]) {
    let className;
    const classNameList = [];
    this.data.classIdList.forEach(item => {
      const classData = MainData.classList.find(classInfo => {
        return classInfo.classId == item;
      });
      if (classData) {
        className = classData.name;
        classNameList.push(className);
      }
    })
    const hoveredPartClassList = document.createElement('div');
    hoveredPartClassList.classList.add('hovered-part-classlist')
    classNameList.forEach((el, i) => {
      const span = document.createElement('span');
      span.setAttribute("dir", "ltr");
      span.innerText = (i !== classNameList.length -1 ? "," : '') + el;
      hoveredPartClassList.append(span);
    });
    hoveredPart.appendChild(hoveredPartClassList)
  }

  return hoveredPartWrapper;
};
Draggable.prototype.onMouseIn = function () {
  const itemInSidebar = document.querySelector(
    `[data-link-id-insidebar='${this.data._id}'`
  );
  if (!itemInSidebar) return;
  const itemEl = itemInSidebar.querySelector(".activity-row");
  itemEl.classList.add("active");
};

Draggable.prototype.onMouseOut = function () {
  const itemInSidebar = document.querySelector(
    `[data-link-id-insidebar='${this.data._id}'`
  );
  if (!itemInSidebar) return;
  const itemEl = itemInSidebar.querySelector(".activity-row");
  itemEl.classList.remove("active");
};
Draggable.prototype.setClassForHoveredPart = function (
  left,
  top,
  hoveredPartWrapper
) {
  if (!hoveredPartWrapper) {
    const wrapperAdditional = document.querySelector(
      `[data-link-id-inbook='${this.data._id}'`
    );
    hoveredPartWrapper = wrapperAdditional.querySelector(
      ".hovered-part-wrapper"
    );
  }
  hoveredPartWrapper.classList = "";
  hoveredPartWrapper.classList.add("hovered-part-wrapper");
  if (left < 20 && top >= 20) {
    hoveredPartWrapper.classList.add("left");
  }
  if (left < 20 && top < 20) {
    hoveredPartWrapper.classList.add("bottom-left");
  }
  if (left > 80 && top < 20) {
    hoveredPartWrapper.classList.add("bottom-right");
  }
  if (left <= 80 && left >= 20) {
    hoveredPartWrapper.classList.add("bottom-center");
  }
};

// Draggable.prototype.onClickIconLayer = function (event) {
//     const container = document.querySelector('.main-conteiner');
//     if (container.classList.contains('pages-in-row')) {
//         container.classList.toggle('pages-in-row');
//         MainData.isRow = container.classList.contains('pages-in-row');
//         let action = new Action();
//         action.setHeightdisplayPairs();
//         const parentPage = event.target.closest('.page');
//         MainData.currentPage = parseInt(parentPage.getAttribute('data-page-number'));
//         MainData.navbarObj.changePage(MainData.currentPage);
//     }
//     const catalogNumber = this.layer.catalogNumber;
//     MainData.sidebarObj.close();
//     MainData.sidebarObj.disableAllBtns();
//     MainData.sidebarObj.openSidebarBlock();
//     MainData.sidebarObj.openActivities();
//     MainData.sidebarObj.onPlayActivity(catalogNumber, MainData.currentPage);
// }

Draggable.prototype.openLinkCommentSidebar = function () {
  const container = document.querySelector(".main-conteiner");
  if (container.classList.contains("pages-in-row")) {
    container.classList.toggle("pages-in-row");
    MainData.isRow = container.classList.contains("pages-in-row");
    let action = new Action();
    action.setHeightdisplayPairs();
  }
  const parentPage = event.target.closest(".page");
  MainData.currentPage = parseInt(parentPage.getAttribute("data-page-number"));
  MainData.subActivePage$.next(MainData.currentPage);
  MainData.navbarObj.changePage(MainData.currentPage);

  MainData.sidebarObj.close();
  MainData.sidebarObj.disableAllBtns();
  MainData.sidebarObj.openSidebarBlock();
  MainData.sidebarObj.openLinks();
  const data = MainData.linksAndComments.find(item => this.data._id === item._id);
  this.showLinkComment(data);
};

function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
Draggable.prototype.showLinkComment = function (data) {
  const linkList = document.querySelector(".link-list");
  linkList.style.display = "block";

  if (data.type === "link") {
    // const _url = (data.url.indexOf('http://') !== -1 || data.url.indexOf('https://') !== -1) ? data.url : `http://${data.url}`;
    if (validateEmail(data.url)) {
      window.open(`mailto:${data.url}`, "_blank");
    } else {
      const url = !data.url.match(/^https?:\/\//i) ? 'http://' + data.url : data.url;
      window.open(url, "_blank");
    }
  }
  if (data.type === "comment") {
    MainData.sidebarObj.showLinkDetailsWrapper(data);
    const linkDetails = document.querySelector(".link-details");
    const commentDescription = document.createElement("textarea");
    commentDescription.classList.add("comment-text");
    commentDescription.setAttribute("rows", "14");
    commentDescription.setAttribute("disabled", true);
    commentDescription.value = data.text;
    linkDetails.appendChild(commentDescription);
  }
};

Draggable.prototype.openHiddenPart = function (e) {
  document.onmouseup = null;
  document.onmousemove = null;
  const hiddenPart = this.createHiddenPart();
  if (this.open) {
    this.element.appendChild(hiddenPart);
  }
  this.open = true;
};

Draggable.prototype.closeObject = function () {
  if (this.isSaved) {
    const el = this.element.querySelector(".hidden-part");
    // el.remove();
    el.parentNode.removeChild(el);
  } else {
    this.deleteObject();
  }
};

Draggable.prototype.deleteObject = function () {
  // this.element.remove();
  this.element.parentNode.removeChild(this.element);
  // this.removeObjectRequest();
  MainData.inCreateMode = false;
  document.querySelector(".header .icon-link").classList.remove("active");
  document.querySelector(".header .icon-comment").classList.remove("active");
};

Draggable.prototype.createObject = function () {
  if (!this.isValid(this.type)) {
    return false;
  }
  if (this.type === "link") {
    this.data.text = this.inputLinkLabel.value;
    this.data.url = this.inputLinkUrl.value;
  }
  if (this.type === "comment") {
    this.data.text = this.textarea.value;
  }
  const el = this.element.querySelector(".hidden-part");
  // el.remove();
  el.parentNode.removeChild(el);
  if (this.isSaved) {
    this.updateObjectRequest(false);
  } else {
    this.addDragToEl();
    this.createObjectRequest();
  }
  MainData.inCreateMode = false;
  document.querySelector(".header .icon-link").classList.remove("active");
  document.querySelector(".header .icon-comment").classList.remove("active");
  this.isSaved = true;
};

Draggable.prototype.updateObjectRequest = function (isTextUpdate, data) {
  if (!data) {
    data = this.getObjectForSaveRequest(isTextUpdate);
  } else {
    data.id = data._id;
    if (isTextUpdate) {
      data.classIdList = MainData.selectedClassListIds;
    } else {
      data.classIdList = this.data.classIdList;
    }
  }
  if (MainData.userType === "student") {
    data.classIdList = MainData.classList.map((el) => el.classId);
  }
  let url;
  if (data.type === "comment") {
    url = `${MainData.environment.pdfApi}comment/update`;
  }
  if (data.type === "link") {
    url = `${MainData.environment.pdfApi}link/update`;
  }
  let request = {};
  request.type = "POST";
  request.data = data;
  request.url = url;
  request.beforeSend = function (xhr) {
    xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  };
  request.success = res => {
    if (res && res.status === "error"){
      MainData.notify.show(res.errorMessage);
      document.querySelector("div[data-link-id-inbook='undefined']").remove();
    } else {
      this.updateWindowLink(data, "update");
      this.updatePageLink(data);
      MainData.inCreateMode = false;
      document.querySelector(".header .icon-link").classList.remove("active");
      document.querySelector(".header .icon-comment").classList.remove("active");
      if (isTextUpdate) {
        MainData.sidebarObj.closeLinkDetailsWrapper(data);
      }
      MainData.selectedClassListIds = [];
    }
  };
  request.processData = true;
  $.ajax(request);
};

Draggable.prototype.isValid = function (type) {
  if (type === "link") {
    if(!this.inputLinkUrl.value || !this.inputLinkUrl.value.length) {
      MainData.notify.show(MainData.langData["Field link is required"]);
      return false
    }
    if(!Utils.isValidUrl(this.inputLinkUrl.value)) {
      MainData.notify.show(MainData.langData["URL is not valid"]);
      return false
    }
  }
  if (type === "comment" && (!this.textarea.value || !this.textarea.value.length)) {
    MainData.notify.show(MainData.langData["Field comment is required"]);
    return false
  }
  return true;
}
Draggable.prototype.createObjectRequest = function () {
  let data = this.getObjectForSaveRequest();
  let url;
  if (data.type === "comment") {
    url = `${MainData.environment.pdfApi}comment/create`;
  }
  if (data.type === "link") {
    url = `${MainData.environment.pdfApi}link/create`;
  }

  let request = {};
  request.type = "PUT";
  request.data = data;
  request.url = url;
  request.beforeSend = function (xhr) {
    xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  };
  request.success = res => {
    if (res && res.status === "error"){
      MainData.notify.show(MainData.langData[res.errorMessage]);
      document.querySelector("div[data-link-id-inbook='undefined']").remove();
    } else {
      data._id = res.id;
      this.data._id = res.id;
      this.element.setAttribute("data-link-id-inbook", this.data._id);
      const hoveredPart = this.createHoveredPart();
      this.element.appendChild(hoveredPart);
      this.updateWindowLink(data, "create");
      MainData.inCreateMode = false;
      document.querySelector(".header .icon-link").classList.remove("active");
      document.querySelector(".header .icon-comment").classList.remove("active");
      MainData.selectedClassListIds = [];
      MainData.notify.show(MainData.langData['Successful'], { type: "success" });
    }
  };
  request.processData = true;
  request.error = err => {
    console.log(err);
    // MainData.notify.show(MainData.langData["Something went wrong"], false);
    document.querySelector("div[data-link-id-inbook='undefined']").remove();
  }
  $.ajax(request);
};

Draggable.prototype.updateWindowLink = function (data, updatingType) {
  let updatedLink;
  if (data.id) {
    updatedLink = MainData.linksAndComments.find(item => item._id === data.id);
  }
  if (data._id) {
    updatedLink = MainData.linksAndComments.find(item => item._id === data._id);
  }
  switch (updatingType) {
    case "create": {
      const newItem = {};
      newItem.bookId = data.bookId;
      newItem.left = data.left;
      newItem.pageId = data.pageId;
      newItem.text = data.text;
      newItem.top = data.top;
      newItem.type = data.type;
      newItem.__v = 0;
      newItem._id = data._id;
      newItem.userId = MainData.userId;
      newItem.classIdList = data.classIdList;
      if (newItem.type === "link") {
        newItem.url = data.url;
      }
      MainData.linksAndComments.push(newItem);
      MainData.sidebarObj.showLinks();
      break;
    }
    case "update": {
      updatedLink.top = data.top;
      updatedLink.left = data.left;
      updatedLink.text = data.text;
      updatedLink.classIdList = data.classIdList;
      if (updatedLink.type === "link") {
        updatedLink.url = data.url;
      }
      MainData.sidebarObj.showLinks();
      break;
    }
    // case 'delete': {
    //     const indexOfDeletedElement = MainData.linksAndComments.indexOf(updatedLink);
    //     MainData.linksAndComments.splice(indexOfDeletedElement, 1);
    //     console.log(MainData.linksAndComments);
    //     MainData.sidebarObj.showLinks();
    //     break;
    // }
  }
};

Draggable.prototype.updateInput = function (input, text) {
  if (text) {
    if (text && text.length > 120) {
      input.innerText = text.replace(/[\r\n]/g, "").substring(0, 120) + "...";
    }
    if (text && text.length <= 120) {
      input.innerText = text;
    }
  }
}
Draggable.prototype.updatePageLink = function (data) {
  const linkInPage = document.querySelector(
    `[data-link-id-inbook='${data._id}'`
  );
  if (linkInPage) {
    let input = linkInPage.querySelector(".hovered-part-desc");
    let field = "text";
    Draggable.prototype.updateInput(input, data[field]);
    if (data.type === "link") {
      field = "url";
      input = linkInPage.querySelector(".hovered-url");
      Draggable.prototype.updateInput(input, data[field])
    }
    const classes = linkInPage.querySelector('.hovered-part-classlist');
    if (classes) {
      const arr = MainData.classList.filter((el) => {
        return data.classIdList.find((id) => id === el.classId);
      });
      classes.innerText = arr.map((el) => el.name).join();
    }
  }
};

Draggable.prototype.isColliding = function(ac, bc) {
  if(Math.abs(ac.top - bc.top) < ac.height && Math.abs(ac.left - bc.left) < ac.width) {
    return true;
    /*if(Math.abs(ac.top - bc.top) < Math.abs(ac.left - bc.left)) {
      // vartical offset is smaller, so snap 'y's

      if(ac.top < bc.top) { // a is above b, so snap a's bottom to b's top
        // a.style.top = bc.top - ac.height - 1 + 'px';
        return bc.top - ac.height - 1 + 'px';
      } else {
        // a.style.top = bc.top + bc.height + 1 + 'px';
        return bc.top + bc.height + 1 + 'px';
      }

    } else {
      // here, horizontal offset is smaller, so snap 'x's
      if(ac.left < bc.left) {
        // a is to the left of b, so snap a's right to b's left
        // a.style.left = bc.left - ac.width - 1 + 'px';
        return bc.left - ac.width - 1 + 'px';
      } else {
        // a.style.left = bc.left + bc.width + 1 + 'px';
        return bc.left + bc.width + 1 + 'px';
      }

    }*/

  }
};

Draggable.prototype.getObjectForSaveRequest = function(isTextUpdate) {
  let data = {};

  const parentEl = this.element.parentElement;
  const parentPos = parentEl.getBoundingClientRect();
  const sourceEl = this.element;
  let sourcePos = sourceEl.getBoundingClientRect();
  let sourceX = sourcePos.left - parentPos.left;
  let sourceY = sourcePos.top - parentPos.top;

  const x = (sourceX * 100) / parentPos.width;
  let y = (sourceY * 100) / parentPos.height;
  this.data.left = x;
  this.data.top = y;

  let pageId;
  console.log(MainData.bookPages);
  console.log(MainData.currentPage);
  if (this.isSaved) {
    pageId = this.data.pageId;
  } else {
    /*pageId = MainData.bookPages.find(p => p.number == MainData.currentPage - 2)
      .pageId;*/

    pageId = MainData.bookPages.find(p => p.number == MainData.currentPage - 1)
      .pageId;
    this.data.pageId = pageId;
  }
  const form = document.querySelector(`[data-page-id='${this.data.pageId}'`).querySelector(`[data-link-id-inbook='undefined']`) || document.querySelector(`[data-link-id-inbook='undefined']`);
  let amountColliding = 0;
  const checkColliding = () => {
    let isCollided = false;
    let isBreak = false;
    let i = 0;
    const currentForm = form.getBoundingClientRect();
    do {
      let rect1 = document.querySelector(`[data-link-id-inbook='${MainData.linksAndComments[i]._id}'`).getBoundingClientRect();
      i = i + 1;
      isCollided = this.isColliding(rect1, currentForm);
      isBreak = isCollided || i >= MainData.linksAndComments.length;
    } while (!isBreak);
    if (isCollided){
      amountColliding++;
      y = ((amountColliding * 49 + sourceY) * 100) / parentPos.height;
      form.style.top = `calc(${y}% + 19.5px)`;
      form.style.left = `calc(${x}% + 19.5px)`;
      this.data.top = y;
      return checkColliding();
    } else {
      y = ((amountColliding * 49 + sourceY) * 100) / parentPos.height;
      form.style.top = `calc(${y}% + 19.5px)`;
    }
  };
  if (!this.isSaved) {
    if (MainData.linksAndComments.length) {
      checkColliding();
    }
  }

  let selectedClassListIds;
  if ((isTextUpdate !== undefined && isTextUpdate) || !this.isSaved) {
    selectedClassListIds = MainData.selectedClassListIds;
    this.data.classIdList = MainData.selectedClassListIds;
  } else {
    selectedClassListIds = this.data.classIdList;
  }
  if (MainData.userType === "student") {
    selectedClassListIds = MainData.classList.map((el) => el.classId);
  }
  // select by default all classes - no necessary because if no one classes is selected it assigns to the teacher only
  // if (!selectedClassListIds.length && MainData.userType === "teacher") {
    // selectedClassListIds = MainData.classList.map((el) => el.classId);
  // }

  if (this.type === "link") {
    data = {
      url: this.inputLinkUrl.value,
      text: this.inputLinkLabel.value,
      bookId: MainData.bookId,
      pageId: pageId,
      type: this.type,
      top: y,
      left: x,
      id: this.isSaved ? this.data._id : null,
      classIdList: selectedClassListIds
    };
  }
  if (this.type === "comment") {
    data = {
      text: this.textarea.value,
      bookId: MainData.bookId,
      pageId: pageId,
      type: this.type,
      top: y,
      left: x,
      id: this.isSaved ? this.data._id : null,
      classIdList: selectedClassListIds
    };
  }
  return data;
};

Draggable.prototype.addDragToEl = function () {
  this.isDraggable = true;
  let that = this;
  createDraggableElement(this.element);

  function createDraggableElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    // todo: need to refactor -  remove duplicated code
    function dragTouchStart(e) {
      const wrap = document.querySelector('.conteiner-wrapper');
      wrap.classList.add('no-scroll');
      var touchLocation = e.targetTouches[0];
      pos3 = touchLocation.pageX;
      pos4 = touchLocation.pageY;
    }

    function dragTouchMove(e) {
      var touchLocation = e.targetTouches[0];
      const zoomFactor = Math.floor(MainData.zoomFactor * 100) / 100;
      pos1 = (pos3 - touchLocation.pageX) / zoomFactor;
      pos2 = (pos4 - touchLocation.pageY) / zoomFactor;
      pos3 = touchLocation.pageX;
      pos4 = touchLocation.pageY;
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    if ( Utils.isNotDesktop() ) {
      elmnt.addEventListener('touchstart', dragTouchStart);
      elmnt.addEventListener('touchmove', dragTouchMove);

      elmnt.addEventListener('touchend', function(e) {
        document.removeEventListener("touchstart", dragTouchStart);
        document.removeEventListener("touchmove", dragTouchMove);
        const wrap = document.querySelector('.conteiner-wrapper');
        wrap.classList.remove('no-scroll');
        const parentEl = elmnt.parentElement;
        const parentPos = parentEl.getBoundingClientRect();
        let sourcePos = elmnt.getBoundingClientRect();
        let sourceX = sourcePos.left - parentPos.left;
        let sourceY = sourcePos.top - parentPos.top;
        const x = (sourceX * 100) / parentPos.width;
        const y = (sourceY * 100) / parentPos.height;
        elmnt.style.top = `calc(${y}% + 19.5px)`;
        elmnt.style.left = `calc(${x}% + 19.5px)`;
        if (
          that.data.top > +y + 0.5 ||
          that.data.top < +y - 0.5 ||
          that.data.left > +x + 0.5 ||
          that.data.left < +x - 0.5
        ) {
          that.updateObjectRequest(false);
        }
        that.setClassForHoveredPart(x, y);
      })
    } else {
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.addEventListener("mouseup", closeDragElement);
      document.addEventListener("mousemove", elementDrag);
    }

    function elementDrag(e) {
      that.open = false;
      e = e || window.event;
      e.preventDefault();
      const zoomFactor = Math.floor(MainData.zoomFactor * 100) / 100;
      pos1 = (pos3 - e.clientX) / zoomFactor;
      pos2 = (pos4 - e.clientY) / zoomFactor;
      pos3 = e.clientX;
      pos4 = e.clientY;
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement(e) {
      //that.open = true;
      e.preventDefault();
      e.stopPropagation();
      document.removeEventListener("mouseup", closeDragElement); //onmouseup = null;
      document.removeEventListener("mousemove", elementDrag); //onmousemove = null;

      const parentEl = elmnt.parentElement;
      const parentPos = parentEl.getBoundingClientRect();
      let sourcePos = elmnt.getBoundingClientRect();
      let sourceX = sourcePos.left - parentPos.left;
      let sourceY = sourcePos.top - parentPos.top;
      const x = (sourceX * 100) / parentPos.width;
      const y = (sourceY * 100) / parentPos.height;
      elmnt.style.top = `calc(${y}% + 19.5px)`;
      elmnt.style.left = `calc(${x}% + 19.5px)`;
      if (
        that.data.top > +y + 0.5 ||
        that.data.top < +y - 0.5 ||
        that.data.left > +x + 0.5 ||
        that.data.left < +x - 0.5
      ) {
        that.updateObjectRequest(false);
      }
      that.setClassForHoveredPart(x, y);
    }
  }
};
