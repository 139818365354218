import { Action } from './actions.js';
import { MainData } from './mainData.js';
import { ClassSelectDropdown } from "@/js/class-select-dropdown";
import { Utils } from "@/js/utils";
import moment from 'moment';
import {UiModal} from "@/js/ui-modal";
moment.locale('he');

export class Marks {
    constructor() {}
    static onCloseMark(isCreate = false) {
        const mark = document.querySelector('.mark.active');
        if (mark) {
          const classSelector = mark.querySelector(".class-select-wrapper");
          mark.classList.remove("active");
          if (classSelector) {
              MainData.selectedClassListIds = [];
              classSelector.remove();
          }
          if (isCreate){
              this.onDeleteMarkFromUi(mark.getAttribute("data-mark-temp-id"), isCreate);
          }
        }
        $('body').removeClass('is-marking');
    };
    static onDeleteMarkFromUi(id, isCreate = false){
        MainData.selectedClassListIds = [];
        const wrapper = document.querySelector(".conteiner-wrapper");
        wrapper.classList.remove("no-horizontal-scroll");
        const markElements = document.querySelectorAll((isCreate ? "[data-mark-temp-id='" : "[data-mark-group-id='") + id +"']");
        markElements.forEach(item => {
            item.parentNode.removeChild(item);
        });

    }
}

Marks.prototype.links = [];
Marks.prototype.initWordsForMarks = function() {
    // console.log(MainData.coordsData);
    preparing();

    function preparing() {
      MainData.coordsData.forEach(page => {
        const pageEl = document.querySelector(
          `[data-page-id="${page.pageId}"]`
        );
        if (pageEl && page.data && page.data.length) {
          page.data.forEach(word => {
            if (word) {
                if (validateEmail(word.word)) {
                    Marks.prototype.links.push(word)
                }
              const wordInDB = Marks.prototype.createElWord(word, page);
              pageEl.appendChild(wordInDB);
              if (word.type) {
                  wordInDB.addEventListener('click', function(){
                      console.log(word);
                      const data = word.word;
                      if (word.type === 1) {
                          window.open(`mailto:${data}`, "_blank");
                      } else {
                          const url = !data.match(/^https?:\/\//i) ? 'http://' + data : data;
                          console.log(url);
                          window.open(url, "_blank");
                      }
                  })
              }
            }
          });
        }
      });
      console.log(Marks.prototype.links);
    }
}

function validateEmail(email){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
}
Marks.prototype.createElWord = function(word) {
    let wordWrapper = document.createElement("div");
    wordWrapper.classList.add("word-wrapper");
    wordWrapper.style.width = word.wPercent * 105 + 0.5 + "%";
    wordWrapper.style.height = word.max_hPercent * 120 + "%";
    wordWrapper.style.top = (word.yPercent) * 100 - 0.5 + "%";
    wordWrapper.style.left = word.xPercent * 100 - 0.5 + "%";
    wordWrapper.style.background = 'transparent';
    wordWrapper.style.opacity = word.type ? 1 : 0;
    if (!word.type) {
        wordWrapper.style.userSelect = 'none';
    } else {
        wordWrapper.style.borderBottom = '2px solid #6c90ff';
        wordWrapper.style.cursor = 'pointer';
    }
    if (word.index >= 0) {
        wordWrapper.setAttribute("word-index", word.index);
    }
    wordWrapper.innerText = word.word;
    wordWrapper.style.fontSize = word.type ? 0 : "calc((200vh - 145px)/2 *" + word.max_hPercent + ")";
    return wordWrapper;
}

Marks.prototype.addMarksOnPages = function() {
    if(MainData.marksData && MainData.marksData.length > 0) {
        for (let mark of MainData.marksData) {
            Marks.prototype.pageEl = document.querySelector(`[data-page-id="${mark.pageId}"]`);
            const colorMark = mark.classColor;
            const markGroupId = mark.groupId;
            if (Marks.prototype.pageEl) {
                mark.marks.forEach(word => {
                    var el = document.createElement('div');
                    var tooltip = Marks.prototype.createMarkTooltip(word, false, mark.userId === MainData.userId);
                    el.classList.add('mark');
                    el.classList.add(`mark-${colorMark}`);
                    el.appendChild(tooltip);
                    el.setAttribute('data-mark-id', word._id);
                    el.setAttribute('data-mark-pageId', mark.pageId);
                    el.setAttribute('data-mark-classes', word.classIdList || '');
                    el.setAttribute('data-color', colorMark);
                    el.setAttribute('data-mark-group-id', markGroupId);
                    el.setAttribute('data-word', word.wordData);
                    if (word.comment) {
                        el.setAttribute('data-comment', word.comment);
                        el.classList.add('mark-comment');
                    }
                    el.style.width = word.width * 100 + '%';
                    el.style.height = word.height * 100 + '%';
                    el.style.top = word.top * 100 + '%';
                    el.style.left = word.left * 100 + '%';
                    el.querySelector(".mark-tooltip");
                    el.addEventListener('click', (e) => {
                        Marks.prototype.onClickMark(e, markGroupId)
                    });
                    Marks.prototype.pageEl.appendChild(el);
                });
            }
        }
    }
}

Marks.prototype.setMouseEvents = function(windowDATA) {
    if (Utils.isNotDesktop()) {
        document.addEventListener('touchstart', function (e) {
            /*if (document.querySelector('.mark.active')) {
                return false;
            }*/
            console.log('touchstart');
            Marks.prototype.check = false;
            Marks.prototype.moveSelectedStrings = [];
            Marks.prototype.markWordsIndexes = [];
            Marks.prototype.currentMoveString = {};
            Marks.prototype.calcMarkGroupId = null;
            Marks.prototype.firstWordMove = null;
            Marks.prototype.windowDATA = windowDATA;
            if (MainData.markColor !== 'none') {
                Marks.prototype.check = true;
                Marks.prototype.pageNumber = Marks.prototype.findPageNumber(e.target);
                if (!Marks.prototype.pageNumber) return;
                Marks.prototype.pageEl = e.target.closest('.page');
                if (Marks.prototype.windowDATA[Marks.prototype.pageNumber].data) {
                    this.word = Marks.prototype.getWord(e);
                    if (this.word && this.word.word.length > 0) {
                        Marks.prototype.firstWordMove = this.word;
                        Marks.prototype.calcMarkGroupId = Math.round(this.word.max_hPercent * 100).toString() + Math.round(this.word.wPercent * 100).toString() + Math.round(this.word.xPercent * 100).toString() + Math.round(this.word.max_yPercent * 100).toString();
                        document.addEventListener('touchmove', Marks.prototype.move);
                        document.addEventListener('touchend', Marks.prototype.mouseUpEvent);
                    }
                }
            }
        })
    } else {
        document.addEventListener('mousedown', function (e) {
            Marks.prototype.check = false;
            Marks.prototype.moveSelectedStrings = [];
            Marks.prototype.markWordsIndexes = [];
            Marks.prototype.currentMoveString = {};
            Marks.prototype.calcMarkGroupId = null;
            Marks.prototype.firstWordMove = null;
            Marks.prototype.windowDATA = windowDATA;
            if (MainData.markColor !== 'none') {
                Marks.prototype.check = true;
                Marks.prototype.pageNumber = Marks.prototype.findPageNumber(e.target);
                if (!Marks.prototype.pageNumber) return;
                Marks.prototype.pageEl = e.target.closest('.page');
                const activePage = Marks.prototype.windowDATA[Marks.prototype.pageNumber];
                if (activePage && activePage.data) {
                    this.word = Marks.prototype.getWord(e);
                    if (this.word && this.word.word.length > 0) {
                        Marks.prototype.firstWordMove = this.word;
                        Marks.prototype.calcMarkGroupId = Math.round(this.word.max_hPercent * 100).toString() + Math.round(this.word.wPercent * 100).toString() + Math.round(this.word.xPercent * 100).toString() + Math.round(this.word.max_yPercent * 100).toString();
                        document.addEventListener('mousemove', Marks.prototype.move);
                        document.addEventListener('mouseup', Marks.prototype.mouseUpEvent);
                    }
                }
            }
        })
    }
}

Marks.prototype.mark = function(isSameString) {
    if (isSameString) {
        Marks.prototype.currentMoveStringEl.style.left = Marks.prototype.currentMoveString.left * 100 + '%';
        Marks.prototype.currentMoveStringEl.style.width = Marks.prototype.currentMoveString.width * 100 + '%';
        Marks.prototype.currentMoveStringEl.setAttribute('data-word', Marks.prototype.getWordDataString(Marks.prototype.currentMoveString.wordDataObj));
    } else {
        let el = document.createElement('div');
        el.classList.add('mark');
        el.classList.add(`mark-${MainData.markColor}`);
        el.setAttribute('data-mark-temp-id', Marks.prototype.calcMarkGroupId);
        el.setAttribute('data-color', MainData.markColor);
        el.setAttribute('data-word', Marks.prototype.getWordDataString(Marks.prototype.currentMoveString.wordDataObj));
        el.style.width = Marks.prototype.currentMoveString.width * 100 + '%';;
        el.style.height = Marks.prototype.currentMoveString.height * 100 + '%';;
        el.style.left = Marks.prototype.currentMoveString.left * 100 + '%';;
        el.style.top = Marks.prototype.currentMoveString.top * 100 + '%';;

        const allTempMarks = document.querySelectorAll(`[data-mark-temp-id]`);
        const lastTempMark = [...allTempMarks].pop();
        const firstTempMark = [...allTempMarks].shift();
        if (!firstTempMark) {
            let firstWordEl = Marks.prototype.pageEl.querySelector('.word-wrapper');
            Marks.prototype.pageEl.insertBefore(el, firstWordEl);
        }
        if (firstTempMark && parseInt(el.style.top) > parseInt(firstTempMark.style.top)) {
            lastTempMark.after(el);
        }
        if (firstTempMark && parseInt(el.style.top) < parseInt(firstTempMark.style.top)) {
            Marks.prototype.pageEl.insertBefore(el, firstTempMark);
        }
        Marks.prototype.currentMoveStringEl = el;
    }
}

Marks.prototype.unMark = function(isSameString) {
    if (isSameString && Marks.prototype.currentMoveStringEl) {
        Marks.prototype.currentMoveStringEl.style.left = Marks.prototype.currentMoveString.left * 100 + '%';
        Marks.prototype.currentMoveStringEl.style.width = Marks.prototype.currentMoveString.width * 100 + '%';
        Marks.prototype.currentMoveStringEl.setAttribute('data-word', Marks.prototype.getWordDataString(Marks.prototype.currentMoveString.wordDataObj));
    }
}

Marks.prototype.getWordDataString = function(wordDataObj) {
    let wordDataArr = [];
    wordDataObj.forEach(obj => {
        wordDataArr.push(obj.word)
    });
    return wordDataArr.join(' ');
}

Marks.prototype.move = function (e) {
    const wrap = document.querySelector('.conteiner-wrapper');
    wrap.classList.add('no-scroll');
    if (Marks.prototype.check) {
        let wordMove = Marks.prototype.getWord(e);
        if (wordMove && wordMove.word.length > 0) {
            const pageWordData = Marks.prototype.windowDATA[Marks.prototype.pageNumber].data;
            const firstWordMoveIndex = Marks.prototype.firstWordMove?.index;
            let lastWordIndex;
            if (firstWordMoveIndex < wordMove.index) {
                lastWordIndex = Math.max(...Marks.prototype.markWordsIndexes);
            }
            if (firstWordMoveIndex > wordMove.index) {
                lastWordIndex = Math.min(...Marks.prototype.markWordsIndexes);
            }
            if (firstWordMoveIndex === wordMove.index && Marks.prototype.markWordsIndexes.length) {
                if (Marks.prototype.markWordsIndexes.indexOf(firstWordMoveIndex) === 0) {
                    lastWordIndex = Math.max(...Marks.prototype.markWordsIndexes);
                } else {
                    lastWordIndex = Math.min(...Marks.prototype.markWordsIndexes);
                }
            }

            if (wordMove.index !== firstWordMoveIndex) {
                if (wordMove.index > lastWordIndex && wordMove.index > firstWordMoveIndex
                  || wordMove.index < lastWordIndex && wordMove.index < firstWordMoveIndex) {
                    const minIndex = Math.min(...Marks.prototype.markWordsIndexes);
                    const maxIndex = Math.max(...Marks.prototype.markWordsIndexes);
                    if (wordMove.index > firstWordMoveIndex && minIndex < firstWordMoveIndex
                      || wordMove.index < firstWordMoveIndex && maxIndex > firstWordMoveIndex) {
                        const promise = new Promise((resolve, reject) => {
                            Marks.prototype.removeMarksBeforeAdding(pageWordData, wordMove, firstWordMoveIndex);
                            resolve();
                        })
                        promise.finally(Marks.prototype.prepareForAddingToMarksArr(pageWordData, wordMove, lastWordIndex));
                    } else {
                        Marks.prototype.prepareForAddingToMarksArr(pageWordData, wordMove, lastWordIndex);
                    }

                }
                if (wordMove.index < lastWordIndex && wordMove.index > firstWordMoveIndex
                  || wordMove.index > lastWordIndex && wordMove.index < firstWordMoveIndex) {
                    Marks.prototype.prepareForRemovingFromMarksArr(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex);
                }
            } else {
                if (Marks.prototype.markWordsIndexes.length > 1) {
                    Marks.prototype.prepareForRemovingFromMarksArr(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex);
                } else {
                    Marks.prototype.addWordToMarksArray(wordMove);
                }
            }
        }
    }
}

Marks.prototype.removeMarksBeforeAdding = function(pageWordData, wordMove, firstWordMoveIndex) {
    let lastWordIndexForRemoving;
    if (firstWordMoveIndex < wordMove.index) {
        lastWordIndexForRemoving = Math.min(...Marks.prototype.markWordsIndexes);
    }
    if (firstWordMoveIndex > wordMove.index) {
        lastWordIndexForRemoving = Math.max(...Marks.prototype.markWordsIndexes);
    }
    Marks.prototype.prepareForRemovingFromMarksArr(pageWordData, Marks.prototype.firstWordMove, lastWordIndexForRemoving, firstWordMoveIndex);
}

Marks.prototype.prepareForAddingToMarksArr = function(pageWordData, wordMove, lastWordIndex) {
    let wordsForAdding;
    if (wordMove.index > lastWordIndex) {
        wordsForAdding = pageWordData.slice(lastWordIndex, wordMove.index + 1);
    } else {
        wordsForAdding = pageWordData.slice(wordMove.index, lastWordIndex + 1).reverse();
    }
    if (wordsForAdding && wordsForAdding.length) {
        const isMarkExist = Marks.prototype.checkIfWordMarked(wordsForAdding);
        if (!isMarkExist) {
            wordsForAdding.forEach(wordData => {
                Marks.prototype.addWordToMarksArray(wordData, lastWordIndex);
            })
        } else {
            Action.prototype.showUserToast("You can't create new mark over old one");
        }
    }
}

Marks.prototype.checkIfWordMarked = function(wordsForAdding) {
    let isMarkExist = false;
    wordsForAdding.forEach(wordData => {
        const allCreatedMarks = [...Marks.prototype.pageEl.querySelectorAll('[data-mark-group-id]')];
        allCreatedMarks.forEach(mark => {
            const markY = parseInt(mark.style.top)/100;
            const markX = parseInt(mark.style.left)/100;
            const markW = parseInt(mark.style.width)/100;
            const markH = parseInt(mark.style.height)/100;
            if (wordData['max_yPercent'] > markY ) {
                if (wordData['max_yPercent'] < (markY + markH)) {
                    if (wordData['xPercent'] > markX) {
                        if (wordData['xPercent'] < (markX + markW)) {
                            return isMarkExist = true;
                        }
                    }
                }
            }
        })
        if (isMarkExist) return;
    })
    return isMarkExist;
}

Marks.prototype.prepareForRemovingFromMarksArr = function(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex) {
    let lastWordForRemoving;
    if (wordMove.index < lastWordIndex && wordMove.index >= firstWordMoveIndex) {
        lastWordForRemoving = pageWordData.find(item => item.index === wordMove.index + 1);
    }
    if (wordMove.index > lastWordIndex && wordMove.index <= firstWordMoveIndex) {
        lastWordForRemoving = pageWordData.find(item => item.index === wordMove.index - 1);
    }
    if (wordMove.index > lastWordIndex && wordMove.index > firstWordMoveIndex) {
        lastWordForRemoving = pageWordData.find(item => item.index === firstWordMoveIndex + 1);
    }
    if (wordMove.index < lastWordIndex && wordMove.index < firstWordMoveIndex) {
        lastWordForRemoving = pageWordData.find(item => item.index === firstWordMoveIndex - 1);
    }
    if (Marks.prototype.markWordsIndexes.length > 1) {
        Marks.prototype.removeWordsFromMarksArray(lastWordForRemoving, wordMove, lastWordIndex, firstWordMoveIndex);
    }
}

Marks.prototype.addWordToMarksArray = function(word, lastWordIndex) {
    const isExistWord = Marks.prototype.markWordsIndexes.includes(word.index);
    if (!isExistWord) {
        const wordTop = (word.max_yPercent - word.max_hPercent * 0.15) * 100
        const stringTop = Marks.prototype.currentMoveString.top * 100;
        const isSameString = Math.abs(wordTop - stringTop) < word.max_hPercent * 100;
        if (isNaN(stringTop) || isSameString) {
            if (Object.keys(Marks.prototype.currentMoveString).length === 0) {
                addNewCurrentMoveString();
            } else {
                let isLastWordMoreWordMove;
                if (lastWordIndex) {
                    isLastWordMoreWordMove = lastWordIndex > word.index;
                }
                Marks.prototype.currentMoveString.wordDataObj.push({index: word.index, word: word.word});
                Marks.prototype.currentMoveString.wordDataObj = Marks.prototype.currentMoveString.wordDataObj.sort((a, b) => a.index - b.index);
                if (!isLastWordMoreWordMove) {
                    Marks.prototype.currentMoveString.width += Marks.prototype.currentMoveString.left - word.xPercent;
                    Marks.prototype.currentMoveString.left = word.xPercent
                } else {
                    Marks.prototype.currentMoveString.width += word.xPercent - Marks.prototype.currentMoveString.left - Marks.prototype.currentMoveString.width + word.wPercent;
                }
            }
        } else {
            const stringObj = JSON.parse(JSON.stringify(Marks.prototype.currentMoveString));
            Marks.prototype.moveSelectedStrings.push(stringObj);
            Marks.prototype.currentMoveString = {};
            addNewCurrentMoveString();
        }
        Marks.prototype.markWordsIndexes.push(word.index);
        Marks.prototype.markWordsIndexes = Marks.prototype.markWordsIndexes.sort((a,b) => {return a - b});
        Marks.prototype.mark(isSameString);
    }

    function addNewCurrentMoveString() {
        Marks.prototype.currentMoveString = {
            wordDataObj: [{index: word.index, word: word.word}],
            width: word.wPercent,
            height: word.max_hPercent * 1.3,
            left: word.xPercent,
            top: word.max_yPercent - word.max_hPercent * 0.15
        }
    }
}

Marks.prototype.removeWordsFromMarksArray = function(lastWordForRemoving, wordMove, lastWordIndex) {
    const lastWordRemovingIndexInArray =  Marks.prototype.markWordsIndexes.indexOf(lastWordForRemoving.index);
    const isLastWordMoreWordMove = lastWordIndex > wordMove.index;
    if (isLastWordMoreWordMove) {
        Marks.prototype.markWordsIndexes = Marks.prototype.markWordsIndexes.slice(0, lastWordRemovingIndexInArray);
    } else {
        Marks.prototype.markWordsIndexes = Marks.prototype.markWordsIndexes.slice(lastWordRemovingIndexInArray + 1, Marks.prototype.markWordsIndexes.length);
    }
    const wordTop = (lastWordForRemoving.max_yPercent - lastWordForRemoving.max_hPercent * 0.15) * 100
    const stringTop = Marks.prototype.currentMoveString.top * 100;
    let isSameString = Math.abs(wordTop - stringTop) < lastWordForRemoving.max_hPercent * 100;

    if (isSameString) {
        cutIfSameString();
    } else {
        Marks.prototype.currentMoveStringEl?.remove();
        const allTempMarkEls = [...document.querySelectorAll(`[data-mark-temp-id]`)];
        Marks.prototype.currentMoveStringEl = allTempMarkEls.find(el => Math.abs(parseInt(el.style.top)/100 - wordMove.max_yPercent) < 0.03);
        const oldWordMoveString = JSON.parse(JSON.stringify(Marks.prototype.currentMoveString));
        Marks.prototype.moveSelectedStrings = Marks.prototype.moveSelectedStrings.filter(item => {
            let isStringForRemoving = false;
            const currentWordString = item.wordDataObj.find(obj => wordMove.index === obj.index);
            if (currentWordString) {
                Marks.prototype.currentMoveString = JSON.parse(JSON.stringify(item));
                isSameString = true;
                cutIfSameString();
                isStringForRemoving = true;
            }
            if (Marks.prototype.currentMoveString.top > oldWordMoveString.top && Marks.prototype.currentMoveString.top > item.top
              || Marks.prototype.currentMoveString.top < oldWordMoveString.top && Marks.prototype.currentMoveString.top < item.top) {
                isStringForRemoving = true;
                const elForRemoving = allTempMarkEls.find(el => Math.abs(parseInt(el.style.top)/100 - item.top) < 0.02);
                if (elForRemoving) {
                    elForRemoving.remove();
                }
            }
            return !isStringForRemoving;
        });
    }

    function cutIfSameString() {
        const lastWordDataInString = Marks.prototype.currentMoveString.wordDataObj.find(item => item.index === lastWordForRemoving.index);
        const lastWordDataIndexInString = Marks.prototype.currentMoveString.wordDataObj.indexOf(lastWordDataInString);
        if (isLastWordMoreWordMove) {
            Marks.prototype.currentMoveString.wordDataObj = Marks.prototype.currentMoveString.wordDataObj.slice(0, lastWordDataIndexInString);
            Marks.prototype.currentMoveString.width -= wordMove.xPercent - Marks.prototype.currentMoveString.left;
            Marks.prototype.currentMoveString.left = wordMove.xPercent;
        } else {
            const lengthArr = Marks.prototype.currentMoveString.wordDataObj.length;
            Marks.prototype.currentMoveString.wordDataObj = Marks.prototype.currentMoveString.wordDataObj.slice(lastWordDataIndexInString + 1, lengthArr);
            Marks.prototype.currentMoveString.width = (wordMove.xPercent + wordMove.wPercent) - Marks.prototype.currentMoveString.left;
        }
        Marks.prototype.unMark(isSameString);
    }
}

Marks.prototype.mouseUpEvent = function () {
    const wrap = document.querySelector('.conteiner-wrapper');
    wrap.classList.remove('no-scroll');
    Marks.prototype.check = false;
    const stringObj = JSON.parse(JSON.stringify(Marks.prototype.currentMoveString));
    if (Object.keys(stringObj).length) {
        Marks.prototype.moveSelectedStrings.push(stringObj);
        Marks.prototype.currentMoveString = {};
    }
    if (Marks.prototype.moveSelectedStrings.length == 0) {
        Marks.prototype.addWordToMarksArray(this.word);
        let body = {
            bookId: MainData.bookId,
            pageId: Marks.prototype.pageEl.getAttribute('data-page-id'),
            classColor: `${MainData.markColor}`,
            words: [
                {
                    indexOnPage: this.word.index,
                    wordData: this.word.word,
                    width: this.word.wPercent,
                    height: this.word.max_hPercent,
                    left: this.word.xPercent,
                    top: this.word.max_yPercent - this.word.max_hPercent * 0.01
                }
            ]
        }
        // if (MainData.userType === 'teacher') {
        //   Marks.prototype.createMarkRequest(body);
          Marks.prototype.createMarkUi(body);
        // } else {
        //   Marks.prototype.createMarkRequest(body);
        // }
    } else {
        let body = {
            bookId: MainData.bookId,
            pageId: Marks.prototype.pageEl?.getAttribute('data-page-id'),
            classColor: `${MainData.markColor}`,
            words: []
        }
        Marks.prototype.moveSelectedStrings = Marks.prototype.moveSelectedStrings.sort((a, b) => a.top - b.top);
        for (let k = 0; k < Marks.prototype.moveSelectedStrings.length; k++) {
            const wordDataObj = JSON.parse(JSON.stringify(Marks.prototype.moveSelectedStrings[k].wordDataObj));
            // delete Marks.prototype.moveSelectedStrings[k].wordDataObj;
            Marks.prototype.moveSelectedStrings[k].wordDataObj = [];
            if (wordDataObj?.length) {
                Marks.prototype.moveSelectedStrings[k].wordData = Marks.prototype.getWordDataString(wordDataObj);
                body.words.push(Marks.prototype.moveSelectedStrings[k]);
            }
            if (k === Marks.prototype.moveSelectedStrings.length - 1 && body.words.length) {
              // if (MainData.userType === 'teacher') {
              //   Marks.prototype.createMarkRequest(body);
                Marks.prototype.createMarkUi(body);
              // } else {
              //   Marks.prototype.createMarkRequest(body);
              // }
            }
        };
    }
    Marks.prototype.moveSelectedStrings = [];
    document.removeEventListener("mouseup", Marks.prototype.mouseUpEvent, false);
    document.removeEventListener("mousemove", Marks.prototype.move, false);
    document.removeEventListener('touchmove', Marks.prototype.move, false);
    document.removeEventListener('touchend', Marks.prototype.mouseUpEvent, false);
}

Marks.prototype.findPageNumber = function(el) {
    while (el.parentNode) {
        if (el.dataset && el.dataset.pageNumber)
            return el.dataset.pageNumber;
        el = el.parentNode;
    }
    return null;
}

Marks.prototype.getWord = function(e) {
    const page_cord = Marks.prototype.windowDATA[Marks.prototype.pageNumber]?.data;
    var word = false;
    const markIdDB = e.target.getAttribute('data-mark-group-id');
    if (markIdDB) {
        Action.prototype.showUserToast("You can't create new mark over old one");
        return;
    }
    Marks.prototype.pageEl = e.target.closest('.page');
    const isTargetWord = e.target.classList.contains('mark') || e.target.classList.contains('word-wrapper');
    if (Marks.prototype.pageEl && isTargetWord) {
        let bounds = Marks.prototype.pageEl.getBoundingClientRect();
        let x = (e.clientX || e.changedTouches[0].clientX) - bounds.left;
        let y = (e.clientY || e.changedTouches[0].clientY) - bounds.top;
        const clickRelativeX = x/(Marks.prototype.pageEl.offsetWidth * MainData.zoomFactor);
        const clickRelativeY = y/(Marks.prototype.pageEl.offsetHeight * MainData.zoomFactor);
        for (let i = 0; i < page_cord.length; i++) {
            if (page_cord[i]['word'].length > 0) {
                if (clickRelativeY > page_cord[i]['max_yPercent']) {
                    if (clickRelativeY < (page_cord[i]['max_yPercent'] + page_cord[i]['max_hPercent'])) {
                        if (clickRelativeX > page_cord[i]['xPercent']) {
                            if (clickRelativeX < (page_cord[i]['xPercent'] + page_cord[i]['wPercent'])) {
                                word = page_cord[i];
                            }
                        }
                    }
                }
            }
        }
    }
    return word;
}
Marks.prototype.createMarkTooltip =  function (word, isCreate = false, isDelete) {
    const tooltipWrapper = document.createElement('div');
    tooltipWrapper.classList.add('ui-tooltip');
    tooltipWrapper.classList.add('marked-tooltip');

    const header = document.createElement("div");
    header.classList.add("part-header");
    const iconClose = document.createElement("div");
    iconClose.setAttribute('data-action', 'close');
    iconClose.classList.add("icon-x");
    //
    // const titleWrapper = document.createElement("div");
    // titleWrapper.classList.add("title-wrapper");
    //
    // const headerTitleIcon = document.createElement("div");
    // headerTitleIcon.classList.add(`header-icon`, `icon-comment`);
    //
    // const headerTitleText = document.createElement("div");
    // headerTitleText.classList.add(`title`);
    // headerTitleText.innerText = MainData.langData["Comment"];
    //
    // titleWrapper.appendChild(headerTitleIcon);
    // titleWrapper.appendChild(headerTitleText);

    header.appendChild(iconClose);
    // header.prepend(titleWrapper);

    const wrapInfo = document.createElement("div");
    wrapInfo.classList.add(`comment-info`);
    const info = document.createElement("div");
    info.classList.add(`info`);

    const author = document.createElement("div");
    author.classList.add(`author`);
    let authorText = '';
    // console.log(word);
    if (word.creatorUserType == "USER_TYPE_EDITOR") {
        // authorText = MainData.langData["Added by Administrator/Added by Wizdi"];
    } else {
        authorText = word.creatorName || '';
    }
    author.innerText = authorText;

    const date = document.createElement("div");
    date.classList.add(`date`);
    date.innerText = (word.createDate ? moment(word.createDate) : moment()).format('D MMM y');

    const removeBtn = document.createElement('div');
    removeBtn.classList.add(`btn-delete`);
    removeBtn.setAttribute('data-action', 'remove');
    const removeBtnIcon = document.createElement('i');
    removeBtnIcon.classList.add('icon2-delete');
    removeBtn.appendChild(removeBtnIcon);
    if (authorText) {
        info.append(author);
    }

    if (word.creatorUserType !== "USER_TYPE_EDITOR") info.append(date);
    wrapInfo.append(info);
    if (word.userId == MainData.userId) wrapInfo.append(removeBtn);

    const comment = document.createElement('div');
    comment.classList.add(`comment`);
    comment.innerText = word.comment || '';


    const assign = document.createElement('div');
    assign.classList.add(`assign`);
    if (!word.classIdList || !word.classIdList.length) {
        assign.innerText = word.creatorUserType == "USER_TYPE_EDITOR" ? '' : MainData.langData["Saved only for me"];
    } else {
        const assignedClasses = MainData.classList.filter((cl) => {
            return word.classIdList.find(id => id == cl.classId)
        }).map(el => el.name).join(', ').trim();
        if (assignedClasses && !MainData.userType === 'editor') {
            assign.innerHTML = `<span>${MainData.langData["Saved to:"]}</span> ${assignedClasses}`
        }
    }

    tooltipWrapper.appendChild(header);
    tooltipWrapper.appendChild(wrapInfo);
    if (word.comment) tooltipWrapper.appendChild(comment);
    tooltipWrapper.appendChild(assign);
    return tooltipWrapper;
}

Marks.prototype.onClickMark = function (e, markGroupId, isCreate = false, body = undefined) {
    let item;
    const items = document.querySelectorAll((isCreate ? "[data-mark-temp-id='" : "[data-mark-group-id='") + markGroupId +"']");
    items.forEach(el => {
        if (el.contains(event.target)){
            item = el;
        }
    })
    if (items && item) {
        $('.mark-comment.active').removeClass('active');
        if (item.className.indexOf("active") === -1) {
          // if (MainData.userType === 'teacher') {
            // this.createMarkClassSelector(item);
          // }
          item.classList.add("active");
        }
    }
    if (e.target.parentElement.getAttribute('data-action') == 'remove') {
        if (markGroupId) {
            Marks.prototype.removeMark(e, markGroupId);
        }
    }
    if (e.target.parentElement.getAttribute('data-action') == 'copy') {
        Marks.prototype.copyMark(e, markGroupId)
    }
    if (e.target.parentElement.getAttribute('data-action') == 'save') {
      if (item && item.getAttribute("data-mark-temp-id")) {
        // if (!MainData.selectedClassListIds || !MainData.selectedClassListIds.length) {
        //   MainData.notify.show(MainData.langData["At least one grade must be chosen"]);
        // } else {
          Marks.prototype.createMarkRequest(body)
        // }
      } else {
        if (markGroupId) {
          // const currentClasses = $("[data-mark-group-id=" + markGroupId +"]").attr("data-mark-classes");
          // if (!MainData.selectedClassListIds || !MainData.selectedClassListIds.length) {
          //   MainData.notify.show(MainData.langData["At least one grade must be chosen"]);
          // } else if (MainData.selectedClassListIds.join(',') !== currentClasses) {
            Marks.prototype.updateMark(markGroupId, MainData.selectedClassListIds);
          // }
        }
      }
    }
    if (e.target.getAttribute('data-action') == 'close') {
        Marks.onCloseMark(isCreate);
    }
}

Marks.prototype.createMarkClassSelector = function (mark) {
    const classSelectWrapper = document.createElement("div");
    classSelectWrapper.classList.add("class-select-wrapper");
    const classSelectDropdown = new ClassSelectDropdown();
    const multipleSelect = classSelectDropdown.createMultipleSelect(true);
    classSelectWrapper.appendChild(multipleSelect);
    MainData.selectedClassListIds = [];
    if (mark) {
      mark.querySelector(".mark-tooltip").prepend(classSelectWrapper);
      let classes = mark.getAttribute("data-mark-classes");
      if (mark.getAttribute("data-mark-temp-id")) {
          classes = MainData.classList.map(el => el.classId).join(',');
      }
      if (classes) {
        classes.split(",").forEach(item => {
          MainData.selectedClassListIds.push(+item);
          const _item = multipleSelect.querySelector(`[id='${item}'`);
          if (_item) {
            _item.checked = true;
          }
        });
      }
      // classSelectDropdown.checkAllClasses();
    }
}
Marks.prototype.createMarkRequest = function(body) {
    const url = `${MainData.environment.pdfApi}mark/create`;
    const comment = document.querySelector('.ui-tooltip.input-active .comment-input')
    const data = {
        ...body,
        classIdList: [],
        creatorName: MainData.user.firstName + ' ' + MainData.user.lastName
    };
    if (comment && comment.value.length) {
        data.comment = comment.value.trim();
    }
    MainData.selectedClassListIds.map((el) => {
      data.classIdList.push(el);
    });
    let request = {};
    request.type = 'PUT';
    request.url = url;
    request.data = data;
    request.beforeSend = function (xhr) {
        Utils.setLoader();
        xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
    };
    request.success = (res) => {
        $('body').removeClass('is-marking');
        const elements = Marks.prototype.pageEl.querySelectorAll(`[data-mark-temp-id]`);
        Marks.prototype.closeUiTooltip();
        const newMark = {
            pageId: Number(data.pageId),
            userId: MainData.userId,
            classColor: data.classColor,
            groupId: res.groupId,
            marks: [
                {
                    "comment": data.comment || '',
                    "classIdList": data.classIdList,
                    "createDate": moment().utc().format(),
                    "_id": res.addedIds.marksId[0],
                    "userId": MainData.userId,
                    "bookId": MainData.bookId,
                    "pageId": Number(data.pageId),
                    "groupId": res.groupId,
                    "classColor": data.classColor,
                    "wordData": data.words[0],
                    "left": data.words[0].left,
                    "top": data.words[0].top,
                    "width": data.words[0].width,
                    "height": data.words[0].height,
                    "creatorName": MainData.user.firstName + ' ' + MainData.user.lastName,
                    "creatorUserType": MainData.userType
                }
            ]
        }
        if (elements) {
            elements.forEach( item => {
                $(item).off("click", "**");
                item.classList.remove("active");
                item.removeAttribute('data-mark-temp-id');
                item.setAttribute('data-mark-group-id', res.groupId);
                item.setAttribute('data-mark-classes', data.classIdList);
                item.setAttribute('data-mark-pageId', data.pageId);
                item.setAttribute('data-mark-comment', data.comment);
                item.setAttribute('data-mark-id', res.addedIds.marksId[0]);
                if (data.comment) {
                    item.setAttribute('data-comment', data.comment);
                    item.classList.add('mark-comment');
                }
                item.addEventListener('click', (e) => {
                  Marks.prototype.onClickMark(e, res.groupId);
                });
                const tooltip = Marks.prototype.createMarkTooltip(newMark.marks[0],false,true);
                item.append(tooltip);
            })
        }
        MainData.notify.show(MainData.langData["Highlight succeeded"], { type: "success" });
        // console.log( res );
        // console.log( data );
        // console.log( newMark );
        // console.log( MainData.marksData );
        MainData.marksData.push(newMark);
        MainData.sidebarObj.showLinks();
        Utils.removeLoader();
    }
    request.processData = true;
    $.ajax(request);
}

Marks.prototype.createInputCommentUi = function(body) {
    const inputWrapper = document.createElement("div");
    const textarea = document.createElement("textarea");
    textarea.classList.add("textarea");
    textarea.classList.add("comment-input");
    textarea.placeholder = MainData.langData["Note"];
    inputWrapper.appendChild(textarea);
    return inputWrapper;
}
Marks.prototype.createMarkCommentUi = function(body) {
    const hiddenPart = document.createElement("div");
    hiddenPart.classList.add("ui-tooltip");

    const hiddenPartHeader = document.createElement("div");
    hiddenPartHeader.classList.add("part-header");
    const iconClose = document.createElement("div");
    iconClose.classList.add("icon-x");
    //
    // const titleWrapper = document.createElement("div");
    // titleWrapper.classList.add("title-wrapper");
    //
    // const headerTitleIcon = document.createElement("div");
    // headerTitleIcon.classList.add(`header-icon`, `icon-comment`);
    //
    // const headerTitleText = document.createElement("div");
    // headerTitleText.classList.add(`title`);
    // headerTitleText.innerText = MainData.langData["Add an adjacent comment for emphasis"];

    // titleWrapper.appendChild(headerTitleIcon);
    // titleWrapper.appendChild(headerTitleText);

    hiddenPartHeader.appendChild(iconClose);
    // hiddenPartHeader.prepend(titleWrapper);

    hiddenPart.appendChild(hiddenPartHeader);

    //form

    const formWrapper = document.createElement("div");
    formWrapper.classList.add("form-wrapper");

    if (MainData.userType === "teacher") {
        const classSelectWrapper = document.createElement("div");
        classSelectWrapper.classList.add("class-select-wrapper");

        const toggleWrapper = document.createElement("div");
        toggleWrapper.classList.add("toggle-wrapper");

        MainData.selectedClassListIds = [];
        const classSelectDropdown = new ClassSelectDropdown();
        const multipleSelect = classSelectDropdown.createMultipleSelect(true);
        classSelectWrapper.appendChild(multipleSelect);
        formWrapper.appendChild(classSelectWrapper);
    }
    const refInput = Marks.prototype.createInputCommentUi();
    formWrapper.append( refInput );

    hiddenPart.appendChild(formWrapper);

    const actions = document.createElement("div");
    // const btnDelete = document.createElement("button");
    const wrapBtnSave = document.createElement("div");
    wrapBtnSave.classList.add('btn-save');

    actions.classList.add("actions");

    const saveBtnIcon = document.createElement('i');
    saveBtnIcon.classList.add('icon2-publish');
    const saveBtnLabel = document.createElement('span');
    saveBtnLabel.innerHTML = MainData.langData['Publish'];

    wrapBtnSave.appendChild(saveBtnIcon);
    wrapBtnSave.appendChild(saveBtnLabel);

    // button -cooment
    const wrapBtnComment = document.createElement("div");
    wrapBtnComment.classList.add('btn-comment');

    const commentBtnIcon = document.createElement("div");
    commentBtnIcon.classList.add(`icon-comment`);
    const commentBtnLabel = document.createElement('span');
    commentBtnLabel.innerHTML = MainData.langData['Add comment'];

    wrapBtnComment.appendChild(commentBtnIcon);
    wrapBtnComment.appendChild(commentBtnLabel);

    wrapBtnComment.addEventListener('click', function(e){
        hiddenPart.classList.add('input-active');
    })


    // ---------- add all buttons to UI---------
    // actions.appendChild(btnDelete);
    actions.appendChild(wrapBtnComment);
    actions.appendChild(wrapBtnSave);

    hiddenPart.appendChild(actions);

    wrapBtnSave.addEventListener('click', function(e){
        Marks.prototype.removeBtnStyles();
        Marks.prototype.createMarkRequest(body);
    })

    iconClose.addEventListener('click', function(e){
        Marks.prototype.removeBtnStyles();
        Marks.onCloseMark(true);
    })

    return hiddenPart;
}

Marks.prototype.removeBtnStyles = function () {
    const colorSelectorBtn = document.querySelector(".header .color-selector-btn");
    colorSelectorBtn.classList.remove("active");
    const activeMarkColor = document.querySelector(".header .color-point.active");
    if (activeMarkColor) {
        activeMarkColor.classList.remove("active");
    }
}
Marks.prototype.closeUiTooltip = function () {
    $('.mark.active .ui-tooltip').remove();
    Marks.onDeleteMarkFromUi();
}
Marks.prototype.createMarkUi = function(body) {
    Marks.onCloseMark();
    MainData.markColor = "none";

    const elements = Marks.prototype.pageEl.querySelectorAll(`[data-mark-temp-id]`);
    // elements.forEach((el) => {
    //     el.addEventListener('click', (e) => {
    //         const id = el.getAttribute("data-mark-temp-id");
    //         Marks.prototype.onClickMark(e, id, true, body);
    //     });
    // });
    // const tooltip = Marks.prototype.createMarkTooltip(true);
    const tooltipCreateUi = this.createMarkCommentUi(body);
    elements[0].append(tooltipCreateUi);
    elements[0].classList.add("active");
    Marks.prototype.checkTooltipClass(elements[0])

}

Marks.prototype.checkTooltipClass = function(element) {
    const state = this.isOnBottom(element);
    if (state) {
        element.classList.remove('bottom');
        element.classList.add('top');
    } else {
        element.classList.remove('top');
        element.classList.add('bottom');
    }
}
Marks.prototype.isOnBottom = function(element) {
    const container = document.getElementsByClassName('conteiner-wrapper')[0];
    return element.offsetTop - container.scrollTop >= container.clientHeight / 2;
}
Marks.prototype.copyMark = async function (e, markGroupId) {
    const markElements = document.querySelectorAll(`[data-mark-group-id='${markGroupId}'`);
    const arrayMarkElements = [...markElements];
    arrayMarkElements.sort((a,b) => +(a.id.split('_')[1]) - +(b.id.split('_')[1]));
    let text = '';
    await arrayMarkElements.forEach(item => {
        text += ' ' + item.getAttribute('data-word');
    });
    Action.prototype.copyToClipboard(text);
    console.log(text);
    MainData.notify.show(MainData.langData["Text was copied"], { type: "success" });
    Marks.onCloseMark();
}

Marks.prototype.removeMarkFromSideBar = function (id) {
    const elForDeleting = document.querySelector(
      `[data-link-id-insidebar='${id}'`
    );
    if (elForDeleting) {
        elForDeleting.parentNode.removeChild(elForDeleting);
    }
    console.log(id);
    console.log([...MainData.marksData]);

    MainData.marksData = MainData.marksData.filter(el => {
        return el.groupId !== id;
    });
    console.log([...MainData.marksData]);

}
Marks.prototype.removeMark = function (e, markGroupId, id) {
    const url = `${MainData.environment.pdfApi}mark/DeleteByGroupId?groupId=${markGroupId}`;
    $(`[data-mark-group-id=${markGroupId}]`).hasClass
    const activeMark = MainData.marksData.find(el => el.groupId == markGroupId);
    let msg = MainData.langData['Are you sure you want to delete this highlight'];
    if (activeMark && activeMark.marks[0].comment) {
        msg = MainData.langData['Are you sure you want to delete this highlight with a comment'];
    }
    UiModal.openUiModal(msg, true)
      .subscribe((res) => {
        let request = {};
        request.type = 'DELETE';
        request.url = url;
        request.beforeSend = function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
        };
        request.success = (res) => {
            let activeId = id;
            if (e) {
                activeId = $(e.target).closest('[data-mark-id]').data('mark-id');
            }
            Marks.onDeleteMarkFromUi(markGroupId);
            MainData.notify.show(MainData.langData["Highlight deleted"], { type: "success" });
            if (activeId) {
                Marks.prototype.removeMarkFromSideBar(markGroupId);
                MainData.sidebarObj.showLinks();
            }
        }
        request.processData = true;
        $.ajax(request);
      })
}
Marks.prototype.updateMark = function (markGroupId, classes, comment = '') {
    const url = `${MainData.environment.pdfApi}mark/update`;
    let request = {};
    const data = {
        groupId: markGroupId,
        classIdList: classes,
        comment: comment,
        creatorName: MainData.user.firstName + ' ' + MainData.user.lastName
    }
    request.type = 'POST';
    request.url = url;
    request.data = data;
    request.beforeSend = function (xhr) {
        Utils.setLoader();
        xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
    };
    request.success = (res) => {
      MainData.selectedClassListIds = [];
      this.updateClassesMark(data, res);
      MainData.notify.show(MainData.langData["Highlight succeeded"], { type: "success" });
      Marks.onCloseMark();
      Utils.removeLoader();
    }
    request.processData = true;
    $.ajax(request);
}
Marks.prototype.updateClassesMark = function (data, res) {
    // debugger;
    MainData.marksData.forEach((page) => {
        const mark = page.marks.find((el) => el.groupId === data.groupId);
        if (mark) {
            mark.comment = data.comment;
            mark.classIdList = data.classIdList;
        }
    });
    const markRef = $("[data-mark-group-id=" + data.groupId +"]");
    if (markRef) {
        markRef.attr("data-mark-classes", data.classIdList.join(','));
        markRef.attr("data-comment", data.comment);
        markRef.find('.comment').text(data.comment);
        let assign = '';
        if (!data.classIdList || !data.classIdList.length) {
            assign = MainData.langData["Saved only for me"];
        } else {
            const assignedClasses = MainData.classList.filter((cl) => {
                return data.classIdList.find(id => id == cl.classId)
            }).map(el => el.name).join(', ').trim();
            if (assignedClasses) {
                assign = `<span>${MainData.langData["Saved to:"]}</span> ${assignedClasses}`
            }
        }
        markRef.find('.assign').html(assign);
    }
    if (res && res.results[0]) {
        const sidebarMarkRef = $("[data-link-id-insidebar=" + res.results[0] + "]");
        if (sidebarMarkRef) {
            $('.link-details-wrapper .link-title').text(data.comment);
            sidebarMarkRef.find('.name').text(data.comment);
        }
    }
}
