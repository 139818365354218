import {MainData} from "@/js/mainData";

const MultipleBookMarkUser = {
  bookId: 11446,
  userId: 2230052
}
const DevMultipleBookMarkUser = {
  bookId: 356,
  userId: 488201
}
export class Utils {
  constructor() {
  }
  static isNotDesktop() {
    var isMobile = false;
    // console.log(navigator.userAgent);
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Mobile|Touch|Opera Mini/i.test(navigator.userAgent) ) {
      isMobile = true;
    }
    // console.log(navigator.userAgent);
    // console.log('isMobile - ', isMobile);
    // console.log('ontouchstart' in window || navigator.msMaxTouchPoints);
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  }
  static isValidUrl(url){
    const pattern = "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
    return url.match(pattern);
  }
  static isProd(){
    return window.location.hostname === 'books.wizdi.school';
  }
  static isHardcodedBookmarks() {
    if(this.isProd()){
      return +MainData.bookId === MultipleBookMarkUser.bookId && +MainData.userId === MultipleBookMarkUser.userId;
    } else {
      return +MainData.bookId === DevMultipleBookMarkUser.bookId && +MainData.userId === DevMultipleBookMarkUser.userId;
    }
  }
  static setLastBookmark(){
    const localBookmarks = this.getLocalBookMarks();
    const bookmarksForCurrentBook = localBookmarks.find((el) => el.bookId === MainData.bookId);
    if (bookmarksForCurrentBook) {
      MainData.bookmark = bookmarksForCurrentBook.bookmarks.length ? bookmarksForCurrentBook.bookmarks[bookmarksForCurrentBook.bookmarks.length - 1] : null;
      console.log('Last bookmark');
      console.log(MainData.bookmark);
    }
  }
  static toggleBookMark(bookmark) {
    console.log(bookmark);
    const localBookmarks = this.getLocalBookMarks();
    const arr = new Set(localBookmarks);
    const bookmarksForCurrentBook = [...arr].find((el) => el.bookId === MainData.bookId);
    if (bookmarksForCurrentBook) {
      const isBookmarkExistInBook = bookmarksForCurrentBook.bookmarks.find((el) => el.pageNumber === bookmark.pageNumber);
      if (isBookmarkExistInBook) {
        bookmarksForCurrentBook.bookmarks = bookmarksForCurrentBook.bookmarks.filter((el) => el.pageNumber !== bookmark.pageNumber);
        this.toggleStyleForBookMark(bookmark.pageNumber, true);
      } else {
        bookmarksForCurrentBook.bookmarks.push(bookmark);
        this.toggleStyleForBookMark(bookmark.pageNumber);
      }
    } else {
      arr.add({
        bookId: MainData.bookId,
        bookmarks: [bookmark]
      })
      this.toggleStyleForBookMark(bookmark.pageNumber);
    }
    localStorage.setItem("bookMarks", JSON.stringify([...arr]));
    this.setLastBookmark();
  }
  static isRangeHasBookmark(startPage, endPage){
    const bookmarks = this.getLocalBookMarks();
    const bookmarksForCurrentBook = bookmarks.find((el) => el.bookId === MainData.bookId);
    if (bookmarksForCurrentBook) {
      return bookmarksForCurrentBook.bookmarks.some((el) => {
        return el.pageNumber >= startPage && el.pageNumber <= endPage
      })
    }
    return false;
  }
  static isPageAddedToBookmark(page){
    const bookmarks = this.getLocalBookMarks();
    const bookmarksForCurrentBook = bookmarks.find((el) => el.bookId === MainData.bookId);
    if (bookmarksForCurrentBook) {
      const isBookmarkExistInBook = bookmarksForCurrentBook.bookmarks.find((el) => el.pageNumber === page);
      if (isBookmarkExistInBook) {
        return true;
      }
    }
    return false;
  }
  static toggleStyleForBookMark(page, remove = false) {
    const bookmark = document.querySelector(`#page${page} .bookmark-wrapper`);
    if (remove) {
      bookmark.classList.remove("active");
    } else {
      bookmark.classList.add("active");
    }
  }
  static setStylesToAllBookmarks(){
    const bookmarks = this.getLocalBookMarks();
    const bookmarksForCurrentBook = bookmarks.find((el) => el.bookId === MainData.bookId);
    if (bookmarksForCurrentBook) {
      bookmarksForCurrentBook.bookmarks.forEach(el => {
        this.toggleStyleForBookMark(el.pageNumber)
      });
    }
  }

  static getLocalBookMarks(){
    return localStorage.getItem("bookMarks") ? JSON.parse(localStorage.getItem("bookMarks")) : []
  }

  static setLoader(){
    const loaderWrapper = document.querySelector('.loader-material-wrapper');
    loaderWrapper.style.display = 'flex';
  }
  static removeLoader(){
    const loaderWrapper = document.querySelector('.loader-material-wrapper');
    loaderWrapper.style.display = 'none';
  }
}
