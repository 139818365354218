import { MainData } from './mainData.js';
import { Modal } from "@/js/modal";

export class Exercises {
    constructor() {
    }

    static getExercisesTemp() {
        const tempData = {
            "YD101005C": {
                "id": "YD101005C",
                "book": "השבחה מתמטיקה לכיתה א'- מספרים ופעולות 4",
                "bookId": 355,
                "field": "מתמטיקה",
                "lesson": "חיבור עד 20",
                "page": 15745,
                "pageId": 15745,
                "question num": "5",
                "section": "ג",
                "explanationId": "expYD1007001",
                "conclusionId": "postYD1007001",
                "avgTime": 31,
                "excerciseImageSize": {
                    "width": 450,
                    "height": 122
                },
                "exerciseImagePos": {
                    "left": 0.299625468164794,
                    "top": 0.5967276227141483,
                    "height": 0.11742059672762271,
                    "width": 0.5617977528089887
                }
            }
        }
        console.log(tempData);
        return new Promise((resolve, reject) => {
            resolve(tempData)
        });
    }
    static getExercises() {
        return new Promise((resolve, reject) => {
            const url = `${MainData.environment.coreApi}api/v2/AnnBookExercise/book/${MainData.bookId}`;
            let request = {};
            request.type = 'GET';
            request.url = url;
            request.beforeSend = function (xhr) {
                xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
            };
            request.success = (res) => {
                resolve(res);
            }
            request.error = () => {
                resolve([]);
            }
            $.ajax(request);
        });
    }

    static addExercisesToBook(exercises) {
        exercises.forEach(item => {
            const page = document.querySelector(`[data-page-id="${item.pageId}"]`);
            if (page) {
                const pos = item.exerciseImagePos;
                if (pos && pos.width && pos.height && pos.top && pos.left) {
                    const el = document.createElement('div');
                    const spanTopLeft = document.createElement('span');
                    const spanTopRight = document.createElement('span');
                    const spanBottomRight = document.createElement('span');
                    const spanBottomLeft = document.createElement('span');
                    spanTopLeft.classList.add('border-top-left');
                    spanTopRight.classList.add('border-top-right');
                    spanBottomRight.classList.add('border-bottom-right');
                    spanBottomLeft.classList.add('border-bottom-left');
                    el.appendChild(spanTopLeft);
                    el.appendChild(spanTopRight);
                    el.appendChild(spanBottomRight);
                    el.appendChild(spanBottomLeft);
                    el.classList.add('exercise');
                    el.style.width = pos.width * 100 + '%';
                    el.style.height = pos.height * 100 + '%';
                    el.style.top = pos.top * 100 + '%';
                    el.style.left = pos.left * 100 + '%';
                    el.addEventListener('click', () => {
                        this.onClickExercise(item)
                    });
                    page.appendChild(el);
                }
            }
        })
    }
    static onClickExercise(item) {

        var userIdEnvPrefix = '';

        if (window.location.href.includes('test-books.wizdi.school')) {
            userIdEnvPrefix = 't-';
        }
        else if (window.location.href.includes('books.wizdi.school')) {
            userIdEnvPrefix = 'p-';
        }

        Modal.openModal(`https://ann-education-wizdi-nav.web.app/ex/${item.annId}?ctoken=${MainData.annToken}&userId=${userIdEnvPrefix}${MainData.userId}`);
    }
}
