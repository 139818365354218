export const ActivitiesTypes = [
  { name: 'storyLine', order: 0, id: 3, singleName: 'Storyline' },
  { name: 'demoVideos', order: 1, id: 9, singleName: 'Video' },
  { name: 'illustrationMeans', order: 2, id: 6, singleName: 'Illustrationmeans' },

  { name: 'yearlyPlanForLessons', order: 3, id: 13, singleName: 'Yearly plan for lessons' },
  { name: 'printableTests', order: 4, id: 14, singleName: 'Printable tests' },
  { name: 'worksheets', order: 5, id: 4, singleName: 'Worksheets' },
  { name: 'worksheetsFromABook', order: 6, id: 15, singleName: 'Worksheets from a book' },
  { name: 'foundation', order: 7, id: 16, singleName: 'Foundation' },
  { name: 'enrichment', order: 8, id: 17, singleName: 'Enrichment' },

  { name: 'games', order: 9, id: 5, singleName: 'Games' },
  { name: 'tests', order: 10, id: 8, singleName: 'Tests' },
  { name: 'menu-book', order: 11, id: 30, singleName: 'Books' },
  { name: 'audio', order: 12, id: 11, singleName: 'Audio' },
  { name: 'storyline', order: 13, id: 12, singleName: 'AnnBookExercise' },
];
