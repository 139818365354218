import {filter, Subject, take} from "rxjs";

export class UiModal {
    constructor() {
        UiModal.display = new Subject();
        UiModal.display.subscribe((state) => {
            const modal = $('#modal-text');
            if (state.event === 'open') {
                modal.addClass('active');
                setTimeout(() => {
                    modal.find('.modal-wrapper').addClass('active');
                }, 200);
            } else if (state.event === 'close' || state.event === 'confirm') {
                modal.find('.modal-wrapper').removeClass('active');
                setTimeout(() => {
                    modal.removeClass('active');
                    $('.modal-content-text').text( '' );
                }, 200);
            }
            if (state.isConfirm) {
                modal.addClass('confirm');
            } else {
                modal.removeClass('confirm');
            }
        })
        this.actions();
    }

    static openUiModal(str, isConfirm, html) {
        UiModal.display.next({event: 'open', isConfirm: isConfirm});
        if (html){
            $('.modal-content-text').html(html);
        } else {
            $('.modal-content-text').text(str);
        }
        return UiModal.display.pipe(take(1), filter((res) => res.event === 'confirm'));
    }
    static closeUiModal(event) {
        UiModal.display.next({event: event});
    }

    actions(){
        $('#modal-text').on("click", '.btn, .icon-x', function(e) {
            const event = $(e.target).data('action') ? 'confirm' : 'close';
            console.log($(e.target));
            console.log( event );
            UiModal.closeUiModal(event);
        });
    }
}
