Scrollable();

function Scrollable() {

    const toggleBtn = document.querySelector('.toggle-scrollable-btn');
    //temp before checking
    toggleBtn.style.display = 'none';
    //temp end

    let container = document.querySelector('.conteiner-wrapper');
    let down = false;
    let scrollLeftOuter = 0;
    let scrollTopOuter = 0;
    let x = 0;
    let y = 0;
    let isScrollable = false;

    toggleBtn.addEventListener('click', () => {
        isScrollable = !isScrollable;
        document.body.style.cursor = isScrollable ? 'grab' : 'default'

        toggleBtn.classList.toggle('active');
    })

    container.addEventListener('mousedown', (res) => {
        down = true;
        scrollLeftOuter = container.scrollLeft;
        scrollTopOuter = container.scrollTop;
        x = res.clientX;
        y = res.clientY;
        if (isScrollable) {
            document.body.style.cursor = 'grabbing';
        }
    })


    container.addEventListener('mouseup', (res) => {
        down = false;
        if (isScrollable) {
            document.body.style.cursor = 'grab';
        }
    })


    container.addEventListener('mousemove', (res) => {
        if (down && isScrollable) {
            container.scrollLeft = scrollLeftOuter + x - res.clientX;
            container.scrollTop = scrollTopOuter + y - res.clientY;
        }
    })
}