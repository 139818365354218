import { MainData } from './mainData.js';

export class Search {
  constructor() {
    function onSearch() {
      const results = search();
    }

    const searchBtn = document.querySelector(".icon-search");
    searchBtn.addEventListener("click", () => {
      MainData.sidebarObj.disableAllBtns();
      MainData.sidebarObj.openSidebarBlock();
      MainData.sidebarObj.openSearch();
    });
    const searchInput = document.querySelector("#search-input");
    searchInput.placeholder = MainData.langData["Search"];
    searchInput.addEventListener("keyup", e => {
      if (e.key == "Enter") {
        search();
      }
    });

    const searchIconInput = document.querySelector(".icon-search-input");

    searchIconInput.addEventListener("click", () => {
      search();
    });

    const sidebar = document.querySelector(".sidebar");
    const closeBtn = document.querySelector(".search-wrapper-block .icon-x");
    closeBtn.addEventListener("click", () => {
      close();
    });
    close = function() {
      disableAllBtns();
      const el = document.querySelector(".sidebar-block");
      const gutter = document.querySelector(".gutter");
      el.style.display = "none";
      gutter.style.display = "none";
      const containerWrapper = document.querySelector(".conteiner-wrapper");
      containerWrapper.style.width = "100%";
      containerWrapper.style.maxWidth = "100%";
      containerWrapper.style.minWidth = "100%";
      let prevSearch = $(".mark.search");
      // if (prevSearch.parentNode) {
      //     prevSearch.parentNode.removeChild(prevSearch);
      // }
      prevSearch.remove();
      searchInput.value = "";
      let resArr = [];
      resArr = prepareResults(resArr);
      createSubjectList(resArr);
    };

    function search() {
      let prevSearch = $(".mark.search");
      prevSearch.remove();
      let value = searchInput.value;
      if (!value) return;
      value = stripVowels(value);
      let resArr = [];
      const length = MainData.coordsData.length;
      let word;
      for (let i = 0; i < length; i++) {
        if (MainData.coordsData[i].data) {
          for (let j = 0; j < MainData.coordsData[i].data.length; j++) {
            if (MainData.coordsData[i].data[j]) {
              word = MainData.coordsData[i].data[j].word;
            }
            if (!word) continue;
            value = value.split(" ").join("");
            word = word.split(" ").join("");
            // || (value.includes(stripVowels(word).replace(/[, ]+/g, "").trim()))
            if (stripVowels(word.replace(/[, ]+/g, "").trim()).includes(value)) {
              resArr.push({
                wordObj: MainData.coordsData[i].data[j],
                page: MainData.coordsData[i].pageNumber
              });
            }
          }
        }
      }
      resArr = prepareResults(resArr);
      createSubjectList(resArr);
    }

    function prepareResults(result) {
      let arr = [];
      for (let res of result) {
        const subj = getSubject(res.page);
        const subjName = subj.name;
        const obj = arr.find(item => item.name == subjName);
        if (obj) {
          obj.data.push(res);
        } else {
          arr.push({
            name: subjName,
            index: subj.index,
            prefixName: subj.prefixName,
            data: [res]
          });
        }
      }
      return arr;
    }

    function getSubject(page) {
      for (let chapter of MainData.bookChapters) {
        for (let sub of chapter.subjects) {
          if (page >= sub.startPage && sub.endPage >= page) {
            sub.index = chapter.subjects.indexOf(sub);
            return sub;
          }
        }
      }
      const subNone = { name: "", index: 0 };
      return subNone;
    }

    function stripVowels(rawString) {
      var newString = "";
      for (let j = 0; j < rawString.length; j++) {
        if (rawString.charCodeAt(j) < 1425 || rawString.charCodeAt(j) > 1479) {
          newString = newString + rawString.charAt(j);
        }
      }
      return newString;
    }

    function disableAllBtns() {
      for (let child of sidebar.children) {
        child.classList.remove("active");
      }

      const sidebarBlock = document.querySelector(".sidebar-block");
      const gutter = document.querySelector(".gutter");

      for (let child of sidebarBlock.children) {
        child.style.display = "none";
      }
      sidebarBlock.style.display = "none";
      gutter.style.display = "none";
      const containerWrapper = document.querySelector(".conteiner-wrapper");
      containerWrapper.style.width = "50%";
    }

    function createSubjectList(array) {
      const searchResult = document.querySelector(".search-result");
      searchResult.innerHTML = "";

      const searchResultAmount = document.createElement("div");
      searchResultAmount.classList.add("search-result-amount");

      let resultAmount = 0;
      for (let i = 0; i < array.length; i++) {
        resultAmount += array[i].data.length;
      }

      searchResultAmount.innerText = `${resultAmount} ${MainData.langData["Results"]}`;

      searchResult.appendChild(searchResultAmount);

      const subjectsList = document.createElement("div");
      subjectsList.classList.add("subjects-list");

      //subject-wrapper
      for (let i = 0; i < array.length; i++) {
        // if (!array[i].name) continue;

        const subjectItem = document.createElement("div");
        subjectItem.classList.add("subject-item");

        const subjectWrapper = document.createElement("div");
        subjectWrapper.classList.add("subject-wrapper");

        const subjectTitle = document.createElement("div");
        subjectTitle.classList.add("subject-title");
        subjectTitle.innerHTML = `<span> ${
          array[i].prefixName && array[i].name
            ? array[i].prefixName
            : array[i].name
            ? MainData.langData["Subject"]
            : MainData.langData["No subject"]
        } : </span>${array[i].name}`;

        subjectWrapper.appendChild(subjectTitle);
        subjectItem.appendChild(subjectWrapper);
        //page-wrapper

        //todo: in loop

        let currentPageNum;
        let commonPageWrapper;

        for (let j = 0; j < array[i].data.length; j++) {
          let textWrapper = document.createElement("div");
          textWrapper.classList.add("text-wrapper");
          const str = getStringWithFoundWord(array[i].data[j], j);
          textWrapper.innerHTML = `${str}`;

          let pageWrapper;

          if (currentPageNum != array[i].data[j].page) {
            pageWrapper = document.createElement("div");
            pageWrapper.classList.add("page-wrapper");

            const pageTitle = document.createElement("div");
            pageTitle.classList.add("title");
            pageTitle.innerText = `${MainData.langData["P."]} ${array[i].data[j].page}`;

            pageWrapper.appendChild(pageTitle);
            pageWrapper.appendChild(textWrapper);

            commonPageWrapper = pageWrapper;

            subjectItem.appendChild(pageWrapper);

            pageWrapper.addEventListener("click", () => {
              MainData.navbarObj.changePage(array[i].data[j].page + 1);
            });

            currentPageNum = array[i].data[j].page;
          } else {
            commonPageWrapper.appendChild(textWrapper);
          }
          subjectsList.appendChild(subjectItem);

          const pageObj = array[i].data[j];
          const el = addSearchMark(
            pageObj.wordObj.xPercent,
            pageObj.wordObj.yPercent,
            pageObj.wordObj.wPercent,
            pageObj.wordObj.hPercent,
            pageObj.wordObj.max_yPercent,
            pageObj.wordObj.max_hPercent,
            pageObj.wordObj.index,
            pageObj.wordObj.word,
            true
          );
          $(`.main-conteiner #page${pageObj.page + 1}`).append(el);
        }
        //final build
        searchResult.appendChild(searchResultAmount);
        searchResult.appendChild(subjectsList);
      }
    }

    function getStringWithFoundWord(pageObj) {
      const wordObj = pageObj.wordObj;
      const currentPage = MainData.coordsData.find(
        item => item.pageNumber == pageObj.page
      );

      const wordObjIndex = currentPage.data.indexOf(wordObj);
      const startInd = wordObjIndex - 7 < 0 ? 0 : wordObjIndex - 7;
      const endInd = wordObjIndex + 7;
      let str = "";
      let previosAddedWord;
      for (let i = startInd; i <= endInd; i++) {
        if (!currentPage.data[i]) return str;

        const currentWordStart = +currentPage.data[i].x + +currentPage.data[i].w;
        const checkByH =
          +currentPage.data[i].h < wordObj.h * 1.3 &&
          +currentPage.data[i].h > wordObj.h * 0.7;
        const checkByY =
          Math.abs(+currentPage.data[i].y - wordObj.y) < wordObj.h * 1.2;

        if (checkByH && checkByY) {
          const checkNeighborByX = previosAddedWord
            ? Math.abs(
                (previosAddedWord.x - currentWordStart) / previosAddedWord.x
              ) < 0.1
            : true;
          const checkNeighborByY = previosAddedWord
            ? Math.abs(
                (currentPage.data[i].y - previosAddedWord.y) /
                  currentPage.data[i].y
              ) < 0.05
            : true;

          if (i < wordObjIndex) {
            if (checkNeighborByX && checkNeighborByY) {
              str += currentPage.data[i].word;
            } else {
              str = currentPage.data[i].word;
            }
          }
          if (i == wordObjIndex) {
            if (checkNeighborByX && checkNeighborByY) {
              str += `<span>${currentPage.data[i].word}</span>`;
            } else {
              str = `<span>${currentPage.data[i].word}</span>`;
            }
          }
          if (i > wordObjIndex && checkNeighborByX && checkNeighborByY) {
            str += currentPage.data[i].word;
          }
          if (
            (i > wordObjIndex && !checkNeighborByX) ||
            (i > wordObjIndex && !checkNeighborByY)
          ) {
            return str;
          }

          str += " ";
          previosAddedWord = currentPage.data[i];
        }
      }

      return str;
    }

    function addSearchMark(x, y, w, h, my, mh, index, word = "", search = false) {
      var el = $("<div></div>");
      el.addClass("mark");
      // el.append(tooltip);
      if (search) {
        el.addClass("search");
      }
      el.attr("id", "id_" + index);
      el.attr("data-word", word);
      el.css({
        width: w * 100 + "%",
        height: mh * 100 + "%",
        left: x * 100 + "%",
        top: (my - mh * 0.01) * 100 + "%"
      });
      return el;
    }
  }
}
