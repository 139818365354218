import { Action } from './actions.js';
import { MainData } from './mainData.js';
import { ClassSelectDropdown } from "@/js/class-select-dropdown";
import { Utils } from "@/js/utils";
import { Header } from "@/js/header";
import { DragUiItem } from "@/js/drag-ui-item";

const CommentsLinksClassName = "comment-link";
export class CommentsLinks {
  constructor(windowDATA) {
    console.log("CommentsLinks - inicialized");
    this.init(windowDATA);
  }
  init(windowDATA) {
    CommentsLinks.prototype.windowDATA = windowDATA;
    CommentsLinks.prototype.setMouseEvents();
    this.eventHeaderBtns();
  }
  eventHeaderBtns(){
    const btnSelectComment = $(".header .btn-select-comment")
    const btnSelectLink = $(".header .btn-select-link");
    console.log(btnSelectLink);
    if (btnSelectLink) {
      btnSelectLink.on("click",  function() {
        CommentsLinks.closeActiveUi('comment');
        console.log(btnSelectLink.find('.ui-color-point.active').length);
        if (!btnSelectLink.find('.ui-color-point.active').length) {
          CommentsLinks.toggleMainBtn('link');
        }
        CommentsLinks.toggleColorSelector('link');
      });
      btnSelectLink.find('.ui-color-point').on("click",  function(event) {
        event.stopPropagation();
        if ($(this).hasClass('active')){
          CommentsLinks.removeAllColorPoint();
        } else {
          CommentsLinks.removeAllColorPoint();
          $(this).toggleClass('active');
        }
        CommentsLinks.toggleColorSelector('link');
        if (!$('.ui-color-point.active').length) {
          CommentsLinks.toggleMainBtn('link');
        }
        CommentsLinks.start('link', $(this).data('color'));
      });
    } else {
      console.log('btnSelectLink is not provided');
    }
    if (btnSelectComment) {
      btnSelectComment.on("click",  function() {
        CommentsLinks.closeActiveUi('link');
        if (!btnSelectComment.find('.ui-color-point.active').length) {
          CommentsLinks.toggleMainBtn('comment');
        }
        CommentsLinks.toggleColorSelector('comment');
      });
      btnSelectComment.find('.ui-color-point').on("click",  function(event) {
        event.stopPropagation();
        if ($(this).hasClass('active')){
          CommentsLinks.removeAllColorPoint();
        } else {
          CommentsLinks.removeAllColorPoint();
          $(this).toggleClass('active');
        }
        CommentsLinks.toggleColorSelector('comment');
        if (!$('.ui-color-point.active').length) {
          CommentsLinks.toggleMainBtn('comment');
        }
        CommentsLinks.start('comment', $(this).data('color'));
      });
    } else {
      console.log('btnSelectComment is not provided');
    }
  }
  static removeAllColorPoint() {
    CommentsLinks.prototype.activeColor = ''
    $('.ui-color-point').removeClass('active');
  }
  static toggleMainBtn(type) {
    const btnSelect = $(".header .btn-select-" + type);
    btnSelect.toggleClass('active');
    if (!btnSelect.hasClass('active')){
      btnSelect.find('.ui-color-point').removeClass('active');
    }
  }
  static toggleColorSelector(type) {
    const btnSelect = $(".header .btn-select-" + type);
    btnSelect.find('.ui-color-selector').toggleClass('open');
  }
  static removeActiveBtn(type){
    if (type === 'comment') {
      const btnSelectComment = document.querySelector(".header .btn-select-comment");
      btnSelectComment.classList.remove('active');
    } else {
      const btnSelectLink = document.querySelector(".header .btn-select-link");
      btnSelectLink.classList.remove('active');
    }
  }
  static closeActiveUi(type){
    const btnSelect = $(".header .btn-select-" + type);
    this.toggleIsActive(type);
    this.removeActiveBtn(type);
    DragUiItem.prototype.closeDragElem();
    CommentsLinks.prototype.activeColor = null;
    btnSelect.find('.ui-color-point').removeClass('active');
    btnSelect.find('.ui-color-selector').removeClass('open');
  }
  static start(type, color = ''){
    // if ($('.ui-color-selector.open')) {
    //   $('.ui-color-selector.open').removeClass('open');
    // }
    CommentsLinks.prototype.activeColor = color;
    this.removeActiveBtn(type === 'comment' ? 'link' : 'comment');
    DragUiItem.prototype.closeDragElem();
    CommentsLinks.prototype.type = type;
    // this.toggleBtnClass(type);
    this.closeHeaderMarkButtons();
    console.log('started');
    this.toggleIsActive(type);
  }
  static toggleBtnClass(type) {
    const btn = document.querySelector(`.header .btn-select-${type}`);
    if (btn) {
      if (btn.classList.contains('active')) {
        btn.classList.remove('active');
      } else {
        btn.classList.add('active');
        this.closeHeaderMarkButtons();
      }
      CommentsLinks.prototype.isActive = Boolean(btn.classList.contains('active'));
    }
  }
  static toggleIsActive(type) {
    const btn = document.querySelector(`.header .btn-select-${type}`);
    CommentsLinks.prototype.isActive = Boolean(btn.classList.contains('active'));
  }

  static closeCommentsLinkUi(isSaved = false){
    this.closeLinkCommentButtons();
    DragUiItem.prototype.closeDragElem(isSaved);
  }
  static closeLinkCommentButtons(){
    const btns = $(`.header .btn-select-comment.active, .header .btn-select-link.active`);
    btns.removeClass('active');
    CommentsLinks.prototype.isActive = false;
    $('.ui-color-selector').removeClass('open');
    CommentsLinks.removeAllColorPoint();
  }
  static closeHeaderMarkButtons(){
    const colorSelectorBtn = document.querySelector(".header .color-selector-btn");
    const colorSelectorWrapper = document.querySelector(".header .color-selector");
    const colorPoint = document.querySelector(".header .color-point.active");
    colorSelectorBtn.classList.remove("active");
    if (colorPoint) {
      colorPoint.classList.remove("active");
    }
    colorSelectorWrapper.classList.remove("open");
    MainData.markColor = "none";
  }
  static onCloseItem(isCreate = false) {
    CommentsLinks.prototype.isActive = false;
    // const layer = document.querySelector('.ui-layer.active');
    // if (layer) {
    //   const classSelector = layer.querySelector(".class-select-wrapper");
    //   layer.classList.remove("active");
    //   if (classSelector) {
    //     MainData.selectedClassListIds = [];
    //     classSelector.remove();
    //   }
    //   if (isCreate){
    //     this.onDeleteItemFromUi(layer.getAttribute("data-layer-temp-id"), isCreate);
    //   }
    // }
  };
  static onDeleteItemFromUi(id, isCreate = false){
    MainData.selectedClassListIds = [];
    const wrapper = document.querySelector(".conteiner-wrapper");
    wrapper.classList.remove("no-horizontal-scroll");
    const layerElements = document.querySelectorAll((isCreate ? "[data-layer-temp-id='" : "[data-layer-group-id='") + id +"']");
    layerElements.forEach(item => {
      item.parentNode.removeChild(item);
    });
  }
}
CommentsLinks.prototype.activeColor = "";

// SelectingWords.prototype.initWordsForLayers = function() {
//   preparing();
//
//   function preparing() {
//     MainData.coordsData.forEach(page => {
//       const pageEl = document.querySelector(
//         `[data-page-id="${page.pageId}"]`
//       );
//       if (pageEl && page.data && page.data.length) {
//         page.data.forEach(word => {
//           if (word) {
//             const wordInDB = SelectingWords.prototype.createElWord(word, page);
//             pageEl.appendChild(wordInDB);
//           }
//         });
//       }
//     });
//   }
// }
//
// SelectingWords.prototype.createElWord = function(word) {
//   let wordWrapper = document.createElement("div");
//   wordWrapper.classList.add("word-wrapper");
//   wordWrapper.style.width = word.wPercent * 105 + 0.5 + "%";
//   wordWrapper.style.height = word.max_hPercent * 120 + "%";
//   wordWrapper.style.top = (word.yPercent) * 100 - 0.5 + "%";
//   wordWrapper.style.left = word.xPercent * 100 - 0.5 + "%";
//   wordWrapper.style.background = 'transparent';
//   wordWrapper.style.opacity = 0;
//   wordWrapper.style.userSelect = 'none';
//   if (word.index >= 0) {
//     wordWrapper.setAttribute("word-index", word.index);
//   }
//   wordWrapper.innerText = word.word;
//   wordWrapper.style.fontSize = "calc((200vh - 145px)/2 *" + word.max_hPercent + ")";
//   return wordWrapper;
// }

// SelectingWords.prototype.addLayersOnPages = function() {
//   if(MainData.layersData && MainData.layersData.length > 0) {
//     for (let layer of MainData.layersData) {
//       SelectingWords.prototype.pageEl = document.querySelector(`[data-page-id="${layer.pageId}"]`);
//       const colorLayer = layer.classColor;
//       const layerGroupId = layer.groupId;
//       if (SelectingWords.prototype.pageEl) {
//         layer.layers.forEach(word => {
//           var el = document.createElement('div');
//           var tooltip = SelectingWords.prototype.createLayerTooltip(false, layer.userId === MainData.userId);
//           el.classList.add('layer');
//           el.classList.add(`layer-${colorLayer}`);
//           el.appendChild(tooltip);
//           el.setAttribute('data-layer-pageId', layer.pageId);
//           el.setAttribute('data-layer-classes', word.classIdList || '');
//           el.setAttribute('data-color', colorLayer);
//           el.setAttribute('data-layer-group-id', layerGroupId);
//           el.setAttribute('data-word', word.wordData);
//           el.style.width = word.width * 100 + '%';
//           el.style.height = word.height * 100 + '%';
//           el.style.top = word.top * 100 + '%';
//           el.style.left = word.left * 100 + '%';
//           el.querySelector(".layer-tooltip");
//           el.addEventListener('click', (e) => {
//             SelectingWords.prototype.onClickLayer(e, layerGroupId)
//           });
//           SelectingWords.prototype.pageEl.appendChild(el);
//         });
//       }
//     }
//   }
// }

CommentsLinks.prototype.setMouseEvents = function() {
  if (Utils.isNotDesktop()) {
    document.addEventListener('touchstart', function (e) {
      /*if (document.querySelector('.layer.active')) {
          return false;
      }*/
      console.log('touchstart');
      CommentsLinks.prototype.check = false;
      CommentsLinks.prototype.moveSelectedStrings = [];
      CommentsLinks.prototype.layerWordsIndexes = [];
      CommentsLinks.prototype.currentMoveString = {};
      CommentsLinks.prototype.calcLayerGroupId = null;
      CommentsLinks.prototype.firstWordMove = null;
      if (CommentsLinks.prototype.isActive) {
        CommentsLinks.prototype.check = true;
        CommentsLinks.prototype.pageNumber = CommentsLinks.prototype.findPageNumber(e.target);
        if (!CommentsLinks.prototype.pageNumber) return;
        CommentsLinks.prototype.pageEl = e.target.closest('.page');
        if (CommentsLinks.prototype.windowDATA[CommentsLinks.prototype.pageNumber].data) {
          this.word = CommentsLinks.prototype.getWord(e);
          if (this.word && this.word.word.length > 0) {
            CommentsLinks.prototype.firstWordMove = this.word;
            CommentsLinks.prototype.calcLayerGroupId = Math.round(this.word.max_hPercent * 100).toString() + Math.round(this.word.wPercent * 100).toString() + Math.round(this.word.xPercent * 100).toString() + Math.round(this.word.max_yPercent * 100).toString();
            document.addEventListener('touchmove', CommentsLinks.prototype.move);
            document.addEventListener('touchend', CommentsLinks.prototype.mouseUpEvent);
          }
        }
      }
    })
  } else {
    document.addEventListener('mousedown', function (e) {
      CommentsLinks.prototype.check = false;
      CommentsLinks.prototype.moveSelectedStrings = [];
      CommentsLinks.prototype.layerWordsIndexes = [];
      CommentsLinks.prototype.currentMoveString = {};
      CommentsLinks.prototype.calcLayerGroupId = null;
      CommentsLinks.prototype.firstWordMove = null;
      if (CommentsLinks.prototype.isActive) {
        CommentsLinks.prototype.check = true;
        CommentsLinks.prototype.pageNumber = CommentsLinks.prototype.findPageNumber(e.target);
        if (!CommentsLinks.prototype.pageNumber) return;
        CommentsLinks.prototype.pageEl = e.target.closest('.page');
        if (CommentsLinks.prototype.windowDATA[CommentsLinks.prototype.pageNumber].data) {
          this.word = CommentsLinks.prototype.getWord(e);
          if (this.word && this.word.word.length > 0) {
            CommentsLinks.prototype.firstWordMove = this.word;
            CommentsLinks.prototype.calcLayerGroupId = Math.round(this.word.max_hPercent * 100).toString() + Math.round(this.word.wPercent * 100).toString() + Math.round(this.word.xPercent * 100).toString() + Math.round(this.word.max_yPercent * 100).toString();
            document.addEventListener('mousemove', CommentsLinks.prototype.move);
            document.addEventListener('mouseup', CommentsLinks.prototype.mouseUpEvent);
          }
        }
      }
    })
  }
}

CommentsLinks.prototype.layer = function(isSameString) {
  if (isSameString) {
    CommentsLinks.prototype.currentMoveStringEl.style.left = CommentsLinks.prototype.currentMoveString.left * 100 + '%';
    CommentsLinks.prototype.currentMoveStringEl.style.width = CommentsLinks.prototype.currentMoveString.width * 100 + '%';
    CommentsLinks.prototype.currentMoveStringEl.setAttribute('data-word', CommentsLinks.prototype.getWordDataString(CommentsLinks.prototype.currentMoveString.wordDataObj));
  } else {
    let el = document.createElement('div');
    el.classList.add('ui-layer');
    el.classList.add(`layer-${CommentsLinks.prototype.activeColor}`);
    el.classList.add(`layer-${CommentsLinksClassName}`);
    el.setAttribute('data-layer-temp-id', CommentsLinks.prototype.calcLayerGroupId);
    el.setAttribute('data-color', CommentsLinks.prototype.activeColor);
    el.setAttribute('data-word', CommentsLinks.prototype.getWordDataString(CommentsLinks.prototype.currentMoveString.wordDataObj));
    el.style.width = CommentsLinks.prototype.currentMoveString.width * 100 + '%';
    el.style.height = CommentsLinks.prototype.currentMoveString.height * 100 + '%';
    el.style.left = CommentsLinks.prototype.currentMoveString.left * 100 + '%';
    el.style.top = CommentsLinks.prototype.currentMoveString.top * 100 + '%';

    const allTempLayers = document.querySelectorAll(`[data-layer-temp-id]`);
    const lastTempLayer = [...allTempLayers].pop();
    const firstTempLayer = [...allTempLayers].shift();
    if (!firstTempLayer) {
      let firstWordEl = CommentsLinks.prototype.pageEl.querySelector('.word-wrapper');
      CommentsLinks.prototype.pageEl.insertBefore(el, firstWordEl);
    }
    if (firstTempLayer && parseInt(el.style.top) > parseInt(firstTempLayer.style.top)) {
      lastTempLayer.after(el);
    }
    if (firstTempLayer && parseInt(el.style.top) < parseInt(firstTempLayer.style.top)) {
      CommentsLinks.prototype.pageEl.insertBefore(el, firstTempLayer);
    }
    CommentsLinks.prototype.currentMoveStringEl = el;
  }
}

CommentsLinks.prototype.unLayer = function(isSameString) {
  if (isSameString && CommentsLinks.prototype.currentMoveStringEl) {
    CommentsLinks.prototype.currentMoveStringEl.style.left = CommentsLinks.prototype.currentMoveString.left * 100 + '%';
    CommentsLinks.prototype.currentMoveStringEl.style.width = CommentsLinks.prototype.currentMoveString.width * 100 + '%';
    CommentsLinks.prototype.currentMoveStringEl.setAttribute('data-word', CommentsLinks.prototype.getWordDataString(CommentsLinks.prototype.currentMoveString.wordDataObj));
  }
}

CommentsLinks.prototype.getWordDataString = function(wordDataObj) {
  let wordDataArr = [];
  wordDataObj.forEach(obj => {
    wordDataArr.push(obj.word)
  });
  return wordDataArr.join(' ');
}

CommentsLinks.prototype.move = function (e) {
  const wrap = document.querySelector('.conteiner-wrapper');
  wrap.classList.add('no-scroll');
  if (CommentsLinks.prototype.check) {
    let wordMove = CommentsLinks.prototype.getWord(e);
    if (wordMove && wordMove.word.length > 0) {
      const pageWordData = CommentsLinks.prototype.windowDATA[CommentsLinks.prototype.pageNumber].data;
      const firstWordMoveIndex = CommentsLinks.prototype.firstWordMove?.index;
      let lastWordIndex;
      if (firstWordMoveIndex < wordMove.index) {
        lastWordIndex = Math.max(...CommentsLinks.prototype.layerWordsIndexes);
      }
      if (firstWordMoveIndex > wordMove.index) {
        lastWordIndex = Math.min(...CommentsLinks.prototype.layerWordsIndexes);
      }
      if (firstWordMoveIndex === wordMove.index && CommentsLinks.prototype.layerWordsIndexes.length) {
        if (CommentsLinks.prototype.layerWordsIndexes.indexOf(firstWordMoveIndex) === 0) {
          lastWordIndex = Math.max(...CommentsLinks.prototype.layerWordsIndexes);
        } else {
          lastWordIndex = Math.min(...CommentsLinks.prototype.layerWordsIndexes);
        }
      }

      if (wordMove.index !== firstWordMoveIndex) {
        if (wordMove.index > lastWordIndex && wordMove.index > firstWordMoveIndex
          || wordMove.index < lastWordIndex && wordMove.index < firstWordMoveIndex) {
          const minIndex = Math.min(...CommentsLinks.prototype.layerWordsIndexes);
          const maxIndex = Math.max(...CommentsLinks.prototype.layerWordsIndexes);
          if (wordMove.index > firstWordMoveIndex && minIndex < firstWordMoveIndex
            || wordMove.index < firstWordMoveIndex && maxIndex > firstWordMoveIndex) {
            const promise = new Promise((resolve, reject) => {
              CommentsLinks.prototype.removeLayersBeforeAdding(pageWordData, wordMove, firstWordMoveIndex);
              resolve();
            })
            promise.finally(CommentsLinks.prototype.prepareForAddingToLayersArr(pageWordData, wordMove, lastWordIndex));
          } else {
            CommentsLinks.prototype.prepareForAddingToLayersArr(pageWordData, wordMove, lastWordIndex);
          }

        }
        if (wordMove.index < lastWordIndex && wordMove.index > firstWordMoveIndex
          || wordMove.index > lastWordIndex && wordMove.index < firstWordMoveIndex) {
          CommentsLinks.prototype.prepareForRemovingFromLayersArr(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex);
        }
      } else {
        if (CommentsLinks.prototype.layerWordsIndexes.length > 1) {
          CommentsLinks.prototype.prepareForRemovingFromLayersArr(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex);
        } else {
          CommentsLinks.prototype.addWordToLayersArray(wordMove);
        }
      }
    }
  }
}

CommentsLinks.prototype.removeLayersBeforeAdding = function(pageWordData, wordMove, firstWordMoveIndex) {
  let lastWordIndexForRemoving;
  if (firstWordMoveIndex < wordMove.index) {
    lastWordIndexForRemoving = Math.min(...CommentsLinks.prototype.layerWordsIndexes);
  }
  if (firstWordMoveIndex > wordMove.index) {
    lastWordIndexForRemoving = Math.max(...CommentsLinks.prototype.layerWordsIndexes);
  }
  CommentsLinks.prototype.prepareForRemovingFromLayersArr(pageWordData, CommentsLinks.prototype.firstWordMove, lastWordIndexForRemoving, firstWordMoveIndex);
}

CommentsLinks.prototype.prepareForAddingToLayersArr = function(pageWordData, wordMove, lastWordIndex) {
  let wordsForAdding;
  if (wordMove.index > lastWordIndex) {
    wordsForAdding = pageWordData.slice(lastWordIndex, wordMove.index + 1);
  } else {
    wordsForAdding = pageWordData.slice(wordMove.index, lastWordIndex + 1).reverse();
  }
  if (wordsForAdding && wordsForAdding.length) {
    const isLayerExist = CommentsLinks.prototype.checkIfWordLayered(wordsForAdding);
    if (!isLayerExist) {
      wordsForAdding.forEach(wordData => {
        CommentsLinks.prototype.addWordToLayersArray(wordData, lastWordIndex);
      })
    } else {
      Action.prototype.showUserToast("You can't select over old one");
    }
  }
}

CommentsLinks.prototype.checkIfWordLayered = function(wordsForAdding) {
  let isLayerExist = false;
  wordsForAdding.forEach(wordData => {
    const allCreatedLayers = [...CommentsLinks.prototype.pageEl.querySelectorAll('[data-layer-group-id]')];
    allCreatedLayers.forEach(layer => {
      const layerY = parseInt(layer.style.top)/100;
      const layerX = parseInt(layer.style.left)/100;
      const layerW = parseInt(layer.style.width)/100;
      const layerH = parseInt(layer.style.height)/100;
      if (wordData['max_yPercent'] > layerY ) {
        if (wordData['max_yPercent'] < (layerY + layerH)) {
          if (wordData['xPercent'] > layerX) {
            if (wordData['xPercent'] < (layerX + layerW)) {
              return isLayerExist = true;
            }
          }
        }
      }
    })
    if (isLayerExist) return;
  })
  return isLayerExist;
}

CommentsLinks.prototype.prepareForRemovingFromLayersArr = function(pageWordData, wordMove, lastWordIndex, firstWordMoveIndex) {
  let lastWordForRemoving;
  if (wordMove.index < lastWordIndex && wordMove.index >= firstWordMoveIndex) {
    lastWordForRemoving = pageWordData.find(item => item.index === wordMove.index + 1);
  }
  if (wordMove.index > lastWordIndex && wordMove.index <= firstWordMoveIndex) {
    lastWordForRemoving = pageWordData.find(item => item.index === wordMove.index - 1);
  }
  if (wordMove.index > lastWordIndex && wordMove.index > firstWordMoveIndex) {
    lastWordForRemoving = pageWordData.find(item => item.index === firstWordMoveIndex + 1);
  }
  if (wordMove.index < lastWordIndex && wordMove.index < firstWordMoveIndex) {
    lastWordForRemoving = pageWordData.find(item => item.index === firstWordMoveIndex - 1);
  }
  if (CommentsLinks.prototype.layerWordsIndexes.length > 1) {
    CommentsLinks.prototype.removeWordsFromLayersArray(lastWordForRemoving, wordMove, lastWordIndex, firstWordMoveIndex);
  }
}

CommentsLinks.prototype.addWordToLayersArray = function(word, lastWordIndex) {
  const isExistWord = CommentsLinks.prototype.layerWordsIndexes.includes(word.index);
  if (!isExistWord) {
    const wordTop = (word.max_yPercent - word.max_hPercent * 0.15) * 100
    const stringTop = CommentsLinks.prototype.currentMoveString.top * 100;
    const isSameString = Math.abs(wordTop - stringTop) < word.max_hPercent * 100;
    if (isNaN(stringTop) || isSameString) {
      if (Object.keys(CommentsLinks.prototype.currentMoveString).length === 0) {
        addNewCurrentMoveString();
      } else {
        let isLastWordMoreWordMove;
        if (lastWordIndex) {
          isLastWordMoreWordMove = lastWordIndex > word.index;
        }
        CommentsLinks.prototype.currentMoveString.wordDataObj.push({index: word.index, word: word.word});
        CommentsLinks.prototype.currentMoveString.wordDataObj = CommentsLinks.prototype.currentMoveString.wordDataObj.sort((a, b) => a.index - b.index);
        if (!isLastWordMoreWordMove) {
          CommentsLinks.prototype.currentMoveString.width += CommentsLinks.prototype.currentMoveString.left - word.xPercent;
          CommentsLinks.prototype.currentMoveString.left = word.xPercent
        } else {
          CommentsLinks.prototype.currentMoveString.width += word.xPercent - CommentsLinks.prototype.currentMoveString.left - CommentsLinks.prototype.currentMoveString.width + word.wPercent;
        }
      }
    } else {
      const stringObj = JSON.parse(JSON.stringify(CommentsLinks.prototype.currentMoveString));
      CommentsLinks.prototype.moveSelectedStrings.push(stringObj);
      CommentsLinks.prototype.currentMoveString = {};
      addNewCurrentMoveString();
    }
    CommentsLinks.prototype.layerWordsIndexes.push(word.index);
    CommentsLinks.prototype.layerWordsIndexes = CommentsLinks.prototype.layerWordsIndexes.sort((a, b) => {return a - b});
    CommentsLinks.prototype.layer(isSameString);
  }

  function addNewCurrentMoveString() {
    CommentsLinks.prototype.currentMoveString = {
      wordDataObj: [{index: word.index, word: word.word}],
      width: word.wPercent,
      height: word.max_hPercent * 1.3,
      left: word.xPercent,
      top: word.max_yPercent - word.max_hPercent * 0.15
    }
  }
}

CommentsLinks.prototype.removeWordsFromLayersArray = function(lastWordForRemoving, wordMove, lastWordIndex) {
  const lastWordRemovingIndexInArray =  CommentsLinks.prototype.layerWordsIndexes.indexOf(lastWordForRemoving.index);
  const isLastWordMoreWordMove = lastWordIndex > wordMove.index;
  if (isLastWordMoreWordMove) {
    CommentsLinks.prototype.layerWordsIndexes = CommentsLinks.prototype.layerWordsIndexes.slice(0, lastWordRemovingIndexInArray);
  } else {
    CommentsLinks.prototype.layerWordsIndexes = CommentsLinks.prototype.layerWordsIndexes.slice(lastWordRemovingIndexInArray + 1, CommentsLinks.prototype.layerWordsIndexes.length);
  }
  const wordTop = (lastWordForRemoving.max_yPercent - lastWordForRemoving.max_hPercent * 0.15) * 100
  const stringTop = CommentsLinks.prototype.currentMoveString.top * 100;
  let isSameString = Math.abs(wordTop - stringTop) < lastWordForRemoving.max_hPercent * 100;

  if (isSameString) {
    cutIfSameString();
  } else {
    CommentsLinks.prototype.currentMoveStringEl?.remove();
    const allTempLayerEls = [...document.querySelectorAll(`[data-layer-temp-id]`)];
    CommentsLinks.prototype.currentMoveStringEl = allTempLayerEls.find(el => Math.abs(parseInt(el.style.top)/100 - wordMove.max_yPercent) < 0.03);
    const oldWordMoveString = JSON.parse(JSON.stringify(CommentsLinks.prototype.currentMoveString));
    CommentsLinks.prototype.moveSelectedStrings = CommentsLinks.prototype.moveSelectedStrings.filter(item => {
      let isStringForRemoving = false;
      const currentWordString = item.wordDataObj.find(obj => wordMove.index === obj.index);
      if (currentWordString) {
        CommentsLinks.prototype.currentMoveString = JSON.parse(JSON.stringify(item));
        isSameString = true;
        cutIfSameString();
        isStringForRemoving = true;
      }
      if (CommentsLinks.prototype.currentMoveString.top > oldWordMoveString.top && CommentsLinks.prototype.currentMoveString.top > item.top
        || CommentsLinks.prototype.currentMoveString.top < oldWordMoveString.top && CommentsLinks.prototype.currentMoveString.top < item.top) {
        isStringForRemoving = true;
        const elForRemoving = allTempLayerEls.find(el => Math.abs(parseInt(el.style.top)/100 - item.top) < 0.02);
        if (elForRemoving) {
          elForRemoving.remove();
        }
      }
      return !isStringForRemoving;
    });
  }

  function cutIfSameString() {
    const lastWordDataInString = CommentsLinks.prototype.currentMoveString.wordDataObj.find(item => item.index === lastWordForRemoving.index);
    const lastWordDataIndexInString = CommentsLinks.prototype.currentMoveString.wordDataObj.indexOf(lastWordDataInString);
    if (isLastWordMoreWordMove) {
      CommentsLinks.prototype.currentMoveString.wordDataObj = CommentsLinks.prototype.currentMoveString.wordDataObj.slice(0, lastWordDataIndexInString);
      CommentsLinks.prototype.currentMoveString.width -= wordMove.xPercent - CommentsLinks.prototype.currentMoveString.left;
      CommentsLinks.prototype.currentMoveString.left = wordMove.xPercent;
    } else {
      const lengthArr = CommentsLinks.prototype.currentMoveString.wordDataObj.length;
      CommentsLinks.prototype.currentMoveString.wordDataObj = CommentsLinks.prototype.currentMoveString.wordDataObj.slice(lastWordDataIndexInString + 1, lengthArr);
      CommentsLinks.prototype.currentMoveString.width = (wordMove.xPercent + wordMove.wPercent) - CommentsLinks.prototype.currentMoveString.left;
    }
    CommentsLinks.prototype.unLayer(isSameString);
  }
}

CommentsLinks.prototype.mouseUpEvent = function () {
  const wrap = document.querySelector('.conteiner-wrapper');
  wrap.classList.remove('no-scroll');
  CommentsLinks.prototype.check = false;
  const stringObj = JSON.parse(JSON.stringify(CommentsLinks.prototype.currentMoveString));
  if (Object.keys(stringObj).length) {
    CommentsLinks.prototype.moveSelectedStrings.push(stringObj);
    CommentsLinks.prototype.currentMoveString = {};
  }
  if (CommentsLinks.prototype.moveSelectedStrings.length == 0) {
    CommentsLinks.prototype.addWordToLayersArray(this.word);
    let body = {
      bookId: MainData.bookId,
      pageId: CommentsLinks.prototype.pageEl.getAttribute('data-page-id'),
      classColor: `${CommentsLinksClassName}`,
      words: [
        {
          indexOnPage: this.word.index,
          wordData: this.word.word,
          width: this.word.wPercent,
          height: this.word.max_hPercent,
          left: this.word.xPercent,
          top: this.word.max_yPercent - this.word.max_hPercent * 0.01
        }
      ]
    }
    // if (MainData.userType === 'teacher') {
      CommentsLinks.prototype.createItemUi(body);
    // } else {
    //   SelectingWords.prototype.createLayerRequest(body);
    // }
  } else {
    let body = {
      bookId: MainData.bookId,
      pageId: CommentsLinks.prototype.pageEl?.getAttribute('data-page-id'),
      classColor: `${CommentsLinksClassName}`,
      words: []
    }
    CommentsLinks.prototype.moveSelectedStrings = CommentsLinks.prototype.moveSelectedStrings.sort((a, b) => a.top - b.top);
    for (let k = 0; k < CommentsLinks.prototype.moveSelectedStrings.length; k++) {
      const wordDataObj = JSON.parse(JSON.stringify(CommentsLinks.prototype.moveSelectedStrings[k].wordDataObj));
      // delete SelectingWords.prototype.moveSelectedStrings[k].wordDataObj;
      CommentsLinks.prototype.moveSelectedStrings[k].wordDataObj = [];
      if (wordDataObj?.length) {
        CommentsLinks.prototype.moveSelectedStrings[k].wordData = CommentsLinks.prototype.getWordDataString(wordDataObj);
        body.words.push(CommentsLinks.prototype.moveSelectedStrings[k]);
      }
      if (k === CommentsLinks.prototype.moveSelectedStrings.length - 1 && body.words.length) {
        // if (MainData.userType === 'teacher') {
          CommentsLinks.prototype.createItemUi(body);
        // } else {
          // SelectingWords.prototype.createLayerRequest(body);
        // }
      }
    };
  }
  CommentsLinks.prototype.moveSelectedStrings = [];
  document.removeEventListener("mouseup", CommentsLinks.prototype.mouseUpEvent, false);
  document.removeEventListener("mousemove", CommentsLinks.prototype.move, false);
  document.removeEventListener('touchmove', CommentsLinks.prototype.move, false);
  document.removeEventListener('touchend', CommentsLinks.prototype.mouseUpEvent, false);
}

CommentsLinks.prototype.findPageNumber = function(el) {
  while (el.parentNode) {
    if (el.dataset && el.dataset.pageNumber)
      return el.dataset.pageNumber;
    el = el.parentNode;
  }
  return null;
}

CommentsLinks.prototype.getWord = function(e) {
  const page_cord = CommentsLinks.prototype.windowDATA[CommentsLinks.prototype.pageNumber]?.data;
  var word = false;
  const layerIdDB = e.target.getAttribute('data-layer-group-id') || e.target.getAttribute('data-layer-group-id');
  if (layerIdDB) {
    Action.prototype.showUserToast("You can't select word over old one");
    return;
  }
  CommentsLinks.prototype.pageEl = e.target.closest('.page');
  const isTargetWord = e.target.classList.contains('ui-layer') || e.target.classList.contains('word-wrapper');
  if (CommentsLinks.prototype.pageEl && isTargetWord) {
    let bounds = CommentsLinks.prototype.pageEl.getBoundingClientRect();
    let x = (e.clientX || e.changedTouches[0].clientX) - bounds.left;
    let y = (e.clientY || e.changedTouches[0].clientY) - bounds.top;
    const clickRelativeX = x/(CommentsLinks.prototype.pageEl.offsetWidth * MainData.zoomFactor);
    const clickRelativeY = y/(CommentsLinks.prototype.pageEl.offsetHeight * MainData.zoomFactor);
    for (let i = 0; i < page_cord.length; i++) {
      if (page_cord[i]['word'].length > 0) {
        if (clickRelativeY > page_cord[i]['max_yPercent']) {
          if (clickRelativeY < (page_cord[i]['max_yPercent'] + page_cord[i]['max_hPercent'])) {
            if (clickRelativeX > page_cord[i]['xPercent']) {
              if (clickRelativeX < (page_cord[i]['xPercent'] + page_cord[i]['wPercent'])) {
                word = page_cord[i];
              }
            }
          }
        }
      }
    }
  }
  return word;
}

CommentsLinks.prototype.createLayerTooltip = function (isCreate = false, isDelete) {
  const tooltipWrapper = document.createElement('div');
  tooltipWrapper.classList.add('layer-tooltip-wrapper');

  const tooltip = document.createElement('div');
  tooltip.classList.add('layer-tooltip');

  tooltipWrapper.appendChild(tooltip);

  const saveBtn = document.createElement('div');
  const removeBtn = document.createElement('div');
  const copyBtn = document.createElement('div');
  const closeBtn = document.createElement('span');

  saveBtn.setAttribute('data-action', 'save');
  removeBtn.setAttribute('data-action', 'remove');
  copyBtn.setAttribute('data-action', 'copy');
  closeBtn.setAttribute('data-action', 'close');

  closeBtn.classList.add('icon-x');

  const saveBtnIcon = document.createElement('i');
  const removeBtnIcon = document.createElement('i');
  const copyBtnIcon = document.createElement('i');

  saveBtnIcon.classList.add('icon2-publish');
  removeBtnIcon.classList.add('icon2-delete');
  copyBtnIcon.classList.add('icon2-copy');

  const saveBtnLabel = document.createElement('span');
  saveBtnLabel.innerHTML = MainData.langData['Publish'];
  const removeBtnLabel = document.createElement('span');
  removeBtnLabel.innerHTML = MainData.langData['Delete'];
  const copyBtnLabel = document.createElement('span');
  copyBtnLabel.innerHTML = MainData.langData['Copy'];

  saveBtn.appendChild(saveBtnIcon);
  removeBtn.appendChild(removeBtnIcon);
  copyBtn.appendChild(copyBtnIcon);

  saveBtn.appendChild(saveBtnLabel);
  removeBtn.appendChild(removeBtnLabel);
  copyBtn.appendChild(copyBtnLabel);

  if (!isCreate) {
    tooltip.appendChild(copyBtn);
    if (isDelete) {
      tooltip.appendChild(removeBtn);
    }
  }
  if (MainData.userType === 'teacher') {
    tooltip.appendChild(saveBtn);
  }
  tooltip.appendChild(closeBtn);

  return tooltipWrapper;

}


CommentsLinks.prototype.onClickLayer = function (e, layerGroupId, isCreate = false, body = undefined) {
  let item;
  const items = document.querySelectorAll((isCreate ? "[data-layer-temp-id='" : "[data-layer-group-id='") + layerGroupId +"']");
  items.forEach(el => {
    if (el.contains(event.target)){
      item = el;
    }
  })
  if (items && item) {
    if (item.className.indexOf("active") === -1) {
      if (MainData.userType === 'teacher') {
        this.createLayerClassSelector(item);
      }
      item.classList.add("active");
    }
  }
  if (e.target.parentElement.getAttribute('data-action') == 'remove') {
    if (layerGroupId) {
      CommentsLinks.prototype.removeLayer(e, layerGroupId);
    }
  }
  if (e.target.parentElement.getAttribute('data-action') == 'copy') {
    CommentsLinks.prototype.copyLayer(e, layerGroupId)
  }
  if (e.target.parentElement.getAttribute('data-action') == 'save') {
    if (item && item.getAttribute("data-layer-temp-id")) {
      if (!MainData.selectedClassListIds || !MainData.selectedClassListIds.length) {
        MainData.notify.show(MainData.langData["At least one grade must be chosen"]);
      } else {
        // CommentsLinks.prototype.createLayerRequest(body)
      }
    } else {
      if (layerGroupId) {
        const currentClasses = $("[data-layer-group-id=" + layerGroupId +"]").attr("data-layer-classes");
        if (!MainData.selectedClassListIds || !MainData.selectedClassListIds.length) {
          MainData.notify.show(MainData.langData["At least one grade must be chosen"]);
        } else if (MainData.selectedClassListIds.join(',') !== currentClasses) {
          CommentsLinks.prototype.updateLayer(layerGroupId, MainData.selectedClassListIds);
        }
      }
    }
  }
  if (e.target.getAttribute('data-action') == 'x') {
    CommentsLinks.onCloseItem(isCreate);
  }
}

CommentsLinks.prototype.createLayerClassSelector = function (layer) {
  const classSelectWrapper = document.createElement("div");
  classSelectWrapper.classList.add("class-select-wrapper");
  const classSelectDropdown = new ClassSelectDropdown();
  const multipleSelect = classSelectDropdown.createMultipleSelect(true);
  classSelectWrapper.appendChild(multipleSelect);
  MainData.selectedClassListIds = [];
  if (layer) {
    layer.querySelector(".layer-tooltip").prepend(classSelectWrapper);
    let classes = layer.getAttribute("data-layer-classes");
    if (layer.getAttribute("data-layer-temp-id")) {
      classes = MainData.classList.map(el => el.classId).join(',');
    }
    if (classes) {
      classes.split(",").forEach(item => {
        MainData.selectedClassListIds.push(+item);
        const _item = multipleSelect.querySelector(`[id='${item}'`);
        if (_item) {
          _item.checked = true;
        }
      });
    }
    classSelectDropdown.checkAllClasses();
  }
}
CommentsLinks.prototype.createItemUi = function(body) {
  // CommentsLinks.toggleBtnClass();
  CommentsLinks.onCloseItem();

  // console.log(body);
  const dragUiItem = new DragUiItem({type: CommentsLinks.prototype.type, word: body});
  CommentsLinks.prototype.dragUiItem = dragUiItem;
  const el = dragUiItem.getHtmlNode();
  const page = document.querySelector(`[data-page-id='${body.pageId}'`);
  if (page) {
    page.appendChild(el);
    // console.log(el);
    const btnSave = el.querySelector('.btn-save');
    if (btnSave){
      btnSave.addEventListener("click", () => {
        console.log('before saved');
        console.log(body);
        this.createObject(body);
        // dragUiItem.closeDragElem(el, true);
        // const elements = CommentsLinks.prototype.pageEl.querySelectorAll(`[data-layer-temp-id]`);
        // if (elements) {
        //   elements.forEach(item => {
        //     $(item).off("click", "**");
        //     item.classList.remove("active");
        //     item.removeAttribute('data-layer-temp-id');
        //     item.setAttribute('data-layer-group-id', '1123123');
        //     item.setAttribute('data-layer-classes', '1,2');
        //     item.setAttribute('data-layer-pageId', body.pageId);
        //     item.addEventListener('click', (e) => {
        //       CommentsLinks.prototype.onClickLayer(e, '1111');
        //       // CommentsLinks.prototype.onClickLayer(e, res.groupId);
        //     });
        //
        //     const hoveredPart = dragUiItem.createHoveredPart();
        //     el.appendChild(hoveredPart);
        //     // const tooltipOld = item.querySelector('.layer-tooltip-wrapper');
        //     // if (tooltipOld) {
        //     //   tooltipOld.remove();
        //     // }
        //   })
        // }
      });
    }
    const btnDelete = el.querySelector('.icon-x');
    if (btnDelete){
      // btnDelete.addEventListener("click", this.closeDragElem);
      // btnDelete.addEventListener("click", this.closeDragElem.bind(this, this.isSaved));


      btnDelete.addEventListener("click", () => {
        // console.log('will close after saving');
        CommentsLinks.closeCommentsLinkUi(true);
      });
    }
    if (MainData.userType === 'teacher'){
      const all = el.querySelector("#selectAll");
      Header.triggerEvent(all, "change");
    }
  }

  // const elements = CommentsLinks.prototype.pageEl.querySelectorAll(`[data-layer-temp-id]`);
  // added handler click
  // elements.forEach((el) => {
  //   el.addEventListener('click', (e) => {
  //     const id = el.getAttribute("data-layer-temp-id");
  //     CommentsLinks.prototype.onClickLayer(e, id, true, body);
  //   });
  // })

  // const tooltip = CommentsLinks.prototype.createLayerTooltip(true);
  // elements[0].append(tooltip);
  // elements[0].classList.add("active");
  // this.createLayerClassSelector(elements[0]);
}
CommentsLinks.prototype.getWrapper = function() {
  return document.querySelector('.form-comment-link');
}
CommentsLinks.prototype.getLinkLabel = function() {
  return document.querySelector('.form-comment-link').querySelector('.link-label-input');
}
CommentsLinks.prototype.getLinkUrl = function() {
  return document.querySelector('.form-comment-link').querySelector('.link-url-input');
}
CommentsLinks.prototype.getTextarea = function() {
  return document.querySelector('.form-comment-link').querySelector('textarea');
}
CommentsLinks.prototype.createObject = function(body) {
  if (!CommentsLinks.prototype.isValid(this.type)) {
    return false;
  }
  const data = {...body};
  if (this.type === "link") {
    data.text = this.getLinkLabel().value;
    data.url = this.getLinkUrl().value;
  }
  if (this.type === "comment") {
    data.text = this.getTextarea().value;
  }
  // const el = this.getWrapper();
  // el.remove();

  if (CommentsLinks.prototype.dragUiItem.isSaved) {
    // this.updateObjectRequest(false);
  } else {
    this.createLayerRequest(data);
  }
  MainData.inCreateMode = false;
  document.querySelector(".header .icon-link").classList.remove("active");
  document.querySelector(".header .icon-comment").classList.remove("active");
  // this.isSaved = true;
  CommentsLinks.prototype.dragUiItem.isSaved = true;
};

CommentsLinks.prototype.isValid = function(type) {
  if (type === "link") {
    const inputLinkUrl = this.getLinkUrl();
    // debugger;
    if(!inputLinkUrl.value || !inputLinkUrl.value.length) {
      MainData.notify.show(MainData.langData["Field link is required"]);
      return false
    }
    if(!Utils.isValidUrl(inputLinkUrl.value)) {
      MainData.notify.show(MainData.langData["URL is not valid"]);
      return false
    }
  }
  if (type === "comment" && (!this.getTextarea().value || !this.getTextarea().value.length)) {
    MainData.notify.show(MainData.langData["Field comment is required"]);
    return false
  }
  return true;
}


CommentsLinks.prototype.createLayerRequest = function(body) {
  const url = `${MainData.environment.pdfApi}layer/create`;
  const data = {
    bookId: MainData.bookId,
    pageId: CommentsLinks.prototype.pageEl.getAttribute('data-page-id'),
    ...body
  };
  data.classIdList = [];
  if (MainData.userType === 'teacher') {
    MainData.selectedClassListIds.map((el) => {
      data.classIdList.push(el);
    });
  } else {
    MainData.classList.map((el) => {
      data.classIdList.push(el.classId);
    });
  }
  let request = {};
  request.type = 'PUT';
  request.url = url;
  request.data = data;
  // console.log(body);
  console.log(data);
  CommentsLinks.closeCommentsLinkUi();
  const tempResponse = {
    "classIdList": null,
    "_id": "60890d44dec8e36e996207c2",
    "text": "הערה מתוך האדיטור-הראשי.\nחופית 28.4.",
    "bookId": 353,
    "pageId": 15462,
    "type": "comment",
    "top": 72.1208068247542,
    "left": 69.98396355776606,
    "createDate": "2021-04-28T07:22:44.183Z",
    "userId": 448145,
    "isPublic": true,
  }
  // ----------------------------------------
  // request.beforeSend = function (xhr) {
  //   xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
  // };
  // request.success = (res) => {
  //   const elements = CommentsLinks.prototype.pageEl.querySelectorAll(`[data-layer-temp-id]`);
  //   if (elements) {
  //     elements.forEach( item => {
  //       $(item).off("click", "**");
  //       item.classList.remove("active");
  //       item.removeAttribute('data-layer-temp-id');
  //       const tooltipOld = item.querySelector('.layer-tooltip-wrapper');
  //       if (tooltipOld){
  //         tooltipOld.remove();
  //       }
  //       item.setAttribute('data-layer-group-id', res.groupId);
  //       item.setAttribute('data-layer-classes', data.classIdList);
  //       item.setAttribute('data-layer-pageId', data.pageId);
  //       item.addEventListener('click', (e) => {
  //         CommentsLinks.prototype.onClickLayer(e, res.groupId);
  //       });
  //       const tooltip = CommentsLinks.prototype.createLayerTooltip(false,true);
  //       item.append(tooltip);
  //       const wrapper = document.querySelector(".conteiner-wrapper");
  //       wrapper.classList.remove("no-horizontal-scroll");
  //     })
  //   }
  //   MainData.notify.show(MainData.langData["Highlight succeeded"], { type: "success" });
  // }
  // request.processData = true;
  // $.ajax(request);
}
// SelectingWords.prototype.copyLayer = async function (e, layerGroupId) {
//   const layerElements = document.querySelectorAll(`[data-layer-group-id='${layerGroupId}'`);
//   const arrayLayerElements = [...layerElements];
//   arrayLayerElements.sort((a,b) => +(a.id.split('_')[1]) - +(b.id.split('_')[1]));
//   let text = '';
//   await arrayLayerElements.forEach(item => {
//     text += ' ' + item.getAttribute('data-word');
//   });
//   Action.prototype.copyToClipboard(text);
//   console.log(text);
//   MainData.notify.show(MainData.langData["Text was copied"], { type: "success" });
//   SelectingWords.onCloseLayer();
// }

// SelectingWords.prototype.removeLayer = function (e, layerGroupId) {
//   const url = `${MainData.environment.pdfApi}layer/DeleteByGroupId?groupId=${layerGroupId}`;
//   let request = {};
//   request.type = 'DELETE';
//   request.url = url;
//   request.beforeSend = function (xhr) {
//     xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
//   };
//   request.success = () => {
//     SelectingWords.onDeleteLayerFromUi(layerGroupId);
//     MainData.notify.show(MainData.langData["Highlight deleted"], { type: "success" });
//   }
//   request.processData = true;
//   $.ajax(request);
// }
// SelectingWords.prototype.updateLayer = function (layerGroupId, classes) {
//   const url = `${MainData.environment.pdfApi}layer/update`;
//   let request = {};
//   request.type = 'POST';
//   request.url = url;
//   request.data = {
//     groupId: layerGroupId,
//     classIdList: classes
//   };
//   request.beforeSend = function (xhr) {
//     xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
//   };
//   request.success = () => {
//     MainData.selectedClassListIds = [];
//     this.updateClassesLayer(layerGroupId, classes);
//     MainData.notify.show(MainData.langData["Highlight succeeded"], { type: "success" });
//     SelectingWords.onCloseLayer();
//   }
//   request.processData = true;
//   $.ajax(request);
// }
// SelectingWords.prototype.updateClassesLayer = function (layerGroupId, classes) {
//   $("[data-layer-group-id=" + layerGroupId +"]").attr("data-layer-classes", classes.join(','));
// }
