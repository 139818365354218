function makeScreenshot() {
    alert("dsadas");
    html2canvas(document.getElementById("page0"), { scale: 2 }).then(canvas => {
        canvas.id = "canvasID";
        $('canvas').attr('crossorigin', "anonymous")
        var main = $('body')[0]
        //while (main.firstChild) { main.removeChild(main.firstChild); }
        main.appendChild(canvas);
    });
}

document.getElementById("a-make").addEventListener('click', function () {
    document.getElementById("a-make").style.display = "none";
    makeScreenshot();
    document.getElementById("a-download").style.display = "inline";
}, false);

document.getElementById("a-download").addEventListener('click', function () {
    this.href = document.getElementById("canvasID").toDataURL();
    this.download = "canvas-image.png";
}, false);