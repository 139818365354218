export class Modal {
    constructor() {
        this.actions();
        window.addEventListener('resize', () => {
            console.log('resize');
            if ($('#default-modal.active').length) {
                console.log('asd');
                Modal.changeSizes();
            }
        })
    }
    static changeSizes() {
        const modal = $('#default-modal');
        const iframe = $('#default-modal iframe');
        const content = modal.find('.modal-content');
        iframe.css({
            'height': content.height() + "px",
            'width': content.width() + "px"
        });
    }

    static openModal(str) {
        const modal = $('#default-modal');
        const iframe = $('#default-modal iframe');
        iframe.attr('src', str);
        modal.addClass('active');
        this.changeSizes();
    }
    static closeModal() {
        const modal = $('#default-modal');
        modal.find('iframe').attr('src', '');
        modal.removeClass('active');
    }
    actions(){
        $('#default-modal').on("click", '.modal-btn-close', function(e) {
            Modal.closeModal();
        });
    }
}
