import { Common } from './common.js';
import { Action } from './actions.js';
import { MainData } from './mainData.js';

export class InfoLayer {
  constructor(data) {
    // data ~= activity
    this.layer = data;
    this.common = new Common();
    this.layer.activityType.name = this.layer.activityType.name.toLowerCase();

    this.ActivityTypeName = {
      games: "Games",
      worksheets: "Worksheets",
      illustrationmeans: "Illustrationmeans",
      lessons: "Lessons",
      storyline: "Storyline",
      tests: "Tests",
      video: "Videos",
      link: "Links",
      audio: "Audios"
    };
  }
}

InfoLayer.prototype.element = null;

InfoLayer.prototype.createElement = function() {
  //MAIN WRAPPER
  // const wrapper = document.createElement('div');
  // wrapper.classList.add('wrapper');
  // wrapper.style.top = `calc(${this.layer.top}% + 16px)`;
  // wrapper.style.left = `calc(${this.layer.left}% + 32px)`;

  //INNER WRAPPER
  const innerWrapper = document.createElement("div");
  innerWrapper.classList.add(
    "inner-wrapper",
    `activity-${this.layer.activityType.name}`
  );
  if (
    this.layer.activityType.activityTypeId === 11 &&
    this.layer.audioTypeId === 1
  ) {
    innerWrapper.classList.add("book-instruction");
  }
  innerWrapper.style.top = `calc(${this.layer.top}%)`;
  innerWrapper.style.left = `calc(${this.layer.left}%)`;

  //VISIBLE PART (circle)
  const visiblePart = document.createElement("div");
  visiblePart.classList.add("visible-part");

  const activityIcon = document.createElement("div");
  activityIcon.classList.add(
    "activity-icon",
    `icon-${this.layer.activityType.name}`
  );

  visiblePart.appendChild(activityIcon);
  innerWrapper.appendChild(visiblePart);

  //ADDITIONAL PART
  const additionalPartWrapper = document.createElement("div");
  additionalPartWrapper.classList.add("additional-part-wrapper");
  if (this.layer.left < 30 && this.layer.top >= 20) {
    additionalPartWrapper.classList.add("left");
  }
  if (this.layer.left < 30 && this.layer.top < 20) {
    additionalPartWrapper.classList.add("bottom-left");
  }
  if (this.layer.left > 70 && this.layer.top < 20) {
    additionalPartWrapper.classList.add("bottom-right");
  }
  if (this.layer.left <= 70 && this.layer.left >= 30) {
    additionalPartWrapper.classList.add("bottom-center");
  }

  const additionalPart = document.createElement("div");
  additionalPart.classList.add("additional-part");

  const info = document.createElement("div");
  info.classList.add("info");

  const activityName = document.createElement("div");
  activityName.classList.add("activity-name");
  activityName.innerText = this.layer.name;

  const infoInner = document.createElement("div");
  infoInner.classList.add("info-inner");

  const activityType = document.createElement("div");
  activityType.classList.add("activity-type");

  const typeName = document.createElement("span");
  typeName.classList.add("type-name");
  typeName.innerText = this.getActivityName();

  const activityIconSpan = document.createElement("span");
  activityIconSpan.classList.add(
    "activity-icon",
    `icon-${this.layer.activityType.name}`
  );

  activityType.appendChild(activityIconSpan);
  activityType.appendChild(typeName);

  const btnLike = document.createElement("div");
  btnLike.classList.add("btn-like", "icon-heart", "icon-primary-white");
  if (this.layer.isLike) {
    btnLike.classList.add("active");
  }

  btnLike.addEventListener("click", e => {
    e.stopPropagation();
    this.onLike(btnLike);
  });

  const imgWrapper = document.createElement("div");
  imgWrapper.classList.add("img-wrapper");
  const img = document.createElement("img");
  img.setAttribute('onError', 'this.src="../imgs/Icon_Activities.svg"');
  img.setAttribute('data-src', `${this.getImgSrc()}`);
  img.classList.add('info-layer-img');
  img.classList.add('info-layer-lazy');
  img.setAttribute('id', 'info-layer-image-' + this.layer.activityId);
  img.setAttribute('onError', 'this.src="../imgs/Icon_Activities.svg"');
  imgWrapper.appendChild(img);

  //APPENDING

  infoInner.appendChild(activityType);
  infoInner.appendChild(btnLike);

  info.appendChild(activityName);
  info.appendChild(infoInner);

  additionalPart.appendChild(info);
  additionalPart.appendChild(imgWrapper);
  additionalPartWrapper.appendChild(additionalPart);

  innerWrapper.appendChild(visiblePart);
  innerWrapper.appendChild(additionalPartWrapper);

  // wrapper.appendChild(innerWrapper);

  innerWrapper.setAttribute(
    "data-activity-page-catalog-number",
    this.layer.catalogNumber
  );

  innerWrapper.addEventListener("mouseenter", () => {
    this.onMouseIn();
  });
  innerWrapper.addEventListener("mouseleave", () => {
    this.onMouseOut();
  });
  innerWrapper.addEventListener("click", () => {
    this.onClickIconLayer(event);
  });

  this.element = innerWrapper;
  return innerWrapper;
};

InfoLayer.prototype.getActivityName = function() {
  return MainData.langData[
    this.ActivityTypeName[this.layer.activityType.name.toLowerCase()]
  ];
};

InfoLayer.prototype.onLike = function() {
  this.common.likeActivity(this.layer.catalogNumber).then(isLike => {
    this.setLikeActiveClassInBook(isLike);
    this.setLikeActiveClassInSidebar(isLike);
    this.setLikeActiveClassInActivityRow();
  });
};

InfoLayer.prototype.setLikeActiveClassInBook = function(isLike) {
  const allLayers = [
    ...document.querySelectorAll("[data-activity-page-catalog-number]")
  ];
  const activitiesForLike = allLayers.filter(
    layer =>
      parseInt(layer.getAttribute("data-activity-page-catalog-number")) ===
      parseInt(this.layer.catalogNumber)
  );
  activitiesForLike.forEach(act => {
    act.querySelector(".btn-like").classList.toggle("active");
  });
};
InfoLayer.prototype.setLikeActiveClassInSidebar = function(isLike) {
  // const btnLikeInSidebar = document.querySelector('[data-iframe-activity-catalog-number]');
  // if(btnLikeInSidebar && parseInt(btnLikeInSidebar.getAttribute('data-iframe-activity-catalog-number')) === parseInt(this.layer.catalogNumber)) {
  //     if (isLike) {
  //         btnLikeInSidebar.classList.add('active');
  //     } else {
  //         btnLikeInSidebar.classList.remove('active');
  //     }
  // }
  MainData.sidebarObj.setLikeActiveClassInSidebar(isLike);
};

InfoLayer.prototype.setLikeActiveClassInActivityRow = function() {
  const activitiesForLike = [
    ...document.querySelectorAll(
      `[data-catalog-number="${this.layer.catalogNumber}"]`
    )
  ];
  activitiesForLike.forEach(act => {
    if (act.querySelector(".btn-like")) {
      act.querySelector(".btn-like").classList.toggle("active");
    }
  });
};

InfoLayer.prototype.getImgSrc = function() {
  return MainData.environment.media + this.layer.thumbnailUri;
};

InfoLayer.prototype.onMouseIn = function() {
  const catalogNumber = this.layer.catalogNumber;
  const elementPage = this.element
    .closest("[data-page-number]")
    .getAttribute("data-page-number");
  let activitiesInSidebar = document.querySelectorAll(
    `[data-catalog-number='${catalogNumber}'`
  );
  const activityItem = $(`.exercise-item [data-catalog-number='${catalogNumber}'] .activities-item`);
  if (!activitiesInSidebar) return;
  activitiesInSidebar = [...activitiesInSidebar];
  let activityInSidebar;
  for (let i = 0; i < activitiesInSidebar.length; i++) {
    if (
      activitiesInSidebar[i].closest(`[data-page-number='${+elementPage - 1}'`)
    ) {
      activityInSidebar = activitiesInSidebar[i];
      let activityEl = activityInSidebar.querySelector(
        ".activity-card-wrapper"
      );
      if (!activityEl) {
        activityEl = activityInSidebar.querySelector(".activity-row");
      }
      if(activityEl) {
        activityEl.classList.add("active");
      }
      if (activityItem) {
        activityItem.addClass("active")
      }
      break;
    }
  }
};

InfoLayer.prototype.onMouseOut = function() {
  const catalogNumber = this.layer.catalogNumber;
  const elementPage = this.element.closest("[data-page-number]").getAttribute("data-page-number");
  let activitiesInSidebar = document.querySelectorAll(
    `[data-catalog-number='${catalogNumber}'`
  );
  const activityItem = $(`.exercise-item [data-catalog-number='${catalogNumber}'] .activities-item`);
  if (!activitiesInSidebar) return;
  activitiesInSidebar = [...activitiesInSidebar];
  let activityInSidebar;
  for (let i = 0; i < activitiesInSidebar.length; i++) {
    if (
      activitiesInSidebar[i].closest(`[data-page-number='${+elementPage - 1}'`)
    ) {
      activityInSidebar = activitiesInSidebar[i];
      let activityEl = activityInSidebar.querySelector(
        ".activity-card-wrapper"
      );
      if (!activityEl) {
        activityEl = activityInSidebar.querySelector(".activity-row");
      }
      if(activityEl) {
        activityEl.classList.remove("active");
      }
      if (activityItem) {
        activityItem.removeClass("active")
      }
      break;
    }
  }
};

InfoLayer.prototype.onClickIconLayer = function(event) {
  if (this.layer.audioTypeId === 1) {
    MainData.audioPlayer.setAudioInPlayer(this.layer);
  } else {
    const container = document.querySelector(".main-conteiner");
    if (container.classList.contains("pages-in-row")) {
      container.classList.toggle("pages-in-row");
      MainData.isRow = container.classList.contains("pages-in-row");
      let action = new Action();
      action.setHeightdisplayPairs();
    }
    const parentPage = event.target.closest(".page");
    MainData.currentPage = parseInt(parentPage.getAttribute("data-page-number"));
    MainData.navbarObj.changePage(MainData.currentPage);

    const catalogNumber = this.layer.catalogNumber;
    MainData.sidebarObj.close();
    MainData.sidebarObj.disableAllBtns();
    MainData.sidebarObj.openSidebarBlock();
    MainData.sidebarObj.openActivities();
    MainData.sidebarObj.onPlayActivity(catalogNumber);
  }
};
