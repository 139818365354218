export class Notify {
  constructor() {
    this.notify = document.querySelector("#notify");
    this.notify.querySelector(".icon-x").addEventListener("click", () => {
      this.hide()
    })
  }
}

Notify.prototype.show = function(text, options = { type: false, delay: 4000}) {
  this.notify.querySelector(".text").innerText = text;
  this.notify.classList.add("show");
  this.notify.classList.add(options['type'] ? "success" : "error");
  setTimeout(() => {
    this.hide()
  }, options['delay'] || 4000 );
};
Notify.prototype.hide = function() {
  this.notify.className = "";
  this.notify.querySelector(".text").innerText = "";
};
