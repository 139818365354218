import { Action } from './actions.js';
import { Draggable } from './draggable.js';
import { MainData } from './mainData.js';
import { Marks } from "./marks.js";
import { CommentsLinks } from "@/js/comments-links";
import { Sidebar } from "@/js/sidebar";
import { UiModal } from "@/js/ui-modal";

export class Header {
  constructor(bookName) {
    MainData.zoomFactor = 1;
    MainData.translateX = 0;
    this.ZOOM_STEP = 0.1;

    const zoomIn = document.querySelector(".zoom-in");
    const zoomOut = document.querySelector(".zoom-out");

    const searchToggleBtn = document.querySelector(".search-toggle");
    //temp before checking
    // searchToggleBtn.style.display = 'none';
    //temp end
    const togglePagesStylingBtn = document.querySelector(".page-style");

    const bookFullscreen = document.querySelector(".book-fullscreen");

    const headerTitle = document.querySelector(".header .header-title");

    const toolPrint = document.querySelector('.tool-print');
    if (toolPrint) {
      toolPrint.onclick = function () {
        const wrapperForPrint = document.querySelector('.wrapper-for-print');
        wrapperForPrint.style.display = 'block';
        const currentPage = document.querySelector(`#page${MainData.currentPage}`)
        const url = currentPage.style.backgroundImage.slice(4, -1).replace(/"/g, "");
        wrapperForPrint.style.backgroundImage = "url('" + url + "')";
        window.print();
      };
    }

    const toolClose = document.querySelector(".tool-close");
    toolClose.onclick = function() {
      backTillWizdi();
      function showLoader() {
        toolClose.style = "pointer-events: none;";
        const loaderWrapper = document.querySelector(".loader-material-wrapper");
        loaderWrapper.style.display = "flex";
      }

      function backTillWizdi() {
        if (Sidebar.prototype.ifSelectedActivity()){
          const html = `<p dir="ltr">${MainData.langData['Pay attention']}</p>
                 <p>${MainData.langData['Exiting "Create Task" will delete the task data.']}</p>
                 <p>${MainData.langData['Are you sure?']}</p>`
          UiModal.openUiModal('', true, html)
            .subscribe(() => {
              showLoader();
              // Sidebar.prototype.redirectWithData();
              Sidebar.prototype.resetSelectedItems();
              setTimeout(() => {
                if (MainData.fromBookReturnUrl) {
                  window.location.href = MainData.fromBookReturnUrl;
                } else {
                  window.location.href = MainData.environment.wizdi;
                }
              }, 300);
            })
        } else if (MainData.fromBookReturnUrl) {
          showLoader();
          window.location.href = MainData.fromBookReturnUrl;
        } else {
          showLoader();
          window.location.href = MainData.environment.wizdi;
        }
      }

      // window.location.href = MainData.environment.wizdi + 'dashboard';
    };

    const colorSelectorBtn = document.querySelector(
      ".header .color-selector-btn"
    );
    //temp before checking
    // colorSelectorBtn.classList.add('disabled');
    // colorSelectorBtn.style.display = 'none';
    //temp end
    const colorSelectorWrapper = document.querySelector(
      ".header .color-selector"
    );
    const colorSelectorPoints = document.querySelectorAll(".header .color-point");
    const wrapper = document.querySelector(".conteiner-wrapper");

    for (let item of colorSelectorPoints) {
      if (item.dataset.color == MainData.markColor) {
        item.classList.add("active");
        break;
      }
    }

    colorSelectorBtn.addEventListener("click", e => {
      Marks.onCloseMark(true);
      colorSelectorBtn.classList.toggle("active");
      colorSelectorWrapper.classList.toggle("open");
      const body = document.getElementsByTagName('body')[0];
      if (!colorSelectorBtn.classList.contains('active')) {
        colorSelectorWrapper.classList.remove("open");
        $('.color-point.active').removeClass('active');
        MainData.markColor = "none";
        body.classList.remove('is-marking');
      } else {
        body.classList.add('is-marking');
      }
      CommentsLinks.closeCommentsLinkUi();
    });

    colorSelectorWrapper.addEventListener("click", e => {
      e.stopPropagation();
      if (!e.target.dataset.color) return;
      for (let item of colorSelectorPoints) {
        if (item == e.target) {
          item.classList.toggle("active");
        } else {
          item.classList.remove("active");
        }
      }
      if (e.target.classList.contains("active")) {
        MainData.markColor = e.target.dataset.color;
      } else {
        MainData.markColor = "none";
      }
      wrapper.classList.add("no-horizontal-scroll");
      colorSelectorWrapper.classList.toggle("open");
    });

    headerTitle.innerText = `${bookName}`;

    searchToggleBtn.addEventListener("click", this.toggleScrollBar);

    zoomIn.addEventListener("click", this.zoomInFunction.bind(this));

    zoomOut.addEventListener("click", this.zoomOutFunction.bind(this));

    togglePagesStylingBtn.classList.add("disabled");
    /*togglePagesStylingBtn.addEventListener(
      "click",
      this.changePageStyling.bind(this)
    );*/

    bookFullscreen.addEventListener("click", this.onBookFullscreen);

    document.addEventListener("fullscreenchange", () => {
      MainData.isFullscreen = !MainData.isFullscreen;

      const header = document.querySelector(".header");
      const fullscreenNav = [...document.getElementsByClassName("fullscreen-nav")];
      const noFullscreenNav = [...document.getElementsByClassName("no-fullscreen-nav")];
      const bookNav = document.querySelector(".book-nav");
      if (MainData.isFullscreen) {
        header.style.display = "none";
        fullscreenNav.forEach(el => {
          el.style.display = "flex";
        });

        noFullscreenNav.forEach(el => {
          el.style.display = "none";
        });
      } else {
        header.style.display = "flex";
        fullscreenNav.forEach(el => {
          el.style.display = "none";
        });
        noFullscreenNav.forEach(el => {
          el.style.display = "flex";
        });
        MainData.zoomFactor = 1;
        MainData.translateX = 0;
        this.setBodyZoom();
      }
      setTimeout(() => {
        MainData.navbarObj.changePage(MainData.currentPage);
      }, 500);
    });

    const closeFullscreenText = document.querySelector(".fullscreen-text");
    closeFullscreenText.addEventListener("click", this.closeBookFullscreen);

    const zoomInNav = document.querySelector(".zoom-in-nav");
    zoomInNav.addEventListener("click", this.zoomInFunction.bind(this));

    const zoomOutNav = document.querySelector(".zoom-out-nav");
    zoomOutNav.addEventListener("click", this.zoomOutFunction.bind(this));

    //temp before checking
    // document.querySelector('.header .icon-comment').classList.add('disabled');
    //temp end
    document.querySelector(".header .icon-comment").addEventListener("click", () => {
      Marks.onCloseMark(true);
      CommentsLinks.closeCommentsLinkUi();
      if (MainData.inCreateMode) {
        return;
      }
      MainData.inCreateMode = true;
      document.querySelector(".header .icon-comment").classList.add("active");
      const drag = new Draggable({ type: "comment" }, false);
      const el = drag.createElement();
      document.querySelector(`#page${MainData.currentPage}`).appendChild(el);
      if (MainData.userType === 'teacher'){
        const all = el.querySelector("#selectAll");
        Header.triggerEvent(all, "change");
      }
    });
    //temp before checking
    // document.querySelector('.header .icon-link').classList.add('disabled');
    //temp end
    document.querySelector(".header .icon-link").addEventListener("click", () => {
      Marks.onCloseMark(true);
      CommentsLinks.closeCommentsLinkUi();
      if (MainData.inCreateMode) {
        return;
      }
      MainData.inCreateMode = true;
      document.querySelector(".header .icon-link").classList.add("active");
      const drag = new Draggable({ type: "link" }, false);
      const el = drag.createElement();
      document.querySelector(`#page${MainData.currentPage}`).appendChild(el);
      if (MainData.userType === 'teacher'){
        const all = el.querySelector("#selectAll");
        Header.triggerEvent(all, "change");
      }
    });
  }
  static triggerEvent(element, eventName) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }
}
/*Header.prototype.triggerEvent = function (element, eventName) {
  var event = new Event(eventName);
  element.dispatchEvent(event);
}*/
Header.prototype.toggleScrollBar = function() {
  // document.querySelector('.search-wrapper').classList.toggle('show');
};

Header.prototype.changePageStyling = function() {
  const container = document.querySelector(".main-conteiner");
  container.classList.toggle("pages-in-row");
  MainData.isRow = container.classList.contains("pages-in-row");
  Action.prototype.setHeightdisplayPairs();
  // if (MainData.isRow) {
  //     // MainData.zoomFactor = 0.5;
  //     MainData.zoomFactor = 1;
  //     this.setBodyZoom();
  //     return;
  // }
  MainData.zoomFactor = 1;
  MainData.translateX = 0;
  this.setBodyZoom();
};

Header.prototype.isDisabledTopNav = function(book, list) {
  const allowed = list?.find((el) => el.classesId === book.classesId);
  if(!allowed || !list || !list.length) {
    const icons = document.querySelectorAll(".header .icon-link, .header .icon-pen");
    icons.forEach((el) => el.classList.add("disabled"));
  }
}
Header.prototype.onBookFullscreen = function() {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

Header.prototype.closeBookFullscreen = function() {
  const elem = document.documentElement;
  if (elem.mozRequestFullScreen) {
    elem.mozExitFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitExitFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msExitFullscreen();
  } else {
    elem.exitFullscreen();
  }
};

Header.prototype.setBodyZoom = function() {
  // translateX(-50%)
  const container = document.querySelector(".main-conteiner");
  const displayPairs = container.querySelectorAll(".displayPair");
  const parentContainer = container.parentNode;
  // container.style.transition = 'transform .5s';
  if (MainData.zoomFactor >= 1) {
    container.style.transform = `scale(${MainData.zoomFactor})`;
    container.style.transformOrigin = "100% 0px";
    container.style.width = "max-content";
    container.style.maxWidth = "100%";
    displayPairs.forEach(item => {
      item.style.transform = `translate(${MainData.translateX}%, 0%)`;
      // item.style.transition = 'translate .5s';
    });
  }
  if (MainData.zoomFactor < 1) {
    const wrapper = document.querySelector(".conteiner-wrapper");
    const height = wrapper.getBoundingClientRect().height;
    container.style.transform = `scale(${MainData.zoomFactor}) `;
    // wrapper.style.maxHeight = height / MainData.zoomFactor + 'px';
    container.style.transformOrigin = "50% 0px";
    container.style.width = "100%";
  }
  if (
    parentContainer.offsetWidth < displayPairs[0].getBoundingClientRect().width
  ) {
    let marginBase;
    if (!container.classList.contains("pages-in-row")) {
      marginBase =
        (+displayPairs[0].getBoundingClientRect().width -
          parentContainer.offsetWidth) /
        2;
    } else {
      marginBase =
        (+displayPairs[0].getBoundingClientRect().width -
          parentContainer.offsetWidth) /
        3;
    }
    displayPairs.forEach(item => {
      item.style.marginRight = marginBase + "px";
    });
  } else {
    displayPairs.forEach(item => {
      item.style.marginRight = null;
    });
  }
  // setTimeout(() => {MainData.navbarObj.changePage(MainData.currentPage)}, 600);
  MainData.navbarObj.changePage(MainData.currentPage);
};

Header.prototype.zoomInFunction = function() {
  if (MainData.zoomFactor >= 2.5) {
    Action.prototype.showUserToast("You have reached the maximum page size");
    return;
  }
  MainData.zoomFactor += this.ZOOM_STEP;
  MainData.translateX += 2.5 - (MainData.zoomFactor - this.ZOOM_STEP * 4 - 1);
  this.setBodyZoom();
};

Header.prototype.zoomOutFunction = function() {
  if (MainData.zoomFactor <= 0.51) {
    const zoomToast = document.querySelector(".user-toast");
    zoomToast.innerText =
      MainData.langData["You have reached the minimum page size"];
    zoomToast.classList.add("active");
    setTimeout(() => {
      zoomToast.classList.remove("active");
    }, 3000);
    return;
  }
  MainData.zoomFactor -= this.ZOOM_STEP;
  MainData.translateX -= 2.5 - (MainData.zoomFactor - this.ZOOM_STEP * 4 - 1);
  this.setBodyZoom();
};
