import {MainData} from "@/js/mainData";
import {ClassSelectDropdown} from "@/js/class-select-dropdown";
import {Utils} from "@/js/utils";

export class DragUiItem {
  constructor(props, isSaved = false) {
    this.data = props;
    this.isSaved = isSaved;
    this.word = props.word;
  }
  getHtmlNode(){
    return this.createDragElement();
  }

  createDragElement(customClass = 'form-comment-link'){
    const mainWrapper = document.createElement("div");
    if (this.data.word) {
      mainWrapper.style.top = this.data.word.words[0].top * 100 + '%';
      mainWrapper.style.left = this.data.word.words[0].left * 100 + '%';
    }
    mainWrapper.classList.add("wrapper-additional");
    if (customClass) {
      mainWrapper.classList.add(customClass);
    }
    const hiddenPart = this.createHiddenPart();
    const visiblePart = this.createVisiblePart();
    if (this.data.item) {
      const hoveredPart = this.createHoveredPart();
      mainWrapper.appendChild(hoveredPart);
    }
    mainWrapper.appendChild(visiblePart);
    mainWrapper.appendChild(hiddenPart);
    // const btnDelete = mainWrapper.querySelector('.icon-x');
    // if (btnDelete){
    //   btnDelete.addEventListener("click", this.closeDragElem.bind(this, this.isSaved));
    // }
    this.element = mainWrapper;
    DragUiItem.prototype.mainWrapper = mainWrapper;
    return mainWrapper;
  }
  createVisiblePart(){
    const visiblePart = document.createElement("div");
    visiblePart.classList.add("visible-part");

    // visiblePart.addEventListener('click', this.openHiddenPart.bind(this))
    visiblePart.addEventListener("mouseenter", () => {
      // this.onMouseIn();
    });
    visiblePart.addEventListener("mouseleave", () => {
      // this.onMouseOut();
    });

    let flag;
    visiblePart.addEventListener("mousedown", () => {
      flag = 0;
    });
    visiblePart.addEventListener("mousemove", () => {
      flag = 1;
    });
    visiblePart.addEventListener("mouseup", () => {
      if (flag === 0) {
        // this.openLinkCommentSidebar.call(this);
      }
    });

    const iconSpan = document.createElement("span");
    iconSpan.classList.add(`icon-${this.data.type}`);
    visiblePart.appendChild(iconSpan);
    return visiblePart;
  }
  createHiddenPart() {
    const hiddenPart = document.createElement("div");
    hiddenPart.classList.add("hidden-part");

    const hiddenPartHeader = document.createElement("div");
    hiddenPartHeader.classList.add("part-header");
    const iconClose = document.createElement("div");
    iconClose.classList.add("icon-x");
    //
    const titleWrapper = document.createElement("div");
    titleWrapper.classList.add("title-wrapper");

    const headerTitleIcon = document.createElement("div");
    headerTitleIcon.classList.add(`header-icon`, `icon-${this.data.type}`);

    const headerTitleText = document.createElement("div");
    headerTitleText.classList.add(`title`);
    headerTitleText.innerText =
      this.data.type === "link" ? MainData.langData["Link"] : MainData.langData["Comment"];

    titleWrapper.appendChild(headerTitleIcon);
    titleWrapper.appendChild(headerTitleText);

    hiddenPartHeader.appendChild(iconClose);

    hiddenPart.appendChild(hiddenPartHeader);

    //form

    const formWrapper = document.createElement("div");
    formWrapper.classList.add("form-wrapper");
    formWrapper.prepend(titleWrapper);

    const inputWrapper = document.createElement("div");

    if (this.data.type === "link") {
      const inputLinkLabel = document.createElement("input");
      inputLinkLabel.type = "text";
      inputLinkLabel.className = "input-underline";
      inputLinkLabel.classList.add("link-label-input");
      inputLinkLabel.placeholder = "תיאור";

      const linkInput = document.createElement("input");
      linkInput.type = "text";
      linkInput.className = "input-underline";
      linkInput.classList.add("link-url-input");

      if (this.isSaved) {
        linkInput.value = this.data.url || this.inputLinkUrl?.value;
      }
      linkInput.placeholder = "URL";
      this.inputLinkUrl = linkInput;
      inputWrapper.appendChild(inputLinkLabel);
      inputWrapper.appendChild(linkInput);
    }
    if (this.data.type === "comment") {
      const textarea = document.createElement("textarea");
      textarea.classList.add("textarea");
      if (this.isSaved) {
        textarea.value = this.data.text || this.textarea?.value;
      }
      textarea.placeholder = MainData.langData["Note"];
      inputWrapper.appendChild(textarea);
      this.textarea = textarea;
    }

    formWrapper.appendChild(inputWrapper);

    hiddenPart.appendChild(formWrapper);
    if (MainData.userType === "teacher") {
      const classSelectWrapper = document.createElement("div");
      classSelectWrapper.classList.add("class-select-wrapper");

      const toggleWrapper = document.createElement("div");
      toggleWrapper.classList.add("toggle-wrapper");

      const classSelectDropdown = new ClassSelectDropdown();
      const multipleSelect = classSelectDropdown.createMultipleSelect();
      classSelectWrapper.appendChild(multipleSelect);
      hiddenPartHeader.appendChild(classSelectWrapper);
    }

    const actions = document.createElement("div");
    // const btnDelete = document.createElement("button");
    const wrapBtnSave = document.createElement("div");
    wrapBtnSave.classList.add('btn-save');

    actions.classList.add("actions");

    const saveBtnIcon = document.createElement('i');
    saveBtnIcon.classList.add('icon2-publish');
    const saveBtnLabel = document.createElement('span');
    saveBtnLabel.innerHTML = MainData.langData['Publish'];

    wrapBtnSave.appendChild(saveBtnIcon);
    wrapBtnSave.appendChild(saveBtnLabel);

    // actions.appendChild(btnDelete);
    actions.appendChild(wrapBtnSave);

    hiddenPart.appendChild(actions);

    return hiddenPart;
  };
  createHoveredPart() {
    const hoveredPartWrapper = document.createElement("div");
    hoveredPartWrapper.classList.add("hovered-part-wrapper");
    this.setClassForHoveredPart(
      this.data.left,
      this.data.top,
      hoveredPartWrapper
    );
    const hoveredPart = document.createElement("div");
    hoveredPart.classList.add("hovered-part");
    hoveredPartWrapper.appendChild(hoveredPart);

    const hoveredPartTitle = document.createElement("div");
    hoveredPartTitle.classList.add("hovered-part-title");

    const hoveredPartUrl = document.createElement("div");
    hoveredPartUrl.className = "hovered-url";
    hoveredPartUrl.innerText = this.data.url && this.data.url.length > 120 ? this.data.url.replace(/[\r\n]/g, "").substring(0, 120) + "..." : this.data.url;
    if (this.data.type === "link") {
      hoveredPartTitle.innerText = MainData.langData["Link"];
    }
    if (this.data.type === "comment") {
      hoveredPartTitle.innerText = MainData.langData["Comment"];
    }
    const hoveredPartDesc = document.createElement("div");
    hoveredPartDesc.classList.add("hovered-part-desc");
    if (this.data.text && this.data.text.length > 120) {
      hoveredPartDesc.innerText =
        this.data.text.replace(/[\r\n]/g, "").substring(0, 120) + "...";
    }
    if (this.data.text && this.data.text.length <= 120) {
      hoveredPartDesc.innerText = this.data.text;
    }
    hoveredPart.appendChild(hoveredPartTitle);
    hoveredPart.appendChild(hoveredPartDesc);
    if (this.data.type === "link") {
      hoveredPart.appendChild(hoveredPartUrl);
    }
    if (this.data.classIdList && this.data.classIdList[0]) {
      let className;
      const classNameList = [];
      this.data.classIdList.forEach(item => {
        const classData = MainData.classList.find(classInfo => {
          return classInfo.classId == item;
        });
        if (classData) {
          className = classData.name;
          classNameList.push(className);
        }
      })
      const hoveredPartClassList = document.createElement('div');
      hoveredPartClassList.classList.add('hovered-part-classlist')
      classNameList.forEach((el, i) => {
        const span = document.createElement('span');
        span.setAttribute("dir", "ltr");
        span.innerText = (i !== classNameList.length -1 ? "," : '') + el;
        hoveredPartClassList.append(span);
      });
      hoveredPart.appendChild(hoveredPartClassList)
    }

    return hoveredPartWrapper;
  };
  setClassForHoveredPart(left, top, hoveredPartWrapper) {
    if (!hoveredPartWrapper) {
      const wrapperAdditional = document.querySelector(
        `[data-link-id-inbook='${this.data._id}'`
      );
      hoveredPartWrapper = wrapperAdditional.querySelector(
        ".hovered-part-wrapper"
      );
    }
    hoveredPartWrapper.classList = "";
    hoveredPartWrapper.classList.add("hovered-part-wrapper");
    if (left < 20 && top >= 20) {
      hoveredPartWrapper.classList.add("left");
    }
    if (left < 20 && top < 20) {
      hoveredPartWrapper.classList.add("bottom-left");
    }
    if (left > 80 && top < 20) {
      hoveredPartWrapper.classList.add("bottom-right");
    }
    if (left <= 80 && left >= 20) {
      hoveredPartWrapper.classList.add("bottom-center");
    }
  };
  // closeDragElem(isSaved = this.isSaved) {
  //   if (isSaved) {
  //     const el = DragUiItem.prototype.mainWrapper.querySelector(".hidden-part");
  //     el.parentNode.removeChild(el);
  //   } else {
  //     $('[data-layer-temp-id]').remove();
  //     if (DragUiItem.prototype.mainWrapper) {
  //       DragUiItem.prototype.mainWrapper.remove();
  //     }
  //   }
  // }
}
DragUiItem.prototype.mainWrapper = null;
DragUiItem.prototype.closeDragElem = function(isSaved = this.isSaved) {
  if (isSaved) {
    const el = DragUiItem.prototype.mainWrapper.querySelector(".hidden-part");
    el.parentNode.removeChild(el);
  } else {
    console.log('if saved will remove');
    $('[data-layer-temp-id]').remove();
    if (DragUiItem.prototype.mainWrapper) {
      DragUiItem.prototype.mainWrapper.remove();
      DragUiItem.prototype.mainWrapper = null;
    }
  }
}
